import { ResponsiveWaffle } from "@nivo/waffle";
import { formatHours } from "../../helpers/utility";

const WaffleChart = ({ data, total, height }) => {
  const customTooltip = (values) => {
    let durationObj = formatHours(
      Number(values?.data?.value) > 0 ? Number(values?.data?.value) : 0
    );
    let durationStr =
      (durationObj?.days ? `${durationObj?.days} days ` : "") +
      durationObj?.hours +
      " hrs " +
      (!durationObj?.days ? durationObj?.minutes + " mins" : "");
    return (
      <div
        style={{
          background: "white",
          padding: "5px 9px",
          borderRadius: "2px",
          boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
          display: "flex",
          alignItems: "center",
          whiteSpace: "pre",
        }}
      >
        <span
          style={{
            width: 12,
            height: 12,
            background: values?.data?.color,
            marginRight: 7,
          }}
        ></span>
        <span>
          {values?.data?.label}: <strong>{durationStr}</strong>
        </span>
      </div>
    );
  };

  return (
    <ResponsiveWaffle
      height={height}
      data={data}
      total={total}
      rows={10}
      columns={10}
      padding={1}
      valueFormat=".2f"
      colors={["#CB6767", "#FFA384", "#DCDCDC"]}
      emptyColor="#f2f2f2"
      borderRadius={3}
      motionStagger={2}
      tooltip={customTooltip}
    />
  );
};

export default WaffleChart;
