import { Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import ShareTyut from "../Tyuts/CreateTyut/ShareTyut";
import {
  getCoursePermissionByIDApi,
  updateCoursePermissionApi,
} from "../../../services/courses.services";

function ShareCourse({ creator_id, shareCourse, setShareCourse }) {
  const [canOrgView, setOrgView] = useState(false);
  const [sharedWith, setSharedWith] = useState({ groups: [], users: [] });

  function handleCourseShare(permissions, anyOneCanView) {
    updateCoursePermissions(permissions, anyOneCanView);
  }

  useEffect(() => {
    if (shareCourse.courseId) {
      getCoursePermissions(shareCourse.courseId);
    }
  }, [shareCourse.courseId]);

  async function getCoursePermissions(courseId) {
    try {
      const res = await getCoursePermissionByIDApi(courseId);
      let users = [];
      let groups = [];
      let anyOneCanView = false;
      (res.data || []).map((eachPermission) =>
        eachPermission.level == "user" && eachPermission._id != creator_id
          ? users.push({ ...eachPermission, id: eachPermission._id })
          : eachPermission.level == "public"
          ? (anyOneCanView = true)
          : eachPermission.level == "group" &&
            groups.push({ ...eachPermission, id: eachPermission._id })
      );
      setSharedWith({ users, groups });
      setOrgView(anyOneCanView);
    } catch (error) {}
  }
  async function updateCoursePermissions(permissions, anyOneCanView) {
    let updatedPermissions = permissions;
    if (anyOneCanView) {
      updatedPermissions.push({
        level: "public",
        status: "add",
      });
    } else updatedPermissions = permissions;
    try {
      const res = await updateCoursePermissionApi(
        shareCourse.courseId,
        permissions
      );
      message.success("Course shared successfully");

      setShareCourse({ share: false });
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  }
  return (
    <Modal
      open={shareCourse.share}
      onCancel={() => setShareCourse({ share: false })}
      footer={false}
      centered
      style={{ minHeight: "50vh" }}
    >
      <ShareTyut
        creator_id={creator_id}
        anyOneCanFromOrgCanView={canOrgView}
        sharedWith={sharedWith}
        shareType="Course"
        placeholder="Share course"
        tyutMetaData={{}}
        editBtn={false}
        publishHandler={(permissions, anyOneCanView) =>
          handleCourseShare(permissions, anyOneCanView)
        }
        courseId={shareCourse?.courseId}
      />
    </Modal>
  );
}

export default ShareCourse;
