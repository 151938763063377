import { Col, Empty, Row, Skeleton, Typography, message } from "antd";
import React, {
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import VideoPlayer from "../../Blocks/VideoPlayer";
import CustomCard from "../../Blocks/CustomCard";
import CustomCheckBox from "../../Blocks/CustomCheckBox";
import CustomRadioButton from "../../Blocks/CustomRadioButton";
import PdfViewer from "../../Blocks/PDFViewer";
import { useNavigate, useParams } from "react-router-dom";
import CustomLayout from "../../Layout";
import "../SOP.css";
import { getSOPByIDApi, updateSOPApi } from "../../../services/sops.services";
import SOPContent from "../CreateSOP/SOPContent";
import SOPElements from "../CreateSOP/SOPElements";
import GeneralDetails from "../CreateSOP/GeneralDetails";
import { renameFile } from "../../../helpers/utility";
import { UserContext } from "../../../context/UserProvider";
import { ONPREMISE, SOP } from "../../../constants/defaultKeys";
import ViewSection from "../CreateSOP/AddElem/ViewSection";
import Header from "./Header";
import Footer from "./Footer";
import SOPLanguages from "../CreateSOP/SOPLanguages";

function EditSOP({ contentType = SOP }) {
  const params = useParams();
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const MAX_UPLOAD_FILES_PER_TYUT_LIMIT = useRef(100);
  const sopFormRef = useRef(null);
  const activeQuestionnaireRef = useRef(null);
  const [sopData, setSOPData] = useState(null);
  const [sopContent, setSopContent] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [sopMetaData, setsopMetaData] = useState({});
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState({ edit: false, data: null });
  const [isUnfilled, setIsUnfilled] = useState("#406aff");
  const [isUploadLimitReached, setUploadLimitReached] = useState(false);
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const [language, setLanguage] = useState("english");
  const [languages, setLanguages] = useState([]);

  let sopId = params?.id;

  useEffect(() => {
    let fileCount = 0;
    sopContent.map((tyut) => {
      if (
        (tyut.type == "document" || tyut.type == "video") &&
        tyut.src?.slice(0, 4) !== "http"
      )
        fileCount++;
    });
    if (!(fileCount <= MAX_UPLOAD_FILES_PER_TYUT_LIMIT.current)) {
      setUploadLimitReached(true);
    } else setUploadLimitReached(false);

    return () => {
      // Any necessary cleanup can be performed here
    };
  }, [sopContent]);

  useLayoutEffect(() => {
    getSOP();
  }, [language]);

  async function getSOP() {
    setLoading(true);
    try {
      const res = await getSOPByIDApi(sopId, language);

      if (res) {
        sopFormRef?.current?.setFieldsValue({
          ...res?.data,
          asset: res?.data?.asset?._id,
          asset_image: res?.data?.asset?.file,
        });
        setDocuments(res?.data?.asset_docs);
        setSOPData(res?.data);
        setsopMetaData({
          name: res?.data?.name,
          description: res?.data?.description,
          asset: res?.data?.asset?._id,
          reviewer: res?.data?.reviewer,
          qa_reviewer: res?.data?.qa_reviewer,
        });
        setLanguages(res?.data?.language);
        setSopContent(res?.data?.content);
      }
    } catch (error) {
      message.error(error.response?.data?.message);
      navigate("/procedures");
    } finally {
      setLoading(false);
    }
  }

  const formatProcedures = (procedures) => {
    return procedures.map((procedure) => {
      if (procedure.images?.length > 0) {
        procedure.images = procedure?.images?.map((image) => renameFile(image));
      }
      if (procedure?.src) {
        procedure.src = procedure.src;
      }
      return procedure;
    });
  };

  const updateSOP = async (translate_sop = true) => {
    const formattedContent =
      storageType == ONPREMISE
        ? sopContent
        : sopContent.map((item) => {
            if (item.type === "section" && item.procedures) {
              item.procedures = formatProcedures(item?.procedures || []);
            }
            return item;
          });
    setLoading(true);

    if (formattedContent.length > 0) {
      try {
        const res = await updateSOPApi(sopId, {
          ...sopMetaData,
          translate_sop: translate_sop,
          language: languages,
          name: {
            english: sopMetaData?.name,
          },
          description: {
            english: sopMetaData?.description,
          },
          content: {
            english: formattedContent,
          },
        });
        if (res) {
          message.success(res?.data?.message);
          if (res) {
            getSOP();
            setIsEditOpen(false);
          }
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  function RenderItems(data) {
    switch (data?.type) {
      case "document":
        return (
          <CustomCard style={{ marginBlock: "12px" }}>
            {data?.title && <Typography>{data.title}</Typography>}
            <PdfViewer pdfUrl={data?.src} />
          </CustomCard>
        );
      case "section":
        return <ViewSection disabled={true} data={data} />;
      case "text":
        return (
          <CustomCard>
            <Typography>{data?.text}</Typography>
          </CustomCard>
        );
      case "video":
        return (
          <CustomCard>
            <VideoPlayer url={data?.src} height="500px" width="100%" />
          </CustomCard>
        );
      case "checkbox":
        return (
          <CustomCheckBox
            data={data}
            title={data?.question}
            onChange={(e) => {}}
            storageType={storageType}
          />
        );
      case "radio":
        return (
          <CustomRadioButton
            data={data}
            title={data?.question}
            onChange={(e) => {}}
            storageType={storageType}
          />
        );
      default:
        return null;
    }
  }
  return (
    <Fragment>
      <CustomLayout
        header={
          <>
            <Header
              contentType={contentType}
              sopData={sopData}
              setIsEditOpen={setIsEditOpen}
              isEditOpen={isEditOpen}
              setLanguage={setLanguage}
            />
          </>
        }
        footer={
          <>
            {isEditOpen && (
              <Footer
                setIsEditOpen={setIsEditOpen}
                editing={editing}
                isEditOpen={isEditOpen}
                getSOP={getSOP}
                updateSOP={updateSOP}
                sopFormRef={sopFormRef}
                sopContent={sopContent}
              />
            )}
          </>
        }
      >
        <Row
          gutter={[24, 24]}
          align="middle"
          justify="flex-start"
          style={{
            position: "relative",
          }}
        >
          {!isEditOpen && sopData && (
            <Col
              xs={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 20, offset: 2 }}
              xl={{ span: 18, offset: 3 }}
            >
              <CustomCard>
                <Row gutter={[24, 12]}>
                  <Col span={24}>
                    {sopData?.asset?.name && (
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          lineHeight: "24px",
                        }}
                      >
                        Asset: {sopData?.asset?.name}
                      </Typography>
                    )}
                    {sopData?.troubleshooting_issue && (
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          lineHeight: "24px",
                        }}
                      >
                        Issue: {sopData?.troubleshooting_issue}
                      </Typography>
                    )}
                    {sopData?.reviewer && (
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          lineHeight: "24px",
                        }}
                      >
                        Reviewer: {sopData?.reviewer}
                      </Typography>
                    )}
                    {sopData?.qa_reviewer && (
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          lineHeight: "24px",
                        }}
                      >
                        QA: {sopData?.qa_reviewer}
                      </Typography>
                    )}
                  </Col>
                  <Col span={24}>
                    <Typography
                      style={{
                        fontSize: 18,
                        fontWeight: 400,
                        lineHeight: "30px",
                      }}
                    >
                      {sopData?.name}
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Typography.Paragraph
                      ellipsis={{
                        rows: 2,
                        expandable: true,
                        symbol: "read more",
                      }}
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      {sopData?.description}
                    </Typography.Paragraph>
                  </Col>
                </Row>
              </CustomCard>
            </Col>
          )}
          <Skeleton
            active
            loading={loading}
            style={{ width: "50%", margin: "auto" }}
          >
            {isEditOpen ? (
              <Col
                xs={{ span: 18, offset: 6 }}
                md={{ span: 18, offset: 6 }}
                lg={{ span: 18, offset: 6 }}
                xl={{ span: 18, offset: 3 }}
              >
                {sopData?.asset && (
                  <GeneralDetails
                    documents={documents}
                    setDocuments={setDocuments}
                    sopMetaData={sopMetaData}
                    setsopMetaData={setsopMetaData}
                    edit={editing}
                    sopDetails={sopData}
                  />
                )}
                <div style={{ marginBlock: "24px" }}>
                  {" "}
                  <SOPContent
                    isUploadLimitReached={isUploadLimitReached}
                    isUnfilled={isUnfilled}
                    setIsUnfilled={setIsUnfilled}
                    ref={activeQuestionnaireRef}
                    setsopMetaData={setsopMetaData}
                    sopFormRef={sopFormRef}
                    sopContent={sopContent}
                    setSopContent={setSopContent}
                    editing={editing}
                    setEditing={setEditing}
                    sopMetaData={sopMetaData}
                    onFinish={updateSOP}
                    contentType={contentType}
                  />
                </div>

                <SOPElements
                  isUploadLimitReached={isUploadLimitReached}
                  setIsUnfilled={setIsUnfilled}
                  ref={activeQuestionnaireRef}
                  editing={editing}
                  setEditing={setEditing}
                  sopContent={sopContent}
                  setSopContent={setSopContent}
                  contentType={contentType}
                  sopType={sopData?.type}
                />
                <SOPLanguages
                  languages={languages}
                  setLanguages={setLanguages}
                />
              </Col>
            ) : sopData && sopData?.content?.length !== 0 ? (
              sopData?.content?.map((item, index) => (
                <Col
                  xs={{ span: 24, offset: 0 }}
                  md={{ span: 24, offset: 0 }}
                  lg={{ span: 20, offset: 2 }}
                  xl={{ span: 18, offset: 3 }}
                  key={index}
                >
                  {RenderItems(item)}
                </Col>
              ))
            ) : (
              <Col span={24}>
                <Empty />
              </Col>
            )}
          </Skeleton>
        </Row>
      </CustomLayout>
    </Fragment>
  );
}

export default EditSOP;
