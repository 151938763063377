import {
  Empty,
  Result,
  Table,
  Tag,
  Typography,
  Skeleton,
  Col,
  Pagination,
  Space,
  Input,
  Button,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { getWorkordersApi } from "../../services/work-orders.services";
import { useNavigate } from "react-router-dom";
import "./WorkOrder.css";
import {
  workOrderstatusList,
  capitalizeFirstLetter,
  priorityOptions,
  convertUTCToLocalFormat,
  debounce,
  convertToUTCFormat,
  tableComponents,
  scrollToTopTable,
} from "../../helpers/utility";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import { UserContext } from "../../context/UserProvider";
import { FilterFilled, SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { PAGESIZE, createWO } from "../../constants/defaultKeys";
import btnStyles from "../../assets/styles/Custom/Button.module.css";

const WorkOrdersTable = ({
  dateFilter,
  setTotalCOunt,
  totalCOunt,
  filters,
}) => {
  const tableRef = useRef(null);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [search, setSearch] = useState("");

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 140,
      fixed: "left",
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 140,
      render: (val) => (val ? val : "-"),
    },
    {
      title: "Asset/ Production line",
      dataIndex: "asset",
      key: "asset",
      render: (val, record) => (
        <>
          {record?.asset
            ? record?.asset?.name || "-"
            : record?.production_line?.name || "-"}
        </>
      ),
      width: 250,
    },
    {
      title: "Location",
      dataIndex: "site",
      key: "site",
      width: 200,
      render: (val, record) => (
        <>
          {record?.asset
            ? record?.asset?.location?.name || "-"
            : record?.production_line?.location?.name || "-"}
        </>
      ),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      width: 140,
      render: (val, record) => {
        const color =
          priorityOptions.filter((pri) => pri.value === val)[0]?.color ||
          "blue";
        return (
          <Tag
            style={{ minWidth: "80px", border: "0px" }}
            className={tagStyles.smTag}
            color={color}
          >
            {capitalizeFirstLetter(val) || "-"}
          </Tag>
        );
      },
    },
    {
      title: "Created at",
      dataIndex: "created_at",
      key: "created_at",
      width: 220,
      render: (val, record) => (
        <>
          {val ? (
            <>
              <span>
                {dayjs(convertUTCToLocalFormat(val)).format("DD MMM")}
              </span>
              <span style={{ marginLeft: 10 }}>
                {dayjs(convertUTCToLocalFormat(val)).format("hh:mm A")}
              </span>
            </>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 140,
      render: (val, record) => {
        const value = workOrderstatusList?.filter(
          (status) => status?.value == val
        )?.[0];
        return (
          <>
            {value?.label ? (
              <Tag
                className={tagStyles.smTag}
                style={{
                  minWidth: "80px",
                  textAlign: "left",
                }}
                color={value?.color}
              >
                {value?.label}{" "}
              </Tag>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Verification",
      width: 140,
      filterIcon: <FilterFilled style={{ color: "#007aff" }} />,
      dataIndex: "mark_as_verified",
      key: "mark_as_verified",
      render: (val, record) => (
        <div className="flex-center" style={{ justifyContent: "flex-start" }}>
          <Tag
            className={tagStyles.smTag}
            color={val ? "success" : "error"}
            style={{ minWidth: "90px", border: "0px" }}
          >
            {val ? "Verified" : "Not verified"}
          </Tag>
        </div>
      ),
    },
  ];

  const handleSearch = (e) => {
    setSearch(e?.target?.value);
  };

  const getOrders = async () => {
    let params = { page: pageNumber };
    if (search) {
      params.name = search;
    }
    if (dateFilter?.start_date) {
      params.start_date = convertToUTCFormat(
        dateFilter.start_date.startOf("day")
      );
    }
    if (dateFilter?.end_date) {
      params.end_date = convertToUTCFormat(dateFilter.end_date.endOf("day"));
    }
    filters?.asset && (params.asset = filters?.asset);
    filters?.production_line &&
      (params.production_line = filters?.production_line);
    filters?.types?.length > 0 && (params.type = filters?.types);
    filters?.priorities?.length > 0 && (params.priority = filters?.priorities);
    filters?.statuses?.length > 0 && (params.status = filters?.statuses);
    filters?.verification &&
      (params.mark_as_verified =
        filters?.verification === "verified" ? true : false);

    try {
      setLoading(true);
      const resp = await getWorkordersApi(params);
      setOrders(resp?.data?.workorders);
      setTotalCOunt(resp?.data?.totalWorkorders);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await getOrders();
      scrollToTopTable(tableRef);
    };
    fetchData();
  }, [pageNumber, refresh, userDetails]);

  useEffect(() => {
    if (pageNumber === 1) {
      setRefresh((prev) => !prev);
    } else {
      setPageNumber(1);
    }
  }, [search, dateFilter, filters]);

  return (
    <>
      <Col span={24}>
        <Table
          ref={tableRef}
          title={() => (
            <Space
              align="center"
              style={{ width: "100%", justifyContent: "flex-end", gap: 16 }}
            >
              <Input.Search
                size="large"
                onChange={debounce(handleSearch, 500)}
                placeholder="Search by WO name or code"
                prefix={
                  <SearchOutlined style={{ fontSize: 16, color: "#667085" }} />
                }
                style={{ width: "300px" }}
                className="custom-input-search"
              />
              {/* {createWO.includes(userDetails?.activeOrg?.role) && ( */}
              {(userDetails?.activeOrg?.role === "admin" ||
                userDetails?.activeOrg?.group_types?.length === 0 ||
                userDetails?.activeOrg?.group_types?.includes(
                  "Maintenance"
                )) && (
                <Button
                  id="create-wo"
                  type="primary"
                  className={btnStyles.mdBtn}
                  onClick={() => navigate("/work-orders/create")}
                >
                  + Create WO
                </Button>
              )}
            </Space>
          )}
          onRow={(item) => {
            return {
              onClick: (e) => {
                !item?.children?.length > 0 &&
                  navigate(`/work-orders/${item?._id}`);
              },
            };
          }}
          scroll={{
            x: 1200,
            y: "55vh",
          }}
          locale={{
            emptyText: loading ? (
              <Skeleton active />
            ) : (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={<Typography>No WO's found</Typography>}
              />
            ),
          }}
          loading={
            loading
              ? {
                  spinning: false,
                  indicator: null,
                }
              : false
          }
          rowClassName={"rowClassName"}
          bordered
          columns={columns}
          dataSource={orders}
          pagination={false}
          components={tableComponents}
        />
      </Col>
      {totalCOunt > PAGESIZE && (
        <Col span={24} className="text-center">
          <Pagination
            current={pageNumber}
            pageSize={PAGESIZE}
            total={totalCOunt}
            onChange={(e) => setPageNumber(e)}
            showSizeChanger={false}
          />
        </Col>
      )}
    </>
  );
};

export default WorkOrdersTable;
