import { Flex, Tag, Typography } from "antd";
import { workOrderstatusList } from "../../helpers/utility";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import "./index.css";

export default function EventCard({ currEvent }) {
  const { code, name, maintenance } = currEvent;
  const status = workOrderstatusList?.filter(
    (status) => status?.value == currEvent?.status
  )?.[0];

  return (
    <div height="100%" style={{ width: "100%" }} className="calenderEvent">
      <Flex justify="flex-start" style={{ gap: 12, flexWrap: "wrap" }}>
        <Typography
          style={{
            color: currEvent?.type == "wo" ? "#fff" : "#fff",
            fontSize: 12,
            lineHeight: "16px",
          }}
        >
          {code}
          <span className="eventName">
            {": "}
            {name || maintenance?.name}
          </span>
        </Typography>

        <Tag
          className={tagStyles.smTag}
          style={{
            fontWeight: "600",
            fontSize: 10,
            padding: "1px 4px",
            borderRadius: "12px",
            height: "16px",
          }}
          color={status?.color}
        >
          {status?.label}{" "}
        </Tag>
      </Flex>
    </div>
  );
}
