import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Checkbox,
  message,
  Skeleton,
} from "antd";
import Location from "./Location";
import btnStyles from "../../assets/styles/Custom/Button.module.css";

import { createLocationApi, getLocationsApi } from "../../services/location";
import "./Locations.css";
import { createLOcation } from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserProvider";

const TreeLocations = ({ setRefresh, refresh }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locations, setLocations] = useState([]);
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userDetails } = useContext(UserContext);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const createNewLocation = async (values) => {
    try {
      const resp = await createLocationApi({
        ...values,
        parentId: null,
        is_facility: values?.is_facility ? values?.is_facility : false,
      });
      getLocations();
    } catch (error) {
      message.error(error?.resp?.data?.message);
    } finally {
      handleCancel();
    }
  };

  const getLocations = async () => {
    setLoading(true);
    try {
      const resp = await getLocationsApi();
      setLocations(resp?.data);
      setId(resp?.data?._id);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLocations();
  }, [refresh]);
  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          {!locations?._id &&
            createLOcation?.includes(userDetails?.activeOrg?.role) && (
              <Flex justify="flex-end">
                <Button
                  className={btnStyles.mdBtn}
                  onClick={showModal}
                  type="primary"
                >
                  Create New Location
                </Button>
              </Flex>
            )}

          {id && <Location id={id} setRefresh={setRefresh} />}
        </>
      )}
      <Modal
        title="Add location"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          layout="vertical"
          requiredMark={false}
          onFinish={createNewLocation}
          onFinishFailed={(e) => console.log("Location creation failed:", e)}
        >
          <Form.Item
            style={{ marginBottom: "0px" }}
            name={"name"}
            label="Location name"
            rules={[{ required: true, message: "Please enter location" }]}
          >
            <Input size="medium" />
          </Form.Item>
          <Form.Item valuePropName="checked" name={"is_facility"}>
            <Checkbox>Mark as facility</Checkbox>
          </Form.Item>
          <Form.Item style={{ textAlign: "right", marginBottom: "0px" }}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TreeLocations;
