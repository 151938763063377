import { Col, Row, Radio, Typography, Image } from "antd";
import React, { useEffect, useRef, useState } from "react";
import TyutList from "./TyutList";
import PreviewTyut from "../../Tyuts/CreateTyut/PreviewTyut";
import CustomSearch from "../../../Blocks/CustomSearch";
import { debounce } from "../../../../helpers/utility";
import noCourses from "../../../../assets/images/tyuts/nocourses.svg";
import checkboxStyles from "../../../../assets/styles/Custom/Checkbox.module.css";
import typographyStyles from "../../../../assets/styles/Custom/Typography.module.css";
import { searchTyutsAndCoursesApi } from "../../../../services/tyuts.services";

function arrayDifferenceBy(arr1, arr2, key) {
  let set2 = new Set(arr2.map((item) => item[key]));
  return arr1.filter((item) => !set2.has(item[key]));
}
function AddTyuts({
  searchedTyuts,
  setSearchedTyuts,
  setSelectedTyuts,
  selectedTyuts,
  activeTyut,
  setActiveTyut,
}) {
  const [tyuts, setTyuts] = useState([]);
  const [tyutsType, setTyutsType] = useState("allTyuts");
  const activeTyutIndex = useRef(0);

  async function getCourseTyut(name, endLoading) {
    try {
      const res = await searchTyutsAndCoursesApi(name);
      setTyuts(arrayDifferenceBy(res.data?.tyuts || [], selectedTyuts, "_id"));
    } catch (err) {
    } finally {
      endLoading();
    }
  }

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "ArrowLeft" && activeTyutIndex.current > 0) prevTyut();
      else if (
        e.key === "ArrowRight" &&
        activeTyutIndex.current < searchedTyuts?.[tyutsType]?.length - 1
      )
        nextTyut();
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [activeTyutIndex.current]);

  function nextTyut() {
    setActiveTyut(searchedTyuts?.[tyutsType]?.[activeTyutIndex.current + 1]);
    activeTyutIndex.current++;
  }

  function prevTyut() {
    setActiveTyut(searchedTyuts?.[tyutsType]?.[activeTyutIndex.current - 1]);
    activeTyutIndex.current--;
  }

  return (
    <Row style={{ minHeight: "100%" }}>
      <Col
        span={12}
        style={{
          borderRight: "1px solid #FAF9FB",
        }}
      >
        <Row>
          <Col span={24}>
            <Radio.Group
              value={tyutsType}
              onChange={(e) => {
                let val = e.target.value;
                setTyutsType(val);
                if (searchedTyuts?.[val].length == 0) {
                  getAllTyuts();
                }
              }}
            >
              <Radio value={"allTyuts"} className={checkboxStyles.smRadio}>
                All
              </Radio>
              <Radio value={"myTyuts"} className={checkboxStyles.smRadio}>
                Created by me
              </Radio>
            </Radio.Group>
          </Col>
          <Col
            span={18}
            style={{
              margin: "15px 0",
            }}
          >
            <CustomSearch
              placeholder="Search tyuts"
              options={
                tyuts.length > 0 && [
                  {
                    label: (
                      <Typography className={typographyStyles.body3}>
                        Tyuts
                      </Typography>
                    ),
                    options: tyuts.map((eachTyut) => ({
                      value: eachTyut.name,
                      id: eachTyut._id,
                      tyut: eachTyut,
                    })),
                  },
                ]
              }
              onSelect={(val, option) => {
                setSelectedTyuts((prev) => [option.tyut, ...prev]);
                setActiveTyut(option.tyut);
                setTyuts([]);
              }}
              onSearch={debounce(getCourseTyut, 300)}
            />
          </Col>
          <Col
            span={24}
            style={{
              overflowY: "auto",
              paddingRight: "32px",
            }}
          >
            <TyutList
              tyutsType={tyutsType}
              tyutsList={searchedTyuts?.[tyutsType]}
              setSelectedTyuts={setSelectedTyuts}
              setActiveTyut={setActiveTyut}
              activeTyut={activeTyut}
              selectedTyuts={selectedTyuts}
            />
          </Col>
        </Row>
      </Col>
      <Col
        span={12}
        style={{
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {activeTyut ? (
          <PreviewTyut
            maxContentHeight={"100%"}
            showPreviewTitle={false}
            tyutMetaData={activeTyut}
            tyutContent={activeTyut.content || []}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              minHeight: "100%",
            }}
          >
            <Image
              alt="No courses found"
              width={342}
              height={342}
              preview={false}
              src={noCourses}
            />
          </div>
        )}
      </Col>
    </Row>
  );
}

export default AddTyuts;
