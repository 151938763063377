import React, { Fragment, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import Dashboard from "../Components/Dashboard";
import Locations from "../Components/Locations";
import Location from "../Components/Locations/Location";
import WorkOrders from "../Components/WorkOrders";
import SOPs from "../Components/Sops";
import Users from "../Components/Users";
import Maintenance from "../Components/Maintenance";
import Assets from "../Components/Assets";
import CreateAssetForm from "../Components/Assets/CreateAsset";
import CreateSOP from "../Components/Sops/CreateSOP";
import CreateWorkOrder from "../Components/WorkOrders/CreateWorkOrder";
import CreateMaintanace from "../Components/Maintenance/CreateMaintanance";
import Login from "../Components/Auth/Login";
import Join from "../Components/Join";
import Profile from "../Components/Auth/Profile";
import ResetPassword from "../Components/Auth/Profile/ResetPassword";
import Groups from "../Components/Groups";
import EditSOP from "../Components/Sops/EditSOP";
import CalendarView from "../Components/Calender/CalenderView.js";
import PageNorFound from "../Components/PageNotFound/index.js";
import Analysis from "../Components/Analysis/index.js";
import Trainings from "../Components/Trainings/index.js";
import CreateTyut from "../Components/Trainings/Tyuts/CreateTyut/index.js";
import EditTyut from "../Components/Trainings/Tyuts/EditTyut/index.js";
import CreateOrEditCourse from "../Components/Trainings/Courses/CreateOrEditCourse/index.js";
import EditCourse from "../Components/Trainings/Courses/EditCourse/index.js";
import FreeTrail from "../Components/Blocks/FreeTrail.js";
import AuditLogs from "../Components/Assets/AuditLogs/index.js";
import OrgProfileSettings from "../Components/Organisations/OrgProfileSettings";
import SignUp from "../Components/Auth/SignUp/index.js";
import DeleteAccount from "../Components/Auth/DeleteAccount.js";
import { INSPECTION } from "../constants/defaultKeys.js";
import Logout from "../Components/Auth/Logout.js";
import AuthProvider from "../context/AuthProvider.js";
import UserProvider from "../context/UserProvider.js";
import Home from "../Components/Home/index.js";
import Scheduler from "../Components/Calender/Scheduler";
import MRO from "../Components/MRO";
import ProductionLines from "../Components/Assets/ProductionLines/ProductionLines";
import CreateProductionLine from "../Components/Assets/ProductionLines/CreateProductionLine";
import Inventory from "../Components/Inventory/Inventory";
import ScrollToTop from "../Components/Blocks/ScrollToTop";
import CreateMRO from "../Components/MRO/CreateMRO";
import Workflows from "../Components/Organisations/Workflows";
import CreateWorkflow from "../Components/Organisations/Workflows/CreateWorkflow";
import SetPassword from "../Components/Auth/Login/SetPassword";
import Departments from "../Components/Organisations/Departments";
import AuditLogsTable from "../Components/Assets/AuditLogs/AuditLogTable";
import AssetCategories from "../Components/Assets/AssetCategories/AssetCategories";
import CreateAssetCategory from "../Components/Assets/AssetCategories/CreateAssetCategory";
import OrgSettings from "../Components/Organisations/OrgSettings";
import IntegrationPage from "../Components/Organisations/Integrations.js";

const ProjectRoutes = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <UserProvider>
          <AuthProvider>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login pageType="email" />} />
              <Route path="/mobile" element={<Login pageType="mobile" />} />
              <Route path="/delete-account" element={<DeleteAccount />} />
              <Route path="/signup/:step" element={<SignUp />} />
              <Route path="/join/:id" element={<Join />} />
              <Route path="/set-password" element={<SetPassword />} />
              <Route path="/logout" element={<Logout />} />
              <Route element={<PrivateRoutes />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/scheduler" element={<Scheduler />} />
                <Route path="/locations" element={<Locations />} />
                <Route path="/locations/:id" element={<Location />} />
                <Route path="/mro" element={<MRO />} />
                <Route path="/mro/create" element={<CreateMRO />} />
                <Route path="/mro/:id" element={<CreateMRO />} />
                <Route path="/work-orders" element={<WorkOrders />} />
                <Route
                  path="/work-orders/create"
                  element={<CreateWorkOrder />}
                />
                <Route path="/work-orders/:id" element={<CreateWorkOrder />} />
                <Route path="/maintenances" element={<Maintenance />} />

                <Route
                  path="/maintenances/create"
                  element={<CreateMaintanace />}
                />
                <Route
                  path="/maintenances/:id"
                  element={<CreateMaintanace />}
                />
                <Route
                  path="/maintenances/:id/:type"
                  element={<CreateMaintanace />}
                />
                <Route
                  path="/inspections"
                  element={<Maintenance type={INSPECTION} />}
                />
                <Route
                  path="/inspections/create"
                  element={<CreateMaintanace type={INSPECTION} />}
                />
                <Route
                  path="/inspections/:id"
                  element={<CreateMaintanace type={INSPECTION} />}
                />
                <Route
                  path="/inspections/:id/:type"
                  element={<CreateMaintanace type={INSPECTION} />}
                />
                <Route path="/procedures" element={<SOPs />} />
                <Route path="/procedures/create" element={<CreateSOP />} />
                <Route path="/procedures/:id" element={<EditSOP />} />

                <Route path="/assets" element={<Assets />} />
                <Route path="/assets/create" element={<CreateAssetForm />} />
                <Route path="/assets/:id" element={<CreateAssetForm />} />

                <Route path="/production-lines" element={<ProductionLines />} />
                <Route
                  path="/production-lines/create"
                  element={<CreateProductionLine />}
                />
                <Route
                  path="/production-lines/:id"
                  element={<CreateProductionLine />}
                />
                <Route path="/asset-categories" element={<AssetCategories />} />
                <Route
                  path="/asset-categories/create"
                  element={<CreateAssetCategory />}
                />
                <Route
                  path="/asset-categories/:id"
                  element={<CreateAssetCategory />}
                />
                <Route path="/inventory" element={<Inventory />} />

                <Route path="/profile" element={<Profile />} />
                <Route path="/password" element={<ResetPassword />} />
                <Route path="/analysis" element={<Analysis />} />
                <Route path="/trainings" element={<Trainings />} />
                <Route path="/trainings/tyut" element={<CreateTyut />} />
                <Route path="/trainings/tyut/:id" element={<EditTyut />} />
                <Route
                  path="/trainings/course"
                  element={<CreateOrEditCourse />}
                />
                <Route
                  path="/trainings/course/edit/:id"
                  element={<CreateOrEditCourse />}
                />
                <Route path="/trainings/course/:id" element={<EditCourse />} />
                <Route path="/org/profile" element={<OrgProfileSettings />} />
                <Route path="/org/workflows" element={<Workflows />} />
                <Route
                  path="/org/workflows/create"
                  element={<CreateWorkflow />}
                />
                <Route path="/org/workflows/:id" element={<CreateWorkflow />} />
                <Route path="/org/users" element={<Users />} />
                <Route path="/org/groups" element={<Groups />} />
                <Route path="/org/departments" element={<Departments />} />
                <Route path="/org/settings" element={<OrgSettings />} />
                <Route path="/audit-logs" element={<AuditLogsTable />} />
                <Route path="/org/integrations" element={<IntegrationPage />} />
              </Route>
              <Route path="*" element={<PageNorFound />} />
            </Routes>
          </AuthProvider>
        </UserProvider>
      </BrowserRouter>
    </Fragment>
  );
};

export default ProjectRoutes;
