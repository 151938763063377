import { useState } from "react";
import { Flex } from "antd";
import EventForm from "./EventForm";
import MyCalendar from ".";

export default function CalendarView() {
  const [currEvent, setCurrEvent] = useState();

  return (
    <Flex gap={10} height="100%">
      <MyCalendar
        currEvent={currEvent?.[0]}
        onShowEventView={(currEvent) => setCurrEvent([currEvent])}
      />
      {currEvent && (
        <EventForm currEvent={currEvent} onClose={() => setCurrEvent(null)} />
      )}
    </Flex>
  );
}
