import { Col, Row, Segmented, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../Layout";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import AssetsTableView from "./AssetsTable";
import "./Dashboard.css";
import segmentedStyles from "../../assets/styles/Custom/Segmented.module.css";
import EmployeeMetrics from "./EmployeeMetrics";
import { UserContext } from "../../context/UserProvider";
import BreakdownAnalysis from "./BreakdownAnalysis";
import MaintenanceMetrics from "./MaintenanceMetrics";
import { useNavigate, useSearchParams } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState("maintenance-metrics");

  const { userDetails } = useContext(UserContext);

  useEffect(() => {
    let section = searchParams.get("section");
    if (
      ["breakdown-analysis", "asset-health-data", "employee-metrics"].includes(
        section
      )
    ) {
      setSelectedTab(section);
    } else {
      setSelectedTab("maintenance-metrics");
    }
  }, [searchParams]);

  return (
    <CustomLayout
      header={<Typography className={typoStyles.h6}>Dashboard</Typography>}
    >
      <Row gutter={[24, 12]}>
        <Col span={24} className="flex-start">
          <Segmented
            id="analytics-asset-view"
            className={segmentedStyles.basicOne}
            options={[
              { value: "maintenance-metrics", label: "Maintenance Metrics" },
              { value: "breakdown-analysis", label: "Breakdown Analysis" },
              { value: "asset-health-data", label: "Asset Health Data" },
              ...(userDetails?.activeOrg?.role === "admin"
                ? [{ value: "employee-metrics", label: "Employee Metrics" }]
                : []),
            ]}
            onChange={(e) => navigate(`/dashboard?section=${e}`)}
            value={selectedTab}
          />
        </Col>
        {selectedTab === "breakdown-analysis" ? (
          <BreakdownAnalysis />
        ) : selectedTab === "asset-health-data" ? (
          <AssetsTableView />
        ) : selectedTab === "employee-metrics" ? (
          <EmployeeMetrics />
        ) : (
          <MaintenanceMetrics />
        )}
      </Row>
    </CustomLayout>
  );
};

export default Dashboard;
