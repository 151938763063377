import React from "react";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";

import CustomLayout from "../Layout";
import { Flex, Typography } from "antd";
import SOPsTable from "./SOPsTable";

const SOPs = () => {
  return (
    <CustomLayout
      header={
        <Flex justify="space-between">
          <Typography className={typoStyles.h6}>
            Standard Operating Procedures (SOPs)
          </Typography>
        </Flex>
      }
    >
      <SOPsTable />
    </CustomLayout>
  );
};

export default SOPs;
