import { UserOutlined } from "@ant-design/icons";
import { Avatar, Flex, Tooltip, Typography } from "antd";
import React from "react";
import typographyStyles from "../../assets/styles/Custom/Typography.module.css";
import { getAvatarBackgroundColor } from "../../helpers/utility";

function RandomAvatar({
  indexId = 0,
  name,
  url,
  displayName = false,
  size = 40,
  fontSize = 16,
  fontStyle = {},
}) {
  const selectedColor = getAvatarBackgroundColor(name);

  return (
    <Flex align="center">
      <Tooltip title={name}>
        <Avatar
          style={{
            backgroundColor: url ? "" : selectedColor,
            fontSize: fontSize,
          }}
          src={url && url !== "" ? url : null}
          size={size}
        >
          {name ? name?.slice(0, 1).toUpperCase() : <UserOutlined />}
        </Avatar>
      </Tooltip>
      {displayName && (
        <Typography
          ellipsis={true}
          className={typographyStyles.body3}
          style={{
            marginLeft: "10px",
            fontSize: fontSize,
            ...fontStyle,
          }}
        >
          {name?.slice(0, 25)} {name?.length > 26 && "..."}
        </Typography>
      )}
    </Flex>
  );
}

export default RandomAvatar;
