import { DeleteOutlined } from "@ant-design/icons";
import { Col, Popconfirm, Row, Typography } from "antd";
import React from "react";
import CustomCard from "../../../Blocks/CustomCard";
import { RenderThumbnail } from "../../Helpers";
import "../Course.css";
import typographyStyles from "../../../../assets/styles/Custom/Typography.module.css";

function EachTyut(
  {
    item: eachTyut,
    setState: setSelectedTyuts,
    allowDelete = true,
    activeTyut,
    setActiveTyut,
    index,
    activeTyutIndex = null,
  },
  ref
) {
  return (
    <Row
      style={{
        width: "100%",
        marginLeft: "8%",
      }}
      ref={ref}
    >
      <Col
        span={24}
        style={{
          marginBottom: "12px",
          cursor: "pointer",
        }}
        onClick={() => {
          if (activeTyutIndex) activeTyutIndex.current = index;
          setActiveTyut(eachTyut);
        }}
      >
        <CustomCard
          bodyStyle={{ padding: "16px" }}
          className={`tyutCardLessDescriptive ${
            activeTyut?._id === eachTyut?._id && "activetyutCardLessDescriptive"
          }`}
          style={{ cursor: "pointer", border: "none" }}
        >
          <Row
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Col span={6} style={{ overflow: "hidden", objectFit: "cover" }}>
              {RenderThumbnail(eachTyut, () => {})}
            </Col>
            <Col span={14} offset={1}>
              <Typography.Text
                ellipsis={true}
                className={typographyStyles.body3}
              >
                {eachTyut.name}
              </Typography.Text>
            </Col>
            <Col span={2}>
              {allowDelete && (
                <Popconfirm
                  title="Remove this tyut from course? "
                  description="Are you sure to remove this tyut from this course?"
                  onConfirm={(e) => {
                    e.stopPropagation();
                    setActiveTyut(null);
                    setSelectedTyuts((prev) =>
                      prev.filter((tyut) => tyut._id !== eachTyut._id)
                    );
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined style={{ fontSize: "20px" }} />
                </Popconfirm>
              )}
            </Col>
          </Row>
        </CustomCard>
      </Col>
    </Row>
  );
}

export default React.forwardRef(EachTyut);
