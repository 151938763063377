import { useState } from "react";
import { Space, Tag, Typography } from "antd";
import { debounce } from "../../helpers/utility";
import { searchTyutsAndCoursesApi } from "../../services/tyuts.services";
import CustomSearch from "../Blocks/CustomSearch";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

function TyutCourseTagSearch() {
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);

  function searchResult(query) {
    const data = [];
    for (const type of Object.keys(query)) {
      for (const item of query[type]) {
        data.push({
          key: item._id,
          value: item._id,
          type,
          label: (
            <Space align="space-around">
              <Text>{item?.name}</Text>
              <Tag>- {type}</Tag>
            </Space>
          ),
        });
      }
    }
    return data;
  }
  function routerHandler(id, option) {
    switch (option?.type) {
      case "courses":
        return navigate(`/trainings/course/${id}`);
      case "tyuts":
        return navigate(`/trainings/tyut/${id}`);
      default:
        return null;
    }
  }
  async function getCourseTyut(name, endLoading) {
    try {
      const response = await searchTyutsAndCoursesApi(name);
      const data = response.data;
      setOptions(data ? searchResult(data) : []);
    } catch (err) {
    } finally {
      endLoading();
    }
  }

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <CustomSearch
        placeholder="Search tyuts and courses"
        options={options}
        onSelect={routerHandler}
        onSearch={debounce(getCourseTyut, 300)}
        size="large"
        height="100%"
      />
    </div>
  );
}

export default TyutCourseTagSearch;
