import { Col, Empty, Result, Skeleton, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { getEmployeeMetrics } from "../../services/dashboard.services";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import { tableComponents, userRoles } from "../../helpers/utility";

const EmployeeMetrics = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchEmployeeMetrics = async () => {
    setLoading(true);
    try {
      const res = await getEmployeeMetrics();
      setData(res?.data);
    } catch (error) {
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployeeMetrics();
  }, []);

  const tableColumns = [
    {
      title: "Employee",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => a?.first_name?.localeCompare(b?.first_name),
      render: (val, record) => (
        <Typography>
          {val} {record?.last_name || ""}
        </Typography>
      ),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (val) =>
        val ? (
          <Tag className={tagStyles.smRndTag} color="magenta">
            {val}
          </Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (val, record) => {
        const role = userRoles.filter((role) => role?.value == val)?.[0];
        return (
          <Tag className={tagStyles.mdTag} color={role?.color}>
            {role?.label}
          </Tag>
        );
      },
    },
    {
      title: "WOs Closed",
      dataIndex: "workorder",
      key: "workorder",
      sorter: (a, b) => a?.workorder - b?.workorder,
    },
    {
      title: "PMs Closed",
      dataIndex: "maintenance",
      key: "maintenance",
      sorter: (a, b) => a?.maintenance - b?.maintenance,
    },
  ];
  return (
    <>
      <Col span={24}>
        <Table
          locale={{
            emptyText: loading ? (
              <Skeleton active />
            ) : (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={<Typography>No employees found</Typography>}
              />
            ),
          }}
          bordered
          pagination={false}
          scroll={{
            x: 1200,
            y: "60vh",
          }}
          loading={
            loading
              ? {
                  spinning: false,
                  indicator: null,
                }
              : false
          }
          columns={tableColumns}
          dataSource={data}
          components={tableComponents}
        />
      </Col>
    </>
  );
};

export default EmployeeMetrics;
