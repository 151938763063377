import axiosInstance from "../config/axios/axios.config";

export const createSOPApi = (payload) => {
  return axiosInstance.post("/sop", payload);
};
export const getSOPsApi = (query) => {
  return axiosInstance.get(`/sop`, { params: query });
};
export const getSOPByIDApi = (id, language = "english") => {
  return axiosInstance.get(`/sop/${id}?language=${language}`);
};
export const updateSOPApi = (id, payload) => {
  return axiosInstance.put(`/sop/${id}`, payload);
};
export const deleteSOPApi = (id) => {
  return axiosInstance.delete(`/sop/${id}`);
};

export const genrateSOPApi = (payload) => {
  return axiosInstance.post(`/generate-sop`, payload);
};
export const submitSOPApi = (sopId, payload) => {
  return axiosInstance.post(`/sop/submission/${sopId}`, payload);
};
export const getSopFileContext = (payload) => {
  return axiosInstance.post(`/sop/extract-sop-context`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
