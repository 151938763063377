import React, { useEffect, useState } from "react";
import { ConfigProvider, Row, Tabs, Typography } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import CustomLayout from "../Layout";
import MaintenanceTable from "./MaintenanceTable";
import JobsTable from "./JobsTable";
import { INSPECTION, MAINTANANCETEXT } from "../../constants/defaultKeys";

const Maintenance = ({ type = MAINTANANCETEXT }) => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const [viewType, setViewType] = useState("jobs");

  useEffect(() => {
    if (searchParams.get("type") == "conf") {
      setViewType("conf");
    } else {
      setViewType("jobs");
    }
  }, [searchParams]);

  const tabItems = [
    {
      key: "jobs",
      label: `All ${type == INSPECTION ? "Inspections" : "Jobs"}`,
    },
    {
      key: "conf",
      label: `${type == INSPECTION ? "Inspections" : "PMs"} Configured`,
    },
  ];

  return (
    <CustomLayout
      header={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 35,
          }}
        >
          <Typography className={typoStyles.h6} style={{ marginTop: 6 }}>
            {type == INSPECTION ? "Inspections" : "Preventive Maintenance"}
          </Typography>
          <div style={{ height: 36 }}>
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    colorBorderSecondary: "#d0d5dd",
                  },
                },
              }}
            >
              <Tabs
                className="schedulerTabs"
                activeKey={viewType}
                onChange={(e) =>
                  navigate(
                    `/${
                      type == INSPECTION ? "inspections" : "maintenances"
                    }?type=${e}`
                  )
                }
                moreIcon={null}
              >
                {tabItems?.map((item) => (
                  <Tabs.TabPane key={item?.key} tab={item?.label} />
                ))}
              </Tabs>
            </ConfigProvider>
          </div>
        </div>
      }
    >
      <Row gutter={[0, 16]}>
        {viewType == "conf" ? (
          <MaintenanceTable type={type} />
        ) : (
          <JobsTable viewType={viewType} type={type} />
        )}
      </Row>
    </CustomLayout>
  );
};

export default Maintenance;
