import { ConfigProvider, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CustomLayout from "../Layout";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";

import { useNavigate, useSearchParams } from "react-router-dom";
import AssetsListView from "./AssetsListView";
import AssetsTableView from "./AssetsTableView";

const Assets = () => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const [assetsLength, setAssetsLength] = useState(0);
  const [viewType, setViewType] = useState("table");

  useEffect(() => {
    if (searchParams.get("type") == "tree") {
      setViewType("tree");
    } else {
      setViewType("table");
    }
  }, [searchParams]);

  const tabItems = [
    {
      key: "table",
      label: "All Assets",
    },
    {
      key: "tree",
      label: "Location-wise Assets",
    },
  ];

  return (
    <CustomLayout
      header={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 35,
          }}
        >
          <Typography className={typoStyles.h6} style={{ marginTop: 8 }}>
            Assets ({assetsLength})
          </Typography>
          <div style={{ height: 36 }}>
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    colorBorderSecondary: "#d0d5dd",
                  },
                },
              }}
            >
              <Tabs
                className="schedulerTabs"
                activeKey={viewType}
                onChange={(e) => navigate(`/assets?type=${e}`)}
                moreIcon={null}
              >
                {tabItems?.map((item) => (
                  <Tabs.TabPane key={item?.key} tab={item?.label} />
                ))}
              </Tabs>
            </ConfigProvider>
          </div>
        </div>
      }
    >
      {viewType == "tree" ? (
        <AssetsListView setAssetsLength={setAssetsLength} />
      ) : (
        <AssetsTableView setAssetsLength={setAssetsLength} />
      )}
    </CustomLayout>
  );
};

export default Assets;
