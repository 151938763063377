import {
  Button,
  Col,
  Empty,
  Input,
  Pagination,
  Popover,
  Result,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./SOP.css";
import { UserContext } from "../../context/UserProvider";
import { getSOPsApi } from "../../services/sops.services";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";

import { createSOP, PAGESIZE, SOPTYPES } from "../../constants/defaultKeys";
import {
  capitalizeFirstLetter,
  convertUtcToIst,
  debounce,
  tableComponents,
} from "../../helpers/utility";
import { SearchOutlined } from "@ant-design/icons";

const SOPSTable = () => {
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sops, setSops] = useState({});
  const [viewType, setViewType] = useState("All");

  const params = useParams();

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 150,
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 180,
      render: (val) => {
        const value = SOPTYPES.filter((item) => item.value == val)?.[0];
        return (
          <Tag
            style={{ fontSize: "14px" }}
            color={value?.color}
            bordered={false}
          >
            {value?.label}
          </Tag>
        );
      },
    },
    {
      title: "Languages",
      title: <Typography className={"tableHeader"}>Languages</Typography>,
      dataIndex: "language",
      key: "language",
      width: 250,
      render: (val, record) => (
        <>
          {record?.language?.length > 0 ? (
            <div
              className="flex-center"
              style={{
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "6px",
              }}
            >
              {record?.language?.slice(0, 2)?.map((group) => (
                <Tag className={tagStyles.mdTag}>
                  {capitalizeFirstLetter(group)}
                </Tag>
              ))}
              {record?.language?.length > 2 && (
                <Popover
                  content={
                    <div
                      className="flex-center"
                      style={{ flexDirection: "column", gap: "6px" }}
                    >
                      {record?.language?.map((group) => (
                        <Tag className={tagStyles.mdTag}>
                          {capitalizeFirstLetter(group)}
                        </Tag>
                      ))}
                    </div>
                  }
                >
                  <Tag>+{record?.language?.length - 2}</Tag>
                </Popover>
              )}
            </div>
          ) : (
            <>-</>
          )}
        </>
      ),
    },
    {
      title: "Updated on",
      dataIndex: "updated_at",
      key: "updated_at",
      width: 140,
      render: (val) => <>{convertUtcToIst(val)}</>,
    },
    {
      title: "Estimated time (in hours)",
      dataIndex: "estimate_time",
      key: "estimate_time",
      width: 250,
      render: (val) => <>{val ?? "-"}</>,
    },
  ];

  useEffect(() => {
    let params = {
      page: pageNumber,
    };
    if (searchValue) {
      params.name = searchValue;
    }
    if (viewType) {
      if (viewType != "All") {
        params.type = `${viewType}`;
      }
    }
    const fetchData = async () => {
      try {
        const res = await getSOPsApi(params);
        setSops(res?.data);
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    };
    fetchData();
  }, [pageNumber, searchValue, viewType]);

  const handleSearch = (e) => {
    setSearchValue(e?.target?.value);
  };

  return (
    <Row gutter={[24, 18]}>
      <Col span={24}>
        <Select
          options={[{ value: "All", label: "All types" }, ...SOPTYPES]}
          value={viewType}
          onChange={(e) => setViewType(e)}
          style={{ width: "200px" }}
        />
      </Col>
      <Col span={24}>
        <Table
          title={() => (
            <Space
              align="center"
              style={{ width: "100%", justifyContent: "flex-end", gap: 16 }}
            >
              <Input.Search
                size="large"
                onChange={debounce(handleSearch, 500)}
                placeholder="Search by SOP name or code"
                prefix={
                  <SearchOutlined style={{ fontSize: 16, color: "#667085" }} />
                }
                style={{ width: "300px" }}
                className="custom-input-search"
              />
              {createSOP.includes(userDetails?.activeOrg?.role) && (
                <Button
                  id="create-sop"
                  type="primary"
                  className={btnStyles.mdBtn}
                  onClick={() => navigate(`/procedures/create`)}
                >
                  + Create Procedure
                </Button>
              )}
            </Space>
          )}
          pagination={false}
          onRow={(item) => {
            return {
              onClick: (e) => {
                !item?.children?.length > 0 &&
                  navigate(`/procedures/${item?._id}`);
              },
            };
          }}
          locale={{
            emptyText: (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={<Typography>No {params?.type} SOPS found</Typography>}
              />
            ),
          }}
          scroll={{
            x: 1200,
            y: "60vh",
          }}
          rowClassName={"rowClassName"}
          bordered
          columns={columns}
          dataSource={sops?.sops}
          components={tableComponents}
        />
        {sops?.totalSops > PAGESIZE && (
          <Col span={24} className="text-center">
            <Pagination
              current={pageNumber}
              pageSize={PAGESIZE}
              total={sops?.totalSops}
              onChange={(e) => setPageNumber(e)}
              showSizeChanger={false}
            />
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default SOPSTable;
