import React, { useContext, useEffect, useRef, useState } from "react";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import CustomLayout from "../../Layout";
import { Button, Col, Flex, Row, Typography, message } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import SOPContent from "./SOPContent";
import SOPElements from "./SOPElements";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import { createSOPApi } from "../../../services/sops.services";
import InitialQuestions from "./InitialQuestions";
import animateBG from "../../../assets/images/confette.gif";
import { UserContext } from "../../../context/UserProvider";
import { ONPREMISE, SOP } from "../../../constants/defaultKeys";
import { renameFile } from "../../../helpers/utility";
import SOPLanguages from "./SOPLanguages";

const CreateSOP = ({ contentType = SOP }) => {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(true);
  const [isUploadLimitReached, setUploadLimitReached] = useState(false);
  const [editing, setEditing] = useState({ edit: false, data: null });
  const [isUnfilled, setIsUnfilled] = useState("#406aff");
  const activeQuestionnaireRef = useRef(null);
  const [sopMetaData, setsopMetaData] = useState({});
  const [sopContent, setSopContent] = useState([]);
  const [sopReady, setSopReady] = useState(false);
  const sopFormRef = useRef(null);
  const params = useParams();
  const [languages, setLanguages] = useState([]);
  const [enableAI, setEnableAI] = useState(false);
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const [loading, setLoading] = useState(false);

  const formatProcedures = (procedures) => {
    return procedures.map((procedure) => {
      if (procedure.images?.length > 0) {
        procedure.images = procedure?.images?.map((image) => renameFile(image));
      }
      if (procedure?.src) {
        procedure.src = procedure.src;
      }
      return procedure;
    });
  };

  const createSOP = async (lang) => {
    const formattedContent = sopContent.map((item) => {
      if (item.type === "section" && item.procedures) {
        item.procedures = formatProcedures(item?.procedures || []);
      }
      return item;
    });

    let data = {
      ...sopMetaData,
      content: storageType == ONPREMISE ? sopContent : formattedContent,
    };

    !data?.asset && delete data?.asset;
    setLoading(true);

    if (data) {
      try {
        const modifiedData = {
          ...data,
          name: {
            english: data?.name,
          },
          description: {
            english: data?.description,
          },
          content: {
            english: data?.content,
          },
          language: ["english", ...(languages || [])],
        };

        const res = await createSOPApi(modifiedData);
        if (res) {
          message.success(res?.data?.message);
          navigate(`/procedures/${res?.data?._id}`);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (enableAI) {
      const timer = setTimeout(() => {
        document.getElementById("background").style.backgroundImage = "none"; // Disable the background image after 5 seconds
      }, 2000);

      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }
  }, [enableAI]); // Run effect only once on component mount

  return (
    <CustomLayout
      header={
        <Typography className={typoStyles.h6}>
          <LeftOutlined
            style={{ marginRight: "6px" }}
            onClick={() => navigate(`/procedures`)}
          />
          Create Procedure
        </Typography>
      }
      footer={
        <>
          {((!params?.id && sopReady) || (params?.id && edit)) && (
            <Flex justify="flex-end" gap={12}>
              <Button
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (params?.id) {
                    setEdit(false);
                  } else {
                    sopFormRef.current.resetFields();
                    setSopContent([]);
                    setSopReady(false);
                    setsopMetaData({});
                    setEnableAI(false);
                  }
                }}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                className={btnStyles.mdBtn}
                type="primary"
                loading={loading}
                disabled={
                  editing.edit == false &&
                  (sopFormRef?.current
                    ? sopFormRef?.current?.getFieldsValue()?.name &&
                      sopFormRef?.current
                        ?.getFieldsValue()
                        ?.name?.replace(/\s/g, "")?.length > 3
                    : true) &&
                  sopContent.length > 0
                    ? false
                    : true
                }
                onClick={createSOP}
              >
                Save
              </Button>
            </Flex>
          )}
        </>
      }
    >
      <div
        id="background"
        style={{
          backgroundImage: enableAI && `url(${animateBG})`,
          backgroundSize: "50vw 100vh",
          backgroundRepeat: "repeat no-repeat",
          backgroundPosition: "0vw center",
        }}
      >
        {!sopReady ? (
          <Row gutter={[24, 24]}>
            <Col
              xs={{ span: 24, offset: 0 }}
              md={{ span: 24, offset: 0 }}
              lg={{ span: 20, offset: 2 }}
              xl={{ span: 16, offset: 4 }}
            >
              <InitialQuestions
                sopFormRef={sopFormRef}
                sopMetaData={sopMetaData}
                edit={edit}
                setEnableAI={setEnableAI}
                sopContent={sopContent}
                setSopContent={setSopContent}
                enableAI={enableAI}
                sopReady={sopReady}
                setSopReady={setSopReady}
                setsopMetaData={setsopMetaData}
                contentType={contentType}
              />
            </Col>
          </Row>
        ) : (
          <Row gutter={[24, 0]} style={{ paddingBottom: "0px" }}>
            <SOPElements
              isUploadLimitReached={isUploadLimitReached}
              setIsUnfilled={setIsUnfilled}
              ref={activeQuestionnaireRef}
              editing={editing}
              setEditing={setEditing}
              sopContent={sopContent}
              setSopContent={setSopContent}
              contentType={contentType}
              sopType={sopMetaData?.type}
            />
            <SOPLanguages languages={languages} setLanguages={setLanguages} />

            <>
              <Col
                xs={{ span: 18, offset: 6 }}
                md={{ span: 18, offset: 6 }}
                lg={{ span: 18, offset: 6 }}
                xl={{ span: 16, offset: 4 }}
                style={{ paddingBottom: "0px" }}
              >
                <SOPContent
                  editing={editing}
                  setEditing={setEditing}
                  isUploadLimitReached={isUploadLimitReached}
                  isUnfilled={isUnfilled}
                  setIsUnfilled={setIsUnfilled}
                  ref={activeQuestionnaireRef}
                  sopMetaData={sopMetaData}
                  setsopMetaData={setsopMetaData}
                  sopContent={sopContent}
                  sopFormRef={sopFormRef}
                  setSopContent={setSopContent}
                  onFinish={createSOP}
                  contentType={contentType}
                />
              </Col>
            </>
          </Row>
        )}
      </div>
    </CustomLayout>
  );
};

export default CreateSOP;
