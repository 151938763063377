import { Card, Empty, Result, Tag, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  convertUTCToLocalFormat,
  displayOoeFormula,
} from "../../../helpers/utility";

const OeeData = ({ assetDetails }) => {
  const customizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={12}
          textAnchor="middle"
          fill="#666"
          // transform="rotate(-35)"
        >
          {dayjs(payload.value).format("DD MMM")}
        </text>
        <text
          x={0}
          y={0}
          dy={25}
          textAnchor="middle"
          fill="#666"
          // transform="rotate(-35)"
        >
          {dayjs(payload.value).format("HH:mm")}
        </text>
      </g>
    );
  };

  return (
    <Card
      style={{ width: "100%" }}
      title={
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "2%",
            padding: "5px 0px",
          }}
        >
          <Typography style={{ whiteSpace: "normal", fontSize: 16 }}>
            OEE (Overall Equipment Effectiveness)
          </Typography>
          {assetDetails?.oee && (
            <Tag color="blue" style={{ marginRight: 0 }}>
              {`${(assetDetails?.oee * 100).toFixed(2)} %`}
            </Tag>
          )}
        </div>
      }
    >
      {assetDetails?.oee_data?.length > 0 ? (
        <div style={{ width: "90%", height: 240 }}>
          <ResponsiveContainer>
            <LineChart
              data={assetDetails?.oee_data?.map((i) => ({
                oee: Number(i?.oee?.toFixed(2)),
                name: dayjs(convertUTCToLocalFormat(i?.created_at)).format(
                  "DD MMM HH:mm"
                ),
              }))}
            >
              <XAxis dataKey={"name"} tick={customizedAxisTick} />
              <YAxis domain={[0, 1]} />
              <Tooltip
                formatter={(value, name) => [
                  `${Number(value * 100).toFixed(2)} %`,
                  name.toUpperCase(),
                ]}
              />
              <Line type="monotone" dataKey={"oee"} stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <Result
          icon={Empty.PRESENTED_IMAGE_SIMPLE}
          title={<Typography>No chart data</Typography>}
        />
      )}
      <div style={{ marginTop: 24 }}>{displayOoeFormula()}</div>
    </Card>
  );
};

export default OeeData;
