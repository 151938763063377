import React from "react";
import { Button, Empty } from "antd";
import btnStyles from "../../assets/styles/Custom/Button.module.css";

const PageNorFound = () => {
  return (
    <div
      className="flex-center"
      style={{
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Empty description="" />
      <h1>Page Not Found</h1>
      <Button
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/login`;
        }}
        className={btnStyles.mdBtn}
        type="primary"
      >
        Back to login
      </Button>
    </div>
  );
};
export default PageNorFound;
