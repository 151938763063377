export const USER = "user";
export const ORGANISATION = "organization";
export const ACTIVEDOMAIN = "activeDomain";
export const TOKEN_KEY = "token";
export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const FACILITYID = "facilityId";
export const createLOcation = ["admin"];
export const editLOcation = ["admin"];
export const deleteLOcation = ["admin"];
export const createAsset = ["admin", "manager"];
export const editAsset = ["admin", "manager", "supervisor"];
export const deleteAsset = ["admin", "manager"];
export const createWO = ["admin", "manager", "supervisor", "technicain"];
export const editWO = ["admin", "manager", "supervisor"];
export const deleteWO = ["admin", "manager"];
export const createWOStatus = ["admin", "manager", "supervisor"];
export const editWOStatus = [
  "admin",
  "manager",
  "supervisor",
  "technicain",
  "external",
];
export const deleteWOStatus = ["admin", "manager"];
export const verifyPM = ["admin", "supervisor", "manager"]; ////doubt
export const createPM = ["admin", "manager", "supervisor"];
export const editPM = ["admin", "manager", "supervisor"];
export const deletePM = ["admin", "manager"];
export const editTyut = ["admin", "manager", "supervisor"];
export const createInventory = ["admin", "manager"];
export const editInventory = ["admin", "manager"];
export const deleteInventory = ["admin", "manager"];
export const createPL = ["admin", "manager"];
export const editPL = ["admin", "manager"];
export const deletePL = ["admin", "manager"];
export const verifyMRO = ["admin", "supervisor", "manager"];

export const createPMStatus = ["admin", "manager", "supervisor"];
export const editPMStatus = [
  "admin",
  "manager",
  "supervisor",
  "technician",
  "external_service_providers",
];
export const deletePMStatus = ["admin", "manager"];
export const updatePMSOP = ["admin", "manager"]; ////doubt
export const addUsers = ["admin", "manager"];
export const editUsers = ["admin", "manager"];
export const deleteUsers = ["admin"];
export const readUsers = ["admin", "manager", "supervisor"];
export const addGroups = ["admin", "manager"];
export const editGroups = ["admin", "manager"];
export const readGroups = ["admin", "manager", "supervisor"];
export const deleteGroups = ["admin", "manager"];
export const createSOP = ["admin", "manager", "supervisor"];
export const editSOP = ["admin", "manager", "supervisor"];
export const deleteSOP = ["admin", "manager"];

export const TYUT = "Tyut";
export const SOP = "SOP";
export const INSPECTION = "Inspection";
export const MAINTANANCETEXT = "Maintenance";

export const ONPREMISE = "on-premise";
export const CLOUD = "cloud";
export const MAINTANANCE = "step-by-step maintenance";
export const SAFETY = "safety precautions and guidelines";
export const INPSECTION_CHECKS = "inspection & checks";
export const TROUBLESHOOTING = "troubleshooting";

export const POSTMAINTANCNE = "post-maintenance check and clean-up";
export const SOPTYPES = [
  { value: "Repair", label: "Troubleshooting", color: "magenta" },
  { label: "Inspection", value: "Inspection", color: "volcano" },
  { label: "Maintenance", value: "Maintenance", color: "purple" },
  // { label: "Production", value: "Production", color: "cyan" },
];

export const sopLanguages = [
  { label: "Hindi", value: "hindi" },
  { label: "Telugu", value: "telugu" },
  { label: "Tamil", value: "tamil" },
  { label: "Kannada", value: "kannada" },
  { label: "Arabic", value: "arabic" },
];

export const colorMap = [
  {
    tagColor: "blue",
    bgColor: "#e6f4ff",
    textColor: "#0958d9",
  },
  {
    tagColor: "purple",
    bgColor: "#f9f0ff",
    textColor: "#531dab",
  },
  {
    tagColor: "cyan",
    bgColor: "#e6fffb",
    textColor: "#08979c",
  },
  {
    tagColor: "orange",
    bgColor: "#fff7e6",
    textColor: "#d46b08",
  },
  {
    tagColor: "magenta",
    bgColor: "#fff0f6",
    textColor: "#c41d7f",
  },
];

export const PAGESIZE = 50;

export const frequencyOptions = [
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
];

export const MES_LINK =
  "https://cmms-prod-public-resorces.s3.ap-south-1.amazonaws.com/Developer+Documentation_+Integrating+Bolt+with+MES.pdf";
