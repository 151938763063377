import { Button, Upload, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { getAssetsPath, renameFile, uploadFunc } from "../../helpers/utility";
import {
  CloseOutlined,
  CloudUploadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import { UserContext } from "../../context/UserProvider";
import { ONPREMISE } from "../../constants/defaultKeys";

export const DisplayFiles = ({
  fileLists,
  onDelete,
  disabled,
  storageType,
}) => {
  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        alignItems: "flex-start",
        gap: "12px",
      }}
    >
      {fileLists?.map((eachFile, index) => {
        return (
          <div className={"fileListItem"} key={index}>
            <a href={eachFile.url} target="_blank" style={{ color: "#101828" }}>
              <span>
                {storageType == ONPREMISE
                  ? eachFile?.name?.slice(0, 50)
                  : renameFile(eachFile?.name)?.slice(0, 50)}
              </span>
            </a>

            <CloseOutlined
              style={{ cursor: disabled && "not-allowed" }}
              onClick={(e) => {
                if (!disabled) {
                  onDelete(eachFile);
                }
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

const CommonUpload = ({
  documents,
  onUpload,
  dragger = true,
  buttonText = "",
  showFilelist = true,
  maxCount = 10,
  disabled = false,
  onDelete,
}) => {
  const [fileLists, setFileLists] = useState([]);
  const { userDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const props = {
    name: "file",
    multiple: false,
  };
  const { Dragger } = Upload;

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const uploadImage = async (info, key) => {
    try {
      setLoading(true);
      const resp = await uploadFunc(info, storageType);
      if (resp) {
        onUpload(resp);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const createFileLists = (documents) => {
    const fileList = documents?.map((doc, index) => {
      return {
        uid: index,
        name: doc,
        status: "done",
        url: getAssetsPath(doc, storageType),
      };
    });
    return fileList;
  };

  const customOnChange = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      uploadImage(info);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  useEffect(() => {
    const newFileLists = createFileLists(documents);
    setFileLists(newFileLists);
  }, [documents]);

  return (
    <>
      <div>
        {dragger ? (
          <Dragger
            customRequest={dummyRequest}
            {...props}
            showUploadList={false}
            onChange={customOnChange}
            accept={[".pdf", ".xlsx"]}
            maxCount={1}
            style={{
              backgroundColor: "#fff",
              border: "1px solid #EAECF0",
            }}
          >
            <CloudUploadOutlined style={{ fontSize: 40 }} />

            <p>
              <span style={{ color: "#475467" }} className="ant-upload-hint">
                Click to upload
              </span>{" "}
              <span className="ant-upload-text"> Existing SOPs</span>
            </p>
          </Dragger>
        ) : (
          <Upload
            showUploadList={false}
            maxCount={maxCount}
            className="assetsUpload"
            {...props}
            accept={[".pdf", ".xlsx"]}
            onChange={customOnChange}
            customRequest={dummyRequest}
          >
            <Button
              loading={loading}
              className={btnStyles.mdBtn}
              disabled={disabled}
            >
              {buttonText ? buttonText : "Upload  documents"}
              <UploadOutlined />
            </Button>
          </Upload>
        )}
      </div>
      {showFilelist && (
        <DisplayFiles
          fileLists={fileLists}
          onDelete={onDelete}
          disabled={disabled}
          storageType={storageType}
        />
      )}
    </>
  );
};

export default CommonUpload;
