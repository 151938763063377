import { Form, Input } from "antd";
import React from "react";
import inputStyles from "../../../../assets/styles/Custom/Input.module.css";

function CourseMetaData({ formRef }) {
  return (
    <Form layout="vertical" requiredMark={false} ref={formRef}>
      <Form.Item
        label="Course name"
        name="name"
        rules={[
          {
            validator: (_, value, callback) => {
              if (!value) callback("Please provide course title!");
              else if (value?.trim()?.length < 3)
                return callback("Provide a title of at least 3 alphabets");
              else if (value?.trim()?.length > 100)
                return callback("Provide a title of less than 100 alphabets");
              else if (value?.trim()?.length === 0)
                return callback("Please provide course title!");
              else callback();
            },
          },
        ]}
      >
        <Input
          style={{ minWidth: "100%" }}
          className={inputStyles.smInput}
          maxLength={254}
          placeholder="Provide course name"
        />
      </Form.Item>
      <Form.Item
        label="Course description"
        name="description"
        rules={[{ message: "Course description is required", required: true }]}
      >
        <Input.TextArea
          style={{ minWidth: "100%" }}
          className={inputStyles.smInput}
          placeholder="Provide course description"
        />
      </Form.Item>
    </Form>
  );
}

export default CourseMetaData;
