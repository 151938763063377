import { Empty, Row } from "antd";
import React, { useContext, useState } from "react";
import CustomLayout from "../Layout";
import GroupsTable from "./GroupsTable";
import CreateOrEditGroup from "./CreateOREditGroup";
import { readGroups } from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserProvider";
import OrgSettingsHeader from "../Organisations/OrgSettingsHeader";

const Groups = () => {
  const [open, setOpen] = useState(false);
  const [currGroup, setCurrGroup] = useState({});
  const { userDetails } = useContext(UserContext);
  const [groupsCount, setGroupsCount] = useState(0);

  return (
    <CustomLayout header={<OrgSettingsHeader count={groupsCount} />}>
      <Row gutter={[0, 18]}>
        {readGroups.includes(userDetails?.activeOrg?.role) ? (
          <GroupsTable
            open={open}
            setOpen={setOpen}
            currGroup={currGroup}
            setCurrGroup={setCurrGroup}
            setGroupsCount={setGroupsCount}
          />
        ) : (
          <div
            className="flex-center"
            style={{ minHeight: "50vh", width: "100%" }}
          >
            <Empty description="You are not authorised to view users and teams" />
          </div>
        )}
      </Row>
      <CreateOrEditGroup
        open={open}
        setOpen={setOpen}
        currGroup={currGroup}
        setCurrGroup={setCurrGroup}
      />
    </CustomLayout>
  );
};

export default Groups;
