import { Button, Col, Image, Row, Tag, Typography } from "antd";
import React, { useContext, useState } from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import { deleteDocs, getAssetsPath } from "../../../../helpers/utility";
import VideoPlayer from "../../../Blocks/VideoPlayer";
import { UserContext } from "../../../../context/UserProvider";

const ViewSectionQuestion = ({
  index,
  answers,
  questionData,
  setAnswers,
  disabled,
}) => {
  const [editableStr, setEditableStr] = useState(questionData?.question);
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  const isEnglishText = (text) => /^[A-Za-z0-9\s.,!?;:'"()-]+$/.test(text);

  return (
    <Row
      gutter={[24, 48]}
      className="sopQuestion"
      style={{
        padding: "12px",
        borderRadius: "8px",
        margin: "12px 4px",
        boxSizing: "border-box",
      }}
    >
      <Col
        span={16}
        style={{
          alignItems: "flex-start",
          flexDirection: "column",
          gap: "6px",
          borderRight: "1px dashed rgb(205 210 219)",
          display: "flex",
        }}
      >
        <div className="flex-start" style={{ alignItems: "flex-start" }}>
          <span style={{ marginTop: "2px" }}> {index + 1}.</span>
          <Typography.Paragraph
            style={{
              marginBottom: "0px",
              fontSize: isEnglishText(editableStr) ? 16 : 20,
            }}
          >
            {editableStr}
          </Typography.Paragraph>
        </div>

        {Object.keys(questionData?.things)?.map((eachOption) => {
          if (answers?.[questionData?.id]?.value == eachOption) {
            const helperTextIndex =
              questionData?.things[eachOption]?.enable.indexOf("helperText");

            const helperText = (
              <Typography
                style={{
                  gap: "6px",
                  alignItems: "flex-start",
                  marginLeft: "24px",
                }}
                className="flex-start"
              >
                <Tag color="processing" style={{ margin: "0px" }}>
                  Note
                </Tag>
                : {questionData?.things[eachOption]?.helperText}{" "}
              </Typography>
            );

            return helperTextIndex ? [helperText] : [];
          }
        })}
        <Row
          gutter={[24, 24]}
          style={{ maxWidth: "100%", width: "100%", marginLeft: "12px" }}
        >
          {questionData?.images?.map((eachImg) => {
            return (
              <Col span={8}>
                <Image
                  src={getAssetsPath(eachImg, storageType)}
                  alt="Image"
                  style={{
                    zIndex: 100,
                    width: "100%",
                    height: "150px",
                    borderRadius: "8px",
                  }}
                />
              </Col>
            );
          })}
          {questionData?.src && (
            <Col span={8} style={{ borderRadius: "8px", overflow: "hidden" }}>
              <VideoPlayer
                url={questionData.previewURL || questionData.src}
                width="100%"
                height="150px"
                style={{ borderRadius: "8px", overflow: "hidden" }}
              />
            </Col>
          )}
        </Row>
      </Col>
      <Col
        span={8}
        style={{
          alignItems: "flex-start",
          flexDirection: "column",
          gap: "6px",
          display: "flex",
          paddingLeft: "24px",
        }}
      >
        <div
          className="flex-start"
          style={{
            justifyContent: "flex-end",
            width: "100%",
            gap: "12px",
          }}
        >
          {Object.keys(questionData?.things)?.map((eachChoice, index) => {
            return (
              <Button
                style={{
                  backgroundColor:
                    answers?.[questionData?.id]?.value == eachChoice
                      ? questionData?.things?.[eachChoice]?.color
                      : "#fff",
                  borderRadius: "4px",
                  color:
                    answers?.[questionData?.id]?.value == eachChoice
                      ? "#fff"
                      : "#7369F4",
                  border: `1px solid ${
                    answers?.[questionData?.id]?.value == eachChoice
                      ? questionData?.things?.[eachChoice]?.color
                      : "#7369F4"
                  }`,
                  textAlign: "center",
                  justifySelf: "flex-end",
                }}
                value={eachChoice}
              >
                {eachChoice}
              </Button>
            );
          })}
        </div>
        {answers?.[questionData?.id] && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              {Object.keys(questionData?.things)?.map((eachOption) => {
                // Find index of "helperText" in the enable list
                if (answers?.[questionData?.id]?.value == eachOption) {
                  const restOfEnableList = [
                    ...questionData?.things[eachOption]?.enable,
                  ];

                  // Render helper text first
                  return [
                    ...restOfEnableList.map((each) => {
                      if (each === "reason") {
                        return (
                          (!disabled ||
                            (disabled &&
                              answers[questionData?.id]?.reason)) && (
                            <div style={{ width: "100%" }}>
                              <Typography
                                style={{
                                  gap: "6px",
                                  alignItems: "flex-start",
                                }}
                                className="flex-start"
                              >
                                <Tag
                                  color="processing"
                                  style={{ margin: "0px" }}
                                >
                                  Reason
                                </Tag>
                                :
                                <span>{answers[questionData?.id]?.reason}</span>
                              </Typography>
                            </div>
                          )
                        );
                      }
                    }),
                  ];
                  // }
                }
              })}
            </div>
          </>
        )}
        <div style={{ justifyContent: "flex-start" }} className="flex-center">
          {answers?.[questionData?.id]?.images?.map((eachImg) => {
            return (
              <div
                style={{
                  height: "160px",
                  position: "relative",
                }}
              >
                {!disabled && (
                  <CloseCircleFilled
                    onClick={() => {
                      if (questionData.id) {
                        deleteDocs(eachImg, storageType);
                        setAnswers({
                          ...answers,
                          [questionData.id]: {
                            ...answers[questionData?.id],
                            images: answers[questionData?.id]?.images?.filter(
                              (each) => eachImg !== each
                            ),
                          },
                        });
                      }
                    }}
                    style={{
                      position: "absolute",
                      top: -8,
                      right: -10,
                      zIndex: 1,
                      color: "red",
                      fontSize: "18px",
                    }}
                  />
                )}
                <Image
                  src={getAssetsPath(eachImg, storageType)}
                  alt="Image"
                  height={"150px"}
                  width={"150px"}
                  style={{ zIndex: 100 }}
                />
              </div>
            );
          })}
        </div>
      </Col>
    </Row>
  );
};

export default ViewSectionQuestion;
