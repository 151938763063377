import {
  Card,
  Col,
  DatePicker,
  Empty,
  Result,
  Row,
  Skeleton,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  displayFormattedDuration,
  displayStatsCard,
  displayTitleValue,
} from "./helpers";
import dayjs from "dayjs";
import {
  getMWStatsApi,
  getMroChartDataApi,
} from "../../services/dashboard.services";
import WaffleChart from "./WaffleChart";
import {
  capitalizeFirstLetter,
  convertToUTCFormat,
} from "../../helpers/utility";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const { RangePicker } = DatePicker;

const BreakdownAnalysis = () => {
  const [stats, setStats] = useState({ maintenances: {}, workorders: {} });
  const [mroChartData, setMroChartData] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "month"),
    end_date: dayjs().add(1, "month"),
  });
  const [chartLoading, setChartLoading] = useState(false);

  const getStats = async () => {
    try {
      let params = {};
      if (dateFilter?.start_date) {
        params.start_date = convertToUTCFormat(
          dateFilter.start_date.startOf("day")
        );
      }
      if (dateFilter?.end_date) {
        params.end_date = convertToUTCFormat(dateFilter.end_date.endOf("day"));
      }
      const queryString = new URLSearchParams(params);
      const resp = await getMWStatsApi(queryString);
      setStats(resp?.data);
    } catch (error) {}
  };

  const getMroChartData = async () => {
    let query = {};
    if (dateFilter?.start_date) {
      query.start_date = convertToUTCFormat(
        dateFilter.start_date.startOf("day")
      );
    }
    if (dateFilter?.end_date) {
      query.end_date = convertToUTCFormat(dateFilter.end_date.endOf("day"));
    }
    setChartLoading(true);
    try {
      const res = await getMroChartDataApi(query);
      setMroChartData(res?.data);
    } catch (error) {
    } finally {
      setChartLoading(false);
    }
  };

  useEffect(() => {
    getStats();
    getMroChartData();
  }, [dateFilter]);

  const customizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={12}
          textAnchor="end"
          fill="#666"
          transform="rotate(-25)"
        >
          {dayjs(payload.value).format("DD MMM")}
        </text>
      </g>
    );
  };

  return (
    <>
      <Col
        span={24}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <RangePicker
          format="DD-MMM-YYYY"
          value={[dateFilter.start_date, dateFilter.end_date]}
          onChange={(dates, dateString) => {
            setDateFilter({
              start_date: dates?.[0],
              end_date: dates?.[1],
            });
          }}
        />
      </Col>
      <Col
        span={24}
        style={{ paddingBottom: 20, height: "80vh", overflowY: "auto" }}
      >
        <Row gutter={[18, 18]}>
          <Col xs={24} md={24} lg={12} xl={12} style={{ display: "flex" }}>
            <Card
              className="mainCards"
              styles={{
                header: {
                  border: "0px",
                },
                body: {
                  height: "80%",
                },
              }}
              title={"MROs"}
              style={{ width: "100%" }}
            >
              <Row gutter={[8, 0]} style={{ height: "100%" }}>
                {displayStatsCard(
                  "Non Critical",
                  stats?.mros?.nonCriticalMROCount || 0,
                  "warning",
                  6
                )}
                <Col span={18}>
                  <Row gutter={[8, 12]} style={{ height: "100%" }}>
                    {displayStatsCard(
                      "Critical (Breakdown events)",
                      stats?.mros?.criticalMROCount || 0,
                      "warning",
                      24
                    )}
                    {displayStatsCard(
                      "Mechanical",
                      stats?.mros?.criticalMechanicalCount || 0,
                      "warning",
                      8
                    )}
                    {displayStatsCard(
                      "Electrical",
                      stats?.mros?.criticalElectricalCount || 0,
                      "warning",
                      8
                    )}
                    {displayStatsCard(
                      "Others",
                      stats?.mros?.criticalOthersCount || 0,
                      "warning",
                      8
                    )}
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} md={24} lg={12} xl={12} style={{ display: "flex" }}>
            <Card
              title="Breakdown time"
              className="mainCards"
              styles={{
                header: {
                  border: "0px",
                },
              }}
              style={{ width: "100%" }}
            >
              <Row gutter={[12, 0]}>
                <Col span={24}>
                  {displayFormattedDuration(stats?.mros?.totalCriticalMROTime)}
                </Col>
                <Col
                  span={14}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Space direction="vertical" style={{ width: "100%" }}>
                    {displayTitleValue({
                      title: "Others",
                      value: stats?.mros?.criticalOthersTime,
                      type: "duration",
                      dotColor: "#DCDCDC",
                    })}
                    {displayTitleValue({
                      title: "Electrical",
                      value: stats?.mros?.criticalElectricalTime,
                      type: "duration",
                      dotColor: "#FFA384",
                    })}
                    {displayTitleValue({
                      title: "Mechanical",
                      value: stats?.mros?.criticalMechanicalTime,
                      type: "duration",
                      dotColor: "#CB6767",
                    })}
                  </Space>
                </Col>
                <Col span={10}>
                  <WaffleChart
                    height={240}
                    total={stats?.mros?.totalCriticalMROTime || 0}
                    data={[
                      {
                        id: "Mechanical",
                        label: "Mechanical",
                        value: stats?.mros?.criticalMechanicalTime || 0,
                      },
                      {
                        id: "Electrical",
                        label: "Electrical",
                        value: stats?.mros?.criticalElectricalTime || 0,
                      },
                      {
                        id: "Others",
                        label: "Others",
                        value: stats?.mros?.criticalOthersTime || 0,
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24}>
            <Card
              title="Breakdown time chart"
              className="mainCards"
              styles={{
                header: {
                  border: "0px",
                },
                body: {
                  display: "flex",
                  justifyContent: "center",
                },
              }}
              style={{ width: "100%" }}
            >
              {chartLoading ? (
                <Skeleton active />
              ) : mroChartData?.length > 0 ? (
                <div style={{ width: "98%", height: 240 }}>
                  <ResponsiveContainer>
                    <LineChart
                      data={mroChartData?.sort(
                        (a, b) =>
                          dayjs(a?.date).valueOf() - dayjs(b?.date).valueOf()
                      )}
                      margin={{ left: 45, right: 10, bottom: 10 }}
                    >
                      <XAxis
                        dataKey={"date"}
                        interval={0}
                        tick={customizedAxisTick}
                      />
                      <Tooltip
                        formatter={(value, name) => [
                          `${Number(value).toFixed(2)} hrs`,
                          capitalizeFirstLetter(name),
                        ]}
                      />
                      <Line
                        type="monotone"
                        dataKey={"others"}
                        stroke="#A9A9A9"
                      />
                      <Line
                        type="monotone"
                        dataKey={"electrical"}
                        stroke="#FFA384"
                      />
                      <Line
                        type="monotone"
                        dataKey={"mechanical"}
                        stroke="#CB6767"
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <Result
                  icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  title={<Typography>No chart data</Typography>}
                />
              )}
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default BreakdownAnalysis;
