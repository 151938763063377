import axiosInstance from "../config/axios/axios.config";

export const createOrganisationApi = (payload) => {
  return axiosInstance.post("/organization", payload);
};
export const getOrganisationApi = () => {
  return axiosInstance.get("/organization");
};
export const updateOrganisationApi = (payload) => {
  return axiosInstance.put("/organization", payload);
};
export const getDomainDetailsApi = (domain) => {
  return axiosInstance.get(`/auth/domain/${domain}`);
};
export const getMesToken = () => {
  return axiosInstance.get(`/token/webhook`);
};
export const generateMesTokenApi = () => {
  return axiosInstance.post(`/token/webhook`, {});
};

export const getTaskTypeApi = () => {
  return axiosInstance.get(`/tasktype`);
};

export const getTaskTypeByIdApi = (id) => {
  return axiosInstance.get(`/tasktype/${id}`);
};

export const createTaskTypeApi = (payload) => {
  return axiosInstance.post(`/tasktype`, payload);
};

export const updateTaskTypeApi = (id, payload) => {
  return axiosInstance.patch(`/tasktype/${id}`, payload);
};

export const deleteTaskTypeApi = (id) => {
  return axiosInstance.delete(`/tasktype/${id}`);
}