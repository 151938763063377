import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Row, Col, Modal, Flex, Typography, Card, Popover } from "antd";
import { LeftOutlined, ShareAltOutlined } from "@ant-design/icons";
import CustomLayout from "../../../Layout";
import SideMenu from "../../../Layout/SideMenu";
import SOPElements from "../../../Sops/CreateSOP/SOPElements";
import SOPContent from "../../../Sops/CreateSOP/SOPContent";
import PublishTyut from "./PublishTyut";
import btnStyles from "../../../../assets/styles/Custom/Button.module.css";
import typoStyles from "../../../../assets/styles/Custom/Typography.module.css";
import { TYUT } from "../../../../constants/defaultKeys";

function CreateTyut() {
  const tyutFormRef = useRef(null);
  const navigate = useNavigate();
  const activeQuestionnaireRef = useRef(null);
  const [tyutContent, setTyutContent] = useState([]);
  const [isPreviewOpen, setPreview] = useState(false);
  const [tyutMetaData, setTyutMetaData] = useState({});
  const [editing, setEditing] = useState({ edit: false, data: null });
  const [isUnfilled, setIsUnfilled] = useState("#406aff");
  const [isUploadLimitReached, setUploadLimitReached] = useState(false);
  const [tyutTags, setTuytTags] = useState(
    tyutMetaData.tags?.map((eachTag) => eachTag.name) || []
  );

  return (
    <>
      <CustomLayout
        sideContent={<SideMenu />}
        header={
          <Flex justify="space-between">
            <Typography className={typoStyles.h6}>
              <LeftOutlined
                style={{ marginRight: "6px" }}
                onClick={() => navigate("/trainings?type=tyut")}
              />
              Create Tyut
            </Typography>
          </Flex>
        }
      >
        <Row style={{ paddingBottom: "0px", width: "100%" }}>
          <Popover
            placement="left"
            content={
              <>
                {tyutContent?.filter((each) => each.type == "video")?.length ==
                0 ? (
                  <Typography>Add atleast one video</Typography>
                ) : tyutContent?.length == 0 ? (
                  <Typography>Add tyuts to proceed</Typography>
                ) : (
                  <Typography>Proceed to pubish</Typography>
                )}
              </>
            }
            trigger="hover"
          >
            <Button
              className={btnStyles.lgBtn}
              style={{
                position: "fixed",
                right: "2.5rem",
                top: "5rem",
                zIndex: 100,
              }}
              type="primary"
              disabled={
                editing.edit == false &&
                tyutContent.length > 0 &&
                tyutContent?.filter((each) => each.type == "video")?.length > 0
                  ? false
                  : true
              }
              onClick={() => {
                if (!tyutFormRef.current?.getFieldsValue().name) {
                  tyutFormRef.current?.submit();
                  setTyutMetaData((prev) => ({ ...prev, tags: tyutTags }));
                } else {
                  tyutFormRef.current?.submit();
                  setTyutMetaData((prev) => ({ ...prev, tags: tyutTags }));
                  setPreview(true);
                }
              }}
            >
              Publish
              <ShareAltOutlined />
            </Button>
          </Popover>

          <Col
            xs={{ span: 18, offset: 6 }}
            md={{ span: 18, offset: 6 }}
            lg={{ span: 18, offset: 6 }}
            xl={{ span: 16, offset: 4 }}
            style={{ paddingBottom: "0px" }}
          >
            <Card style={{ width: "100%" }}>
              <SOPContent
                editing={editing}
                setEditing={setEditing}
                tyutTags={tyutTags}
                setTuytTags={setTuytTags}
                contentType={TYUT}
                isUploadLimitReached={isUploadLimitReached}
                isUnfilled={isUnfilled}
                setIsUnfilled={setIsUnfilled}
                ref={activeQuestionnaireRef}
                sopMetaData={tyutMetaData}
                setsopMetaData={setTyutMetaData}
                sopContent={tyutContent}
                sopFormRef={tyutFormRef}
                setSopContent={setTyutContent}
              />
            </Card>
            <SOPElements
              contentType={TYUT}
              sopContent={tyutContent}
              setSopContent={setTyutContent}
              isUploadLimitReached={isUploadLimitReached}
              setIsUnfilled={setIsUnfilled}
              ref={activeQuestionnaireRef}
              setEditing={setEditing}
            />
          </Col>
          {isPreviewOpen && (
            <Modal
              style={{ minWidth: "80%" }}
              open={isPreviewOpen}
              footer={false}
              centered
              onCancel={() => setPreview(false)}
            >
              <PublishTyut
                tyutContent={tyutContent}
                tyutMetaData={tyutMetaData}
                setPreview={setPreview}
              />
            </Modal>
          )}
        </Row>
      </CustomLayout>
    </>
  );
}

export default CreateTyut;
