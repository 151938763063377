import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomLayout from "../../Layout";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import buttonStyles from "../../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import {
  createAssetCategory,
  deleteAssetCategoryApi,
  getAllAssetsApi,
  getAssetCategoryDetails,
  updateAssetCategory,
} from "../../../services/assets.services";

const CreateAssetCategory = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();

  const [assets, setAssets] = useState([]);
  const [assetCategoryDetails, setAssetCategoryDetails] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const fetchAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: asset?.name,
          value: asset?._id,
        }))
      );
    } catch (error) {}
  };

  const fetchAssetCategoryDetails = async () => {
    try {
      const res = await getAssetCategoryDetails(params?.id);
      let data = res?.data;
      if (data) {
        let assetsList = [],
          assetIds = [];
        data?.assets?.map((i) => {
          assetsList.push({
            label: i?.name,
            value: i?._id,
          });
          assetIds.push(i?._id);
        });
        setAssetCategoryDetails(data);
        setSelectedAssets(assetsList);
        form.setFieldsValue({
          name: data?.name,
          description: data?.description || "",
          assets: assetIds,
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchAssets();
    if (params?.id) {
      fetchAssetCategoryDetails();
    } else {
      setEditMode(true);
    }
  }, [refresh]);

  const onFinish = async (values) => {
    if (selectedAssets?.length === 0) {
      message.error("Please select atleast 1 asset");
    } else {
      let payload = { ...values, description: values?.description || "" };

      setBtnLoading(true);
      try {
        const resp = params?.id
          ? await updateAssetCategory(params?.id, payload)
          : await createAssetCategory(payload);
        if (resp) {
          !params?.id && navigate(`/asset-categories`);
          message.success(
            `Asset category ${params?.id ? "updated" : "added"} successfully`
          );
          params?.id && setEditMode(false);
        }
      } catch (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!!"
        );
      } finally {
        setBtnLoading(false);
        setRefresh((prev) => !prev);
      }
    }
  };

  const deleteAssetCategory = async () => {
    try {
      const resp = await deleteAssetCategoryApi(params?.id);
      if (resp) {
        navigate("/asset-categories");
        message.success("Asset category deleted successfully");
      }
    } catch (error) {
      message.error(error?.repsonse?.data?.message);
    }
  };

  return (
    <CustomLayout
      header={
        <>
          <Flex justify="space-between" align="center">
            <div className="flex-start" style={{ gap: 6 }}>
              <LeftOutlined
                className={typoStyles.h6}
                onClick={() => navigate("/asset-categories")}
              />
              <Typography className={typoStyles.h6}>
                {params?.id ? "Update Asset Category" : "Create Asset Category"}
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
              {params?.id && (
                <Popconfirm
                  icon={null}
                  description="Are you sure to delete this asset category?"
                  onConfirm={(e) => {
                    deleteAssetCategory();
                  }}
                >
                  <Button className={buttonStyles.mdBtn}>
                    <span className="hiddenBelowTab">Delete</span>
                    <DeleteOutlined style={{ margin: "0px" }} />
                  </Button>
                </Popconfirm>
              )}
              {!editMode && params?.id && (
                <Button
                  className={buttonStyles.mdBtn}
                  onClick={() => setEditMode(true)}
                  type="primary"
                >
                  <span className="hiddenBelowTab">Edit</span>

                  <EditOutlined style={{ margin: "0px" }} />
                </Button>
              )}
            </div>
          </Flex>
        </>
      }
      footer={
        <>
          {(!params?.id || (params?.id && editMode)) && (
            <Flex justify="flex-end" gap={12}>
              <Button
                className={buttonStyles.mdBtn}
                onClick={() => {
                  if (params?.id) {
                    setEditMode(false);
                    fetchAssetCategoryDetails();
                  } else {
                    setSelectedAssets([]);
                    form.resetFields();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={buttonStyles.mdBtn}
                type="primary"
                onClick={form.submit}
                loading={btnLoading}
              >
                Save
              </Button>
            </Flex>
          )}
        </>
      }
    >
      <Form
        name="asset-category-form"
        form={form}
        layout="vertical"
        requiredMark={false}
        autoComplete="off"
        onFinish={onFinish}
      >
        <Row>
          <Col span={12}>
            <Card
              title="Asset Category Details"
              styles={{
                header: { backgroundColor: "#fff" },
              }}
            >
              <Row gutter={[20, 16]}>
                <Col span={24}>
                  <Form.Item
                    label="Asset category name"
                    name={"name"}
                    rules={[
                      {
                        required: true,
                        message: "Please enter asset category name",
                      },
                      {
                        whitespace: true,
                        message:
                          "Asset category name cannot be empty or contain only spaces",
                      },
                      {
                        min: 3,
                        message:
                          "Asset category name should contain atleast 3 characters",
                      },
                      {
                        max: 100,
                        message:
                          "Asset category name cannot excced 100 characters",
                      },
                    ]}
                  >
                    <Input
                      className={inputStyles.smInput}
                      style={{ width: "100%" }}
                      placeholder="Enter asset category name"
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Description"
                    name={"description"}
                    rules={[
                      {
                        whitespace: true,
                        message:
                          "Description cannot be empty or contain only spaces",
                      },
                      {
                        min: 3,
                        message:
                          "Description should contain atleast 3 characters",
                      },
                      {
                        max: 500,
                        message: "Description cannot excced 500 characters",
                      },
                    ]}
                  >
                    <Input
                      className={inputStyles.smInput}
                      style={{ width: "100%" }}
                      placeholder="Enter description"
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={"Assets"} name={"assets"}>
                    <Select
                      placeholder="Select assets"
                      style={{
                        width: "100%",
                      }}
                      className={inputStyles.smSelect}
                      mode="multiple"
                      showSearch={true}
                      optionFilterProp="label"
                      maxTagCount={0}
                      maxTagPlaceholder={`${selectedAssets?.length} assets selected`}
                      options={assets}
                      disabled={!editMode}
                      value={selectedAssets?.map((i) => i?.value)}
                      onChange={(value, option) => {
                        setSelectedAssets(option);
                        form.setFieldValue("assets", value);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {selectedAssets && selectedAssets?.length > 0 && (
                    <Row
                      gutter={[16, 16]}
                      style={{ maxHeight: "40vh", overflowY: "auto" }}
                    >
                      {selectedAssets?.map((item) => (
                        <Col span={12} style={{ display: "flex" }}>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              padding: 8,
                              border: "1px solid #d0d5dd",
                              borderRadius: 8,
                              gap: 12,
                            }}
                          >
                            <Typography style={{ wordBreak: "break-all" }}>
                              {item?.label}
                            </Typography>
                            {editMode && (
                              <CloseOutlined
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  let currAssets = [...selectedAssets];
                                  let filteredAssets = currAssets?.filter(
                                    (i) => i?.value !== item?.value
                                  );
                                  setSelectedAssets(filteredAssets);
                                  form.setFieldValue(
                                    "assets",
                                    filteredAssets?.map((j) => j?.value)
                                  );
                                }}
                              />
                            )}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </CustomLayout>
  );
};

export default CreateAssetCategory;
