import { Col, Empty, Row, Skeleton, Typography } from "antd";
import { useLocation } from "react-router-dom";
import React, { Fragment, useContext, useEffect, useState } from "react";
import VideoPlayer from "../../Blocks/VideoPlayer";
import CustomCard from "../../Blocks/CustomCard";
import CustomCheckBox from "../../Blocks/CustomCheckBox";
import CustomRadioButton from "../../Blocks/CustomRadioButton";
import PdfViewer from "../../Blocks/PDFViewer";
import "../../Sops/SOP.css";
import ViewSection from "../../Sops/CreateSOP/AddElem/ViewSection";
import { UserContext } from "../../../context/UserProvider";

function ViewSOP({ sopData, setSopData, edit, answers, setAnswers }) {
  const { userDetails } = useContext(UserContext);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  let sopId = sopData?._id;

  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  useEffect(() => {
    sopId = sopData?._id;
  }, [location]);

  function RenderItems(data) {
    switch (data?.type) {
      case "document":
        return <PdfViewer pdfUrl={data?.src} />;
      case "text":
        return (
          <CustomCard>
            <Typography>{data?.text}</Typography>
          </CustomCard>
        );
      case "video":
        return (
          <CustomCard>
            <VideoPlayer url={data?.src} height="500px" width="100%" />
          </CustomCard>
        );
      case "checkbox":
        return (
          <CustomCheckBox
            data={data}
            title={data?.question}
            onChange={(e) => {
              setAnswers({ ...answers, [data?.id]: e });
            }}
            disabled={!edit}
            answers={answers}
            storageType={storageType}
          />
        );
      case "radio":
        return (
          <CustomRadioButton
            data={data}
            title={data?.question}
            disabled={!edit}
            onChange={(e) => {
              setAnswers({ ...answers, [data?.id]: e?.target?.value });
            }}
            answers={answers}
            storageType={storageType}
          />
        );
      case "section":
        return (
          <ViewSection
            data={data}
            title={data?.question}
            disabled={!edit}
            onChange={(e) => {
              setAnswers({ ...answers, [data?.id]: e?.target?.value });
            }}
            setAnswers={setAnswers}
            answers={answers}
          />
        );
      default:
        return null;
    }
  }

  return (
    <Fragment>
      <Row
        gutter={[24, 24]}
        align="middle"
        justify={"flex-start"}
        style={{
          position: "relative",
        }}
      >
        {Object?.keys(sopData)?.length > 0 && (
          <Col span={24}>
            <CustomCard>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: 400,
                      lineHeight: "28px",
                    }}
                  >
                    {sopData?.name}
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography.Paragraph
                    ellipsis={{
                      rows: 2,
                      expandable: true,
                      symbol: "read more",
                    }}
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: "20px",
                    }}
                  >
                    {sopData?.description}
                  </Typography.Paragraph>
                </Col>
              </Row>
            </CustomCard>
          </Col>
        )}
        <Skeleton active loading={loading}>
          {sopData && sopData?.content?.length !== 0 ? (
            sopData?.content?.map((item, index) => (
              <Col span={24} key={index}>
                {RenderItems(item)}
              </Col>
            ))
          ) : (
            <Col span={24}>
              <Empty />
            </Col>
          )}
        </Skeleton>
      </Row>
    </Fragment>
  );
}

export default ViewSOP;
