import { Col, Space, Typography } from "antd";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import { formatHours } from "../../helpers/utility";

export const displayFormattedDuration = (
  value,
  color = "#7369F4",
  fontSizeLevel = "custom24"
) => {
  let durationObj = formatHours(Number(value) > 0 ? Number(value) : 0);
  return (
    <Typography className={typoStyles[fontSizeLevel]} style={{ color: color }}>
      {durationObj?.days ? durationObj?.days : ""}
      {durationObj?.days ? (
        <span className={typoStyles.body3}> days </span>
      ) : (
        ""
      )}
      {durationObj?.hours}
      <span className={typoStyles.body3}> hrs </span>
      {!durationObj?.days && (
        <>
          {durationObj?.minutes}
          <span className={typoStyles.body3}> mins </span>
        </>
      )}
    </Typography>
  );
};

export const displayStatsCard = (
  title,
  value,
  type,
  spanValue,
  subtitle = ""
) => {
  return (
    <Col span={spanValue} style={{ display: "flex" }}>
      <div
        className="flex-center"
        style={{
          width: "100%",
          padding: "12px 0px",
          flexDirection: "column",
          gap: "8px",
          border: "2px solid #EAECF0",
          borderRadius: "12px",
        }}
      >
        <Typography
          className={typoStyles.body3}
          style={{ fontWeight: 500, textAlign: "center" }}
        >
          {title}
        </Typography>
        {subtitle !== "" && (
          <Typography
            className={typoStyles.body1}
            style={{ textAlign: "center" }}
          >
            {subtitle}
          </Typography>
        )}
        {type === "duration" ? (
          displayFormattedDuration(value)
        ) : (
          <Typography
            className={typoStyles.custom24}
            style={{
              color: "#7369F4",
              marginTop: 10,
            }}
          >
            {value}
          </Typography>
        )}
      </div>
    </Col>
  );
};

export const displayTitleValue = ({
  title,
  value,
  type = "",
  dotColor = "",
  valueColor = "#3D5A80",
  valueFontSize = "subtext1",
  mb = 0,
}) => {
  return (
    <Space
      style={{
        width: "100%",
        justifyContent: "space-between",
        marginBottom: mb,
      }}
    >
      <Space style={{ gap: 12 }}>
        {dotColor && (
          <div
            style={{
              width: 16,
              height: 16,
              backgroundColor: dotColor,
              borderRadius: 4,
            }}
          ></div>
        )}
        <Typography className={typoStyles.body3} style={{ fontWeight: 500 }}>
          {title}
        </Typography>
      </Space>
      {type === "duration" ? (
        displayFormattedDuration(value, valueColor, valueFontSize)
      ) : (
        <Typography
          className={typoStyles[valueFontSize]}
          style={{ color: valueColor }}
        >
          {value}
        </Typography>
      )}
    </Space>
  );
};
