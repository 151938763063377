import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  Space,
  Spin,
  TreeSelect,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useState } from "react";
import { importAssetsApi } from "../../services/assets.services";
import inputStyles from "../../assets/styles/Custom/Input.module.css";

const { Dragger } = Upload;

const ImportAsset = ({
  locations,
  showImportModal,
  setShowImportModal,
  reloadPage,
}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const props = {
    beforeUpload: (file) => {
      const fileType = file.type === "text/csv";
      if (!fileType) {
        message.error(`${file.name} is not a valid file`);
      }
      return fileType ? true : Upload.LIST_IGNORE;
    },
    onChange: async (info) => {
      if (info.file.status === "done") {
        setLoading(true);
        await importAssetsApi(selectedLocation, {
          file: info?.file?.originFileObj,
        })
          .then((res) => {
            setData({
              failedCases: res?.data?.failedCases || [],
              sucessMsg: res?.data?.message,
            });
            reloadPage();
          })
          .catch((error) => {
            setData({ errorMsg: error?.response?.data?.message });
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed`);
      }
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Modal
      title={
        <Space style={{ width: "94%", justifyContent: "space-between" }}>
          {"Import Assets"}
          <Button
            type="primary"
            ghost
            icon={<DownloadOutlined />}
            href={
              "https://cmms-prod-public-resorces.s3.ap-south-1.amazonaws.com/sample_asset_upload.csv"
            }
            download={"sample_assets_file.csv"}
          >
            Sample CSV File
          </Button>
        </Space>
      }
      open={showImportModal}
      width={600}
      footer={null}
      onCancel={() => {
        setShowImportModal(false);
      }}
      maskClosable={false}
    >
      {data ? (
        <>
          <Space direction="vertical">
            <Typography.Text
              type={data?.sucessMsg ? "success" : "danger"}
              style={{ fontSize: 16 }}
            >
              {data?.sucessMsg || data?.errorMsg}
            </Typography.Text>
            {data?.failedCases?.length > 0 && (
              <>
                <Typography style={{ fontWeight: 600, fontSize: 15 }}>
                  Failed rows :
                </Typography>
                <Space direction="vertical" style={{ gap: 4 }}>
                  {data?.failedCases?.map((f) => (
                    <Typography>{f}</Typography>
                  ))}
                </Space>
              </>
            )}
          </Space>
        </>
      ) : (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Typography>Select location</Typography>
          <TreeSelect
            placeholder="Location"
            style={{
              width: "520px",
            }}
            showSearch
            className={inputStyles.smSelect}
            onChange={(value) => {
              setSelectedLocation(value);
            }}
            treeData={Object.keys(locations)?.length > 0 && [locations]}
            disabled={loading}
          />
          {selectedLocation && (
            <Spin spinning={loading} tip="Uploading...">
              <Dragger
                style={{ width: "520px", marginTop: 16 }}
                {...props}
                accept=".csv"
                maxCount={1}
                showUploadList={false}
                customRequest={dummyRequest}
              >
                <p className="ant-upload-drag-icon">
                  <FileExcelOutlined />
                </p>
                <p className="ant-upload-text">
                  <span style={{ color: "#406AFF" }}>Click to Upload </span> or
                  drag and drop csv files
                </p>
                <p className="ant-upload-text">
                  Please include column names as
                  <br />
                  (name,code,status,make,model)
                </p>
                <br />
                <p className="ant-upload-text">
                  <span style={{ fontWeight: 600 }}>Note: </span> Enter{" "}
                  <span style={{ fontWeight: 600 }}>"status"</span> from given
                  options: (Online,Under Maintenance,Not Working,Retired)
                </p>
              </Dragger>
            </Spin>
          )}
        </Space>
      )}
    </Modal>
  );
};

export default ImportAsset;
