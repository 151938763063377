import React, { useContext, useEffect, useRef, useState } from "react";
import CustomLayout from "../Layout";
import {
  Button,
  Col,
  Empty,
  Flex,
  Image,
  Input,
  Pagination,
  Popconfirm,
  Result,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AddEditInventory from "./AddEditInventory";
import { getAllAssetsWithLocsApi } from "../../services/assets.services";
import {
  deleteInventoryApi,
  getInventoryList,
} from "../../services/inventory.services";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import {
  PAGESIZE,
  createInventory,
  deleteInventory,
  editInventory,
} from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserProvider";
import {
  debounce,
  deleteCond,
  displayFilterTag,
  editCond,
  formatNumber,
  scrollToTopTable,
  tableComponents,
} from "../../helpers/utility";
import sortIcon from "../../assets/images/sortIcon1.svg";
import filterActiveIcon from "../../assets/images/filterActiveIcon.svg";
import InventoryFilters from "./InventoryFilters";
import ImportInventory from "./ImportInventory";

const Inventory = () => {
  const tableRef = useRef(null);
  const { userDetails } = useContext(UserContext);
  const [locations, setLocations] = useState({});
  const [inventories, setInventories] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [currInventory, setCurrInventory] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedSorter, setSelectedSorter] = useState(undefined);
  const [filters, setFilters] = useState({
    types: [],
  });
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);

  function transformData(data) {
    function transformNode(node) {
      const transformedChildren = node.children.map(transformNode);
      return {
        value: node._id,
        title: node.name,
        disabled: node?.disabled,
        children: transformedChildren.length ? transformedChildren : undefined,
      };
    }
    let treeData = transformNode(data);
    return treeData;
  }

  const fetchLocations = async () => {
    try {
      const resp = await getAllAssetsWithLocsApi();
      let treeData = transformData(resp?.data?.locations?.[0]);

      setLocations(treeData);
    } catch (error) {
      setLocations({});
    }
  };

  const fetchInventoryList = async () => {
    let query = {
      page: pageNumber,
    };
    if (searchValue) {
      query = { ...query, name: searchValue };
    }
    filters?.types?.length > 0 && (query.type = filters?.types);
    if (selectedSorter) {
      query.sort_by = selectedSorter;
      query.sort_order = "desc";
    }

    setLoading(true);
    try {
      const res = await getInventoryList(query);
      setInventories(res?.data);
    } catch (error) {
      setInventories(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchInventoryList();
      scrollToTopTable(tableRef);
    }
    fetchData();
  }, [pageNumber, refresh]);

  useEffect(() => {
    if (pageNumber === 1) {
      setRefresh((prev) => !prev);
    } else {
      setPageNumber(1);
    }
  }, [filters, selectedSorter]);

  useEffect(() => {
    fetchLocations();
  }, []);

  const refreshPage = (source = "") => {
    if (source === "search" && pageNumber > 1) {
      setPageNumber(1);
    } else if (
      source === "delete" &&
      inventories?.inventories?.length === 1 &&
      pageNumber > 1
    ) {
      setPageNumber((prev) => prev - 1);
    } else {
      setRefresh((prev) => !prev);
    }
  };

  const deleteInventoryFunc = async (id) => {
    try {
      const resp = await deleteInventoryApi(id);
      if (resp) {
        message.success(resp?.data?.message);
        refreshPage("delete");
      }
    } catch (error) {}
  };

  const handleSearch = (e) => {
    setSearchValue(e?.target?.value);
    if (
      e?.target?.value?.trim()?.length === 0 ||
      e?.target?.value?.trim()?.length > 1
    ) {
      refreshPage("search");
    }
  };

  const tableColumns = [
    {
      title: "Spare part code",
      dataIndex: "code",
      key: "code",
      fixed: "left",
      width: 250,
      render: (val, record) => (
        <Space>
          {val}
          {(Number(record?.available_quantity) <= 0 ||
            Number(record?.available_quantity) <
              Number(record?.low_stock_threshold)) && (
            <Tag className={tagStyles.smTag} color="red">
              Low Stock
            </Tag>
          )}
        </Space>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 250,
      render: (val) => val?.name || "-",
    },
    {
      title: "Spare part name",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 140,
      render: (val) => (val ? val : "-"),
    },
    {
      title: "Quantity available",
      dataIndex: "available_quantity",
      key: "available_quantity",
      width: 180,
    },
    {
      title: "Low stock threshold",
      dataIndex: "low_stock_threshold",
      key: "low_stock_threshold",
      width: 180,
    },
    {
      title: "Cost per piece",
      dataIndex: "cost",
      key: "cost",
      width: 180,
      render: (val) =>
        val || val === 0
          ? `₹ ${formatNumber(Number(val?.toFixed(2)) || 0)}`
          : "-",
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      width: 140,
      render: (_, record) => {
        return (
          <Flex gap={12} align="center" justify="start">
            {deleteCond(
              deleteInventory,
              record?.creator,
              userDetails?.activeOrg?.role,
              userDetails?._id
            ) && (
              <Popconfirm
                icon={null}
                description="Are you sure to delete this inventory?"
                onConfirm={(e) => {
                  e.stopPropagation();
                  deleteInventoryFunc(record?._id);
                }}
                onCancel={(e) => e.stopPropagation()}
              >
                <DeleteOutlined />
              </Popconfirm>
            )}
            {editCond(
              editInventory,
              record?.creator,
              userDetails?.activeOrg?.role,
              userDetails?._id
            ) && (
              <EditOutlined
                onClick={() => {
                  setCurrInventory(record);
                  setShowDrawer(true);
                }}
              />
            )}
          </Flex>
        );
      },
    },
  ];

  const sortOptions = [
    {
      label: "Quantity",
      value: "available_quantity",
    },
    {
      label: "Cost",
      value: "cost",
    },
  ];

  return (
    <CustomLayout
      header={
        <Flex justify="space-between">
          <Typography className={typoStyles.h6}>
            Inventory ({inventories?.totalInventories || 0})
          </Typography>
        </Flex>
      }
    >
      <>
        <Row gutter={[24, 18]}>
          <Col span={10}>
            <Space
              style={{ width: "100%", justifyContent: "flex-start", gap: 12 }}
            >
              <Button
                style={{ color: "#6941C6" }}
                className={btnStyles.smBtn}
                type="default"
                icon={
                  <Image
                    src={filterActiveIcon}
                    width={18}
                    height={18}
                    alt="filter"
                    preview={false}
                  />
                }
                onClick={() => setShowFilterModal(true)}
              >
                Filter
              </Button>
              <div style={{ position: "relative" }}>
                <Select
                  style={{
                    width: 220,
                  }}
                  className="custom-select"
                  placeholder="Sort by"
                  allowClear
                  options={sortOptions}
                  onChange={(e) => setSelectedSorter(e)}
                />
                <div
                  style={{
                    position: "absolute",
                    left: 8,
                    top: 6,
                  }}
                >
                  <Image
                    src={sortIcon}
                    width={18}
                    height={18}
                    alt="sorter"
                    preview={false}
                  />
                </div>
              </div>
            </Space>
            {showFilterModal && (
              <InventoryFilters
                showFilterModal={showFilterModal}
                setShowFilterModal={setShowFilterModal}
                filters={filters}
                setFilters={setFilters}
              />
            )}
          </Col>
          <Col span={4} offset={10} style={{ textAlign: "right" }}>
            {["admin", "manager"].includes(userDetails?.activeOrg?.role) && (
              <>
                <Button
                  type="primary"
                  className={btnStyles.smBtn}
                  onClick={() => setShowImportModal(true)}
                >
                  + Import Inventory
                </Button>
                {showImportModal && (
                  <ImportInventory
                    locations={locations}
                    showImportModal={showImportModal}
                    setShowImportModal={setShowImportModal}
                    reloadPage={() => {
                      setRefresh((prev) => !prev);
                      setFilters({
                        types: [],
                      });
                      setSearchValue("");
                    }}
                  />
                )}
              </>
            )}
          </Col>
          {filters?.types?.length > 0 && (
            <Col
              span={24}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 6,
                flexWrap: "wrap",
              }}
            >
              {filters?.types?.length > 0 &&
                displayFilterTag(
                  "Inventory Type",
                  "volcano",
                  filters?.types?.length
                )}
              <Typography
                style={{ color: "#7369F4", cursor: "pointer", fontSize: 12 }}
                onClick={() =>
                  setFilters({
                    types: [],
                  })
                }
              >
                Clear All
              </Typography>
            </Col>
          )}
          <Col span={24}>
            <Table
              ref={tableRef}
              title={() => (
                <Space
                  align="center"
                  style={{ width: "100%", justifyContent: "flex-end", gap: 16 }}
                >
                  <Input.Search
                    size="large"
                    onChange={debounce(handleSearch, 500)}
                    placeholder="Search by inventory name or code"
                    prefix={
                      <SearchOutlined
                        style={{ fontSize: 16, color: "#667085" }}
                      />
                    }
                    style={{ width: "320px" }}
                    className="custom-input-search"
                  />
                  {createInventory.includes(userDetails?.activeOrg?.role) && (
                    <Button
                      id="create-mro"
                      type="primary"
                      className={btnStyles.mdBtn}
                      onClick={() => {
                        setCurrInventory(null);
                        setShowDrawer(true);
                      }}
                    >
                      + Add Inventory
                    </Button>
                  )}
                </Space>
              )}
              scroll={{
                x: 1200,
                y: "55vh",
              }}
              locale={{
                emptyText: loading ? (
                  <Skeleton active />
                ) : (
                  <Result
                    icon={Empty.PRESENTED_IMAGE_SIMPLE}
                    title={<Typography>No inventory found</Typography>}
                  />
                ),
              }}
              pagination={false}
              bordered
              columns={tableColumns}
              dataSource={inventories?.inventories || []}
              loading={
                loading
                  ? {
                      spinning: false,
                      indicator: null,
                    }
                  : false
              }
              components={tableComponents}
            />
          </Col>
          {inventories?.totalInventories > PAGESIZE && (
            <Col span={24} className="text-center">
              <Pagination
                current={pageNumber}
                pageSize={PAGESIZE}
                total={inventories?.totalInventories}
                onChange={(e) => setPageNumber(e)}
                showSizeChanger={false}
              />
            </Col>
          )}
        </Row>
        {showDrawer && (
          <AddEditInventory
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            locations={locations}
            currInventory={currInventory}
            refreshPage={refreshPage}
          />
        )}
      </>
    </CustomLayout>
  );
};

export default Inventory;
