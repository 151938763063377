import {
  CheckOutlined,
  Loading3QuartersOutlined,
  LockOutlined,
  PauseOutlined,
} from "@ant-design/icons";
import { deleteDocsApi, uploadApi } from "../services/assets.services";
import dayjs from "dayjs";
import { ACCESS_TOKEN, ONPREMISE, USER } from "../constants/defaultKeys";
import { getCookie, getItem } from "./localStorage";
import { Space, Tag, Typography, message } from "antd";
import { delteS3FileApi, getS3urlApi } from "../services/upload.services";
import axios from "axios";
import tagStyles from "../assets/styles/Custom/Tag.module.css";

const utc = require("dayjs/plugin/utc"); // Plugin for UTC handling
const timezone = require("dayjs/plugin/timezone"); // Plugin for timezone handling

dayjs.extend(utc);
dayjs.extend(timezone);

export const isAuthenticated = () => {
  let isTokenAvailable = false;
  const access_token = getCookie(ACCESS_TOKEN);
  if (access_token) {
    isTokenAvailable = true;
  }
  return isTokenAvailable;
};


export const scrollToTopTable =(tableRef)=>{
  try {
    if (tableRef.current) {
      const nestedLoading = tableRef.current?.children[0];
      const tableBody = nestedLoading.querySelector(".ant-table-body");
      if (tableBody) {
        tableBody.scrollTop = 0;
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export const getTokenIfNotExpired = () => {
  const token = getCookie(ACCESS_TOKEN);
  const user = getItem(USER, true);
  if (token && user) {
    const date = new Date();
    const expiredTime = user.exp;
    if (expiredTime < date.getTime() / 1000) {
      clear();
      return null;
    }
  }
  return token;
};

export function convertToFormData(info) {
  const formData = new FormData();
  // for (var key in res.data.url.fields) {
  //   formData.append(key, res.data.url.fields[key]);
  // }
  formData.append("file", info.file.originFileObj);
  return formData;
}

export const uploadFunc = async (info, storageType, query = {}) => {
  if (storageType == "on-premise") {
    return await uploadtoOnpremises(info, query);
  } else {
    return await getandUploadtoS3Url(info, true);
  }
};

export const uploadtoOnpremises = async (info, query) => {
  const formData = new FormData();

  formData.append(
    "file",
    info?.file?.originFileObj ? info?.file?.originFileObj : info?.file
  );
  try {
    const resp = await uploadApi(formData, query);
    return resp?.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getandUploadtoS3Url = async (info, needFormData = false) => {
  let testName = `${info.file.uid}-${info.file.name}`;
  testName = testName.replace(/\s+/g, "");

  // Step 2: Retain only numbers, alphabets, hyphens, and underscores
  testName = testName.replace(/[^a-zA-Z0-9-_.]/g, "");

  try {
    const resp = await getS3urlApi(testName);
    return await uploadToS3(resp, info, needFormData);
  } catch (error) {
    console.log(error);
    message.error(error?.response?.data?.message || "Something went wrong!!");
    return null;
  }
};

export const uploadToS3 = async (res, info, needFormData) => {
  try {
    const finalRes = await axios({
      method: "PUT",
      url: res?.data?.signed_url,
      data: info?.file?.originFileObj ? info?.file?.originFileObj : info?.file,
      headers: {
        "Content-Type": info.file.type,
      },
    });
    if (finalRes) {
      return {
        path: res?.data?.pre_signed_url,
      };
    } else {
      return null;
    }
  } catch (error) {
    message.error(error?.response?.data?.message || "Something went wrong!!");
    return null;
  }
};
export const uploadVideo = async (file) => {
  const formData = new FormData();
  formData.append("file", file);
  try {
    const resp = await uploadApi(formData);
    return resp?.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const deleteDocs = async (path, storageType) => {
  const api = storageType == ONPREMISE ? deleteDocsApi : delteS3FileApi;
  const key = storageType == ONPREMISE ? path : renameFile(path);

  try {
    const resp = await api(key);
    message.success(resp?.data?.message);
  } catch (error) {
    console.log(error);
    message.error(error?.response?.data?.message || "Something went wrong!!");
  }
};

export const getEncodedImage = (name) => {
  if (name) {
    const [, afterCmms] = name?.split("cmms/");
    const encodedImage = encodeURIComponent(afterCmms);
    return encodedImage;
  } else {
    return "";
  }
};

export const getFullPathFromEncoded = (encodedName) => {
  if (encodedName) {
    const decodedImage = decodeURIComponent(encodedName);
    return `cmms/${decodedImage}`;
  } else {
    return "";
  }
};

export const getAssetsPath = (path, storageType) => {
  return storageType == ONPREMISE
    ? `${process.env.REACT_APP_STORAGE_BASE_PATH}/docs/${path}`
    : path;
};

export const renameFile = (url) => {
  const startIndex = url.indexOf("rc-upload");
  if (startIndex === -1) {
    // Start substring 'rc-upload' not found.
    return "";
  }

  const endIndex = url.indexOf("X-Amz", startIndex);
  if (endIndex === -1) {
    // "End substring 'X-Amz' not found.
    return "";
  }

  const result = url.substring(startIndex, endIndex);

  // Remove trailing characters such as '?' if present at the end
  return result.replace(/[\?&]*$/, "");
};

export function timeAgo(utcTimestamp) {
  const currentDate = new Date();
  const pastDate = new Date(utcTimestamp);

  const timeDifference = currentDate.getTime() - pastDate.getTime();
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return days === 1 ? `${days} day ago` : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? `${hours} hour ago` : `${hours} hours ago`;
  } else if (minutes > 0) {
    return minutes === 1 ? `${minutes} minute ago` : `${minutes} minutes ago`;
  } else {
    return "Just now";
  }
}

export function extractDomainFromUrl(url) {
  // Use a regular expression to match and extract the domain
  const match = url.match(
    /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/\n]+)/i
  );

  // Check if there was a match and return the domain
  if (match) {
    return match[1];
  } else {
    return null; // Return null if the URL is invalid or doesn't contain a domain
  }
}

export const statusOptions = [
  {
    value: "Online",
    label: "Online",
    color: "green",
    text: "Online",
  },
  {
    value: "Under Maintenance",
    label: "Under Maintenance",
    color: "orange",
    text: "Under Maintenance",
  },
  {
    value: "Not Working",
    label: "Not Working",
    color: "blue",
    text: "Not Working",
  },
  {
    value: "Retired",
    label: "Retired",
    color: "blue",
    text: "Retired",
  },
];
export const workOrderstatusList = [
  {
    label: "Open",
    text: "Open",
    color: "#108ee9",
    value: "open",
    icon: <LockOutlined style={{ fontSize: "24px" }} />,
  },
  {
    value: "pending",
    label: "Pending",
    text: "Pending",
    color: "#f08080",
    icon: <CheckOutlined style={{ fontSize: "24px" }} />,
  },
  {
    value: "in-progress",
    label: "In Progress",
    text: "In Progress",
    color: "#FFBE0B",
    icon: <Loading3QuartersOutlined style={{ fontSize: "24px" }} />,
  },
  {
    value: "on-hold",
    label: "On Hold",
    text: "On Hold",
    color: "#f50",
    icon: <PauseOutlined style={{ fontSize: "24px" }} />,
  },
  {
    value: "closed",
    label: "Closed",
    text: "Closed",
    color: "#87d068",
    icon: <CheckOutlined style={{ fontSize: "24px" }} />,
  },
];
export const capitalizeFirstLetter = (word) => {
  if (typeof word !== "string" || word.length === 0) {
    return word; // Return the original word if it's not a string or is empty
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const priorityOptions = [
  {
    value: "low",
    label: "Low",
    text: "Low",
    color: "green",
  },
  {
    value: "moderate",
    label: "Moderate",
    text: "Moderate",
    color: "processing",
  },
  {
    value: "high",
    label: "High",
    text: "High",
    color: "error",
  },
];
export const weekDays = [
  { value: "Monday", label: "Monday" },
  { value: "Tuesday", label: "Tuesday" },
  { value: "Wednesday", label: "Wednesday" },
  { value: "Thursday", label: "Thursday" },
  { value: "Friday", label: "Friday" },
  { value: "Saturday", label: "Saturday" },
  { value: "Sunday", label: "Sunday" },
];

export const monthOptions = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

export const weekDaysOptions = [
  { value: "first", label: "First" },
  { value: "second", label: "Second" },
  { value: "third", label: "Third" },
  { value: "fourth", label: "Fourth" },
  { value: "last", label: "Last" },
];
export const repeatOptions = [
  { value: "daily", label: "Day" },
  { value: "weekly", label: "Week" },
  { value: "monthly", label: "Month" },
  { value: "yearly", label: "Year" },
];

export const shortDaysptions = [
  {
    value: "Sunday",
    label: "S",
  },
  {
    value: "Monday",
    label: "M",
  },
  {
    value: "Tuesday",
    label: "T",
  },
  {
    value: "Thursday",
    label: "T",
  },
  {
    value: "Friday",
    label: "F",
  },
  {
    value: "Saturday",
    label: "S",
  },
];

export function convertUtcToUserTimezone(utcTimestamp, isTimeRequired = true) {
  if (!utcTimestamp) {
    return null;
  }

  const utcDate = dayjs.utc(utcTimestamp);
  let userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  userTimezone = "Etc/Unknown" ? "Asia/Kolkata" : userTimezone;
  const userDate = utcDate.tz(userTimezone); // Convert to user's timezone

  const formatString = isTimeRequired ? "MMM D, YYYY hh:mm A" : "MMM D, YYYY";
  const userDateString = userDate.format(formatString);

  return userDateString;
}
export const options = [
  {
    label: "View all",
    value: "View all",
  },
  {
    label: "Assigned to me",
    value: "Assigned to me",
  },
  {
    label: "Created by me",
    value: "Created by me",
  },
];

export const userRoles = [
  {
    value: "admin",
    label: "Admin",
    color: "blue",
  },
  {
    value: "manager",
    label: "Manager",
    color: "orange",
  },
  {
    value: "technician",
    label: "Technician",
    color: "purple",
  },
  {
    value: "supervisor",
    label: "Supervisor",
    color: "green",
  },
  // {
  //   value: "external_service_providers",
  //   label: "External service providers",
  //   color: "volcano",
  // },
  {
    value: "operator",
    label: "Operator",
    color: "volcano",
  },
];
export const colors = [
  "#2c699a",
  "#048ba8",
  "#0db39e",
  "#83e377",
  "#54478c",
  "#d81159ff",
  "#8f2d56ff",
  "#cc4400",
  "#000077",
  "#b9e769",
  "#efea5a",
  "#f1c453",
  "#f29e4c",
  "#d66915",
  "#e08e29",
  "#f0c761",
  "#7cc6de",
  "#3890bc",
  "#be1e2dff",
  "#1c489a",
  "#006ba6ff",
  "#471ca8ff",
  "#884ab2ff",
  "#72bad5ff",
  "#03324eff",
  "#ef4043ff",
  "#16db93",
  "#c43240ff",
  "#52ace4",
];
export function debounce(func, delay) {
  let debounceTimer;
  return function (...args) {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(this, args), delay);
  };
}
export const getAvatarBackgroundColor = (name) => {
  if (!name) return colors[0]; // Default color if name is empty
  const charIndex = name.charCodeAt(0) % colors.length;
  return colors[charIndex];
};
export const editCond = (type, id, userRole, userId) => {
  return (
    type?.includes(userRole) &&
    ((userRole == "supervisor" && id == userId) || userRole !== "supervisor")
  );
};

export const deleteCond = (type, id, userRole, userId) => {
  return (
    type?.includes(userRole) &&
    ((userRole != "admin" && id == userId) || userRole == "admin")
  );
};

export function generateRandomString(strLength = 6) {
  let result = "";
  let length = strLength;
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function convertUtcToIst(utcTimestamp, converttoUtc = true) {
  const utcDate = new Date(utcTimestamp);

  // Convert to Indian Standard Time (IST)
  const istDate = new Date(
    utcDate.getTime() + (converttoUtc ? 5.5 * 60 * 60 * 1000 : 0)
  ); // Adding 5 hours and 30 minutes

  // Format the date in a human-readable format
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[istDate.getMonth()];
  const day = istDate.getDate();
  const year = istDate.getFullYear();
  console.log(istDate.getHours(), istDate.getMinutes(), "====00");

  // Concatenate the formatted date
  const formattedDate = `${day} ${month} ${year}`;

  return formattedDate;
}

export function capitalizeWords(sentence) {
  // Split the sentence into words
  const words = sentence.split(" ");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the words back into a sentence
  const capitalizedSentence = capitalizedWords.join(" ");

  return capitalizedSentence;
}
export function reverseCapitalizeWords(option) {
  return option.toLowerCase().replace(/\s+/g, "_");
}

export function lightenColor(color, percent) {
  // Ensure color is a valid hexadecimal color code
  if (!/^#[0-9A-F]{6}$/i.test(color)) {
    throw new Error(
      'Invalid color format. Please provide a color in the format "#RRGGBB".'
    );
  }

  // Parse hexadecimal color string to RGB
  const r = parseInt(color.substr(1, 2), 16);
  const g = parseInt(color.substr(3, 2), 16);
  const b = parseInt(color.substr(5, 2), 16);

  // Calculate new RGB values based on percent
  const newR = Math.min(255, r + (255 - r) * (percent / 100));
  const newG = Math.min(255, g + (255 - g) * (percent / 100));
  const newB = Math.min(255, b + (255 - b) * (percent / 100));

  // Convert RGB back to hexadecimal format
  return `#${Math.round(newR).toString(16).padStart(2, "0")}${Math.round(newG)
    .toString(16)
    .padStart(2, "0")}${Math.round(newB).toString(16).padStart(2, "0")}`;
}
export function minutesToHoursAndMinutes(minutes) {
  var hours = Math.floor(minutes / 60);
  var remainingMinutes = Math.round(minutes % 60);
  return { hours: hours || 0, remainingMinutes: remainingMinutes || 0 };
}

export function convertToUTCFormat(localTimeStr) {
  // Create a new Date object with the provided local time string
  const localTime = new Date(localTimeStr);

  // Convert to UTC and return in ISO format
  return localTime.toUTCString();
}

export function convertUTCToLocalFormat(utcTimeStr) {
  const localTime = dayjs.utc(utcTimeStr).local();

  // Format the result in YYYY-MM-DDTHH:mm:ss.SSSZ format
  return localTime.format("YYYY-MM-DD HH:mm:ss");
}

export function formatHours(value) {
  let days = Math.floor(value / 24);
  let hours = value - days * 24;
  let minutes = Math.round((hours - Math.floor(hours)) * 60);

  return { days, hours: Math.floor(hours), minutes };
}

export const displayFilterTag = (title, tagColor, count = 1) => {
  return (
    <Tag
      color={tagColor}
      className={tagStyles.mdRndTag}
      style={{ borderRadius: 8, border: 0, fontSize: 12 }}
    >
      {`${title}${count > 1 ? ` (${count})` : ""}`}
    </Tag>
  );
};

export const displayOoeFormula = () => {
  let textStyles = {
    fontSize: "12px",
    color: "#3D5A80",
  };
  return (
    <Space
      direction="vertical"
      style={{ width: "100%", gap: 6, textAlign: "left" }}
    >
      <Typography style={textStyles}>
        OEE = Availability x Performance x Quality
      </Typography>
      <Typography style={textStyles}>
        Availability = Runtime / Planned Production Time
      </Typography>
      <Typography style={textStyles}>
        Performance = Actual Production Output / Ideal Production Output
      </Typography>
      <Typography style={textStyles}>
        Quality = Good Units / Total Units Produced
      </Typography>
    </Space>
  );
};

export const tableComponents = {
  header: {
    cell: (props) => (
      <th {...props} className={props.className}>
        <Typography className="tableHeader">
          {props?.children || props.title}
        </Typography>
      </th>
    ),
  },
};

export const formatNumber = (num) => {
  if (typeof num == "number") {
    const number = num.toLocaleString("hi");
    return number == "NaN" ? "-" : number;
  } else console.error("In proper data type to format number");
};

export const maintanceStatuses = [
  { label: "Open", value: "open", color: "#108ee9" },
  { label: "Pending", value: "pending", color: "#f08080" },
  { label: "In progress", value: "in-progress", color: "#FFBE0B" },
  { label: "On hold", value: "on-hold", color: "#f50" },
  { label: "Closed", value: "closed", color: "#87d068" },
];
export const PUBLIC_KEY = `
-----BEGIN PUBLIC KEY-----
MIICITANBgkqhkiG9w0BAQEFAAOCAg4AMIICCQKCAgB2VpwYatcclHAe3nKTdaqL
sutZjkK0bhNgu3WxDAYX3Os+SJq0KRwdHcmbAwW0QRtB76jGuAZe0YlmINKD1UTb
VLowJVKGWfV2haIdt2wgKUX3DyCPLaxKUx94HyEU96eU/7jlfwx1sIlu/CF022Dr
FOenFzeVFGnt+c+jkHKGYTQoM3bZxqc+XOHdXZCjZ8tk5jLHiLD1kKqai+KSm2av
wM16X6dEGHEwlOd6TxHPlqCTU/3j+Y9fLQakYXaOMP9Py5julfBd3FWoM6zoS3qH
+PRxf9bpteanp8JUkmj5++/nkDViBgJ4cVWl3KyUsdleeP/IXLkkhA7Kbsu5TS3N
qmfkOUoBIbG1nxHBatEkdzoUHDWITc2QQy9j025VmsVEqNZhmRknC4r9kmXwTteq
EOAz7PmtM3QmpdwmW7XXjl25uSKOVz1kh//rTxCBoZoGqHmkBPoJSf0xfJyqskvB
Ci3p4Dgf0GtVbAvw+fGNrvB3xC6PW5x+WUNaqxpXCm9rS/knAlVVkAm2kNtCt/CS
KfJBazp9bm31q+oME4cRUXRXlanIAtB9ziYsdSvpumnOgkhyCEVgzTcA3rFiB+Vh
6rWG20LY8VoVA5ZHhn/y7ovIIwOaAmmG/2shJePp5HCXoMyO/0bLFEt8CWNZx/TQ
LfeP3iWuWh6A9iFHLCYkwQIDAQAB
-----END PUBLIC KEY-----
`;

export const PRIVATE_KEY = `
-----BEGIN RSA PRIVATE KEY-----
MIIJJgIBAAKCAgB4I2h+dR42WgrFqyunuMH09o0RDBBfU+N7O7uvf2C71r7Nntso
HBpIt5DmfEEuj4rclWzuWUjhfATYlLUWrCDVtoyRQVQaZ14wB74yI1gkd/oaPeCo
bvLNx2M7M+wkkRoWSP9xhicP/U7GyGqfS3dAkED2MvwYiAH+ULeK7b5DngTI7ac0
PWr6tYWthJc5imi1svbTJNES8CGxnGF+dq/9XpPKxFY1yzNJ1hVsqh7HzltD16l4
OhwE6wovAN83KwuuJyR24gx7RosthBE3dwLGbj6mNTdZWWfrSz9C278hrF9ig1DD
WsVE38rrg4vJNhmUzniDkrmLjKkAurkqwrGl+y7rv+Oig7ca6GzJOtrg0Jn4R44e
ebPtB4XzqoFTdU3x7rd3cVkp7Yyjb+Bzfo8AOddOJv8LYAR5q5ou98d4dt1dqVy8
omLssuUDY8/AB3Xo7u5+oLnvRaR6OoGnyk0eNPdOwwleKjHU7DL0voRpRlbAWTFr
7LE+jBG5yQhTevumFnt737C+OPGaj7QKKEC1Ga2aCDhjzzg9M0Vs8EdWynAPwzm0
NcgjayPbasRft/NSJlLJW6swldE5C2PMC4efWq5UcHnO7py1kXhbcJqS3pqKgJwQ
anAH612JaQboikaFQl2tIvtBX72LHaJSoSYCpngMSMaQ0t7lnT26Q26tmwIDAQAB
AoICADovC+CS4Dr6iNUK/W2RcX4uGkvzR8qjWjzIxTFNw4+1hVsYYJOgaKpnHOpD
q13dbyc9xy/4Ve++nKo3+ywr2QsoChQfS5guD9O7LEYkEAA5iwtChJeOhFFdC5YU
o31iOPwqBmz4x9pal9h2IA1eAzdlpdCXCLjoxe5hMUM7uTYORcr6LkyColkhqMhM
uuDHQ8ccEaZY4lkFq4vVbPJOkRVoxLMjO416CDDuWhnymk2yh0joHc94dTF8k8YH
6hNR6apCkspw2BKsK6OznpL6NIHdysG8bD39qvKt0smSm5K3ZMrVRjwWrguZRzGg
2AZvCRykkoFGgk4vpXoQ9LTc7iP/7QYEkJCyzBJ77OeJGQr8PZVTPxhtNhgvV82r
aTFcMpUGr1llXHKUOLu1OdCdSwI33vU72HS559FjstS2/KyC+Lo2wR5AnroqEehS
Npnq6tNXyBUGs09UipFw3NgBIWDLCCeaxxotYLd+eYe8QrRzAkj7uHKdlB1YSXGV
XxM+kDnvkbi6Nr8ZxpZ/GUqHJwyB4Lv2K/8De+2U/8Tv2t4AWWAbfG+lgsWmRM0c
cFLMFNoSoRf1KMg8/6IwPuznZOiVXsqqMbucmQ/7axGT+h9RLSh+xxmC3vXma5R3
DWcGwWXuDB3bleLzVAaFM94DHBACO26jTIVo4GfI9odGI+RBAoIBAQDqGxQAWXSO
azNvfm2EZYhV4kAF/HqOxUrAd2NU5vgrF+3Fw8xvrerZUMu6loZPzcv5Q6OL70EW
tsYLKD7Xx9CEZROL1DW7xC1Dv+3G/utPLIsGi7ezgdWCDteesFePxK0Q4U1Cgd/s
pwUMSfl5pkK/9RToKMIo4GaWplElkgMqmQx2hSdciXeqwBw25W5R7L9eqyPVxsQk
BhEWXkZJunEDuv98lNpL7DaDjjbwJfBRzJ1R4GmTJjRvFElWRwEbgsoMmtc7amtO
flIALDQAS4oqQL8pCxaWJBnuyacEOGHDfaXyDyR7Kuuas257x9siPVjmvTJM9LIl
A6inNw0sKyM5AoIBAQCDX71o1KFdbrRmxb6cvaT2Z/SD3TutIT3uRYfCTaGLq9wl
C4qTzS/ICUGToIkUl9kWqKkAk7SWTw/3AKmNlPgfFBLjA+Oj9WnmnGcbEO9Kp2SI
YN34u+hGbKVzy04+psC6c70EpzL4Dvug7Rfe/x3dZxb8v+SowhOC5hYnYG9ehS5a
3Ai6pgXdm+ySr+HPaUFxN1MSiXSzJBjxFH70f0zcKqfFn5E2EzJ8U1EKAzIpiJKB
1IbmKZM4c3Mk9M+ISBFTkNPCNCdGx4jb1KWn5hdK6g6b7ts6mJQHV16dL5Yhyu57
Oy1ja49ZCL3u2AVs00fIWS/gLY2X1sfWpgyU1bNzAoIBAEiq5ReCrQp0PkASl34s
Yhh+pW72QzjEBwMdTAkTtN7zi4wCzhewfaC9Uq/1f3WQJQuXslGCj8q0K19VO8SR
MqmWEm4qDg/dwkswbWeCw5oM8o6ymfAvkg3P+6A3jsaCv1HpDaliVRbJdWiuIbFg
KYIaWkYCONqlZVLIB5JI85Cqd1Lm3CP+S9YF0fw8VXU6ruB88R+vljy0YWN3GgxS
wjftONb8AZLlnLpTT7xVmnZZtE+xADR7/HSqcGVGnxJiE8O74KmBzBNOZLfHANFC
oX2iIXRlC7rJM/+KOry1n6sl+nfbuXA+D2oy3k2v6NaFAm6FNfLrVczliNl23jDi
pwkCggEAIDcvFHDn7SiDlpwxRPCAbmhJnrh2tNrs++wTAYPV9JE9V9w/rETpzazv
Zss8Mk+WTacoYlrWMvHKYXDJI03/lmduxjqi7Qb2ZDXAVvPNKkpoLcVLSX6S1Ao6
fG3DjzuHEXHmTSjPJ+NMs0bQzr9SzINGMM7BvNxyumu/zQIp7UzNdDx264Zg89oT
plj5D8qo4KGHfok0k+VrNFspUr2Q1IClSaH6kv8cJz6rJcBo4aR7ANlkBzb0wigV
eV+LMMkzboGaXAo8A3BU0EuSH90doyJIWNa27eSSkEZNjlAoCbtgxdlaJQIwUc4B
/Xr9wZUCClBMnDWvfieOM8XeWfUZnwKCAQBSgk/zDsSTKJ83n+BvGmyvj1VtcKSI
tC8jXDWvILlU62FxDWFdpt5dHdlAT8WsbH0XA5gYJBs2pl+UCaFbaD6OfQVo+JkC
8QZXtV8w/qKOVNxCPWFXQSybbKKDb4K1Do86nVpxoi7hHruHvOvYlBZabffna0T7
DSzRQ4xNaFo+dHilYnNSQ3YLaN/hi0vaGz6KtPfdfQfGvtp4cQNQkKwkiLxg8/OF
yvirnmCuYKk79RPCa3C8sejL6fC5kzZVTV42BhRAk4s0ur8jXvtFmUlGFWhU0Plx
WsxNykv83YrEAaFhpl9NySi0Vlnq193a+3nEJBzFQBgCeYKM7Qnw97pA
-----END RSA PRIVATE KEY-----
`;
