import axiosInstance from "../config/axios/axios.config";

export const getMWStatsApi = (query) => {
  return axiosInstance.get(`/analytics?${query}`);
};

export const getEmployeeMetrics = () => {
  return axiosInstance.get(`/analytics/employee`);
};

export const getMroChartDataApi = (query) => {
  return axiosInstance.get(`/analytics/mro-chart-data`, { params: query });
};
