import { Col, Row, Typography, Button, Switch } from "antd";
import {
  CloseOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useEffect, useRef, useState, useContext } from "react";
import typographyStyles from "../../../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../../../assets/styles/Custom/Button.module.css";
import { debounce } from "../../../../helpers/utility";
import { UserContext } from "../../../../context/UserProvider";
import CustomSearch from "../../../Blocks/CustomSearch";
import RandomAvatar from "../../../Blocks/RandomAvatar";
import { getUsersandGroupsApi } from "../../../../services/users.services";
function ShareTyut({
  placeholder,
  setPreview = null,
  editBtn = false,
  publishHandler = null,
  shareType = "Tyut",
  sharedWith,
  anyOneCanFromOrgCanView = true,
  creator_id = null,
  courseId = "",
}) {
  const userData = useContext(UserContext);
  let loggedInUserRole = userData?.userDetails?.activeOrg?.role;
  let loggedInUserId = userData?.userDetails?._id;
  const [userNdTeams, setUsersNdTeams] = useState({ groups: [], users: [] });
  const [selectedUserNTeams, setSelectedUsersNTeams] = useState({
    groups: [],
    users: [],
  });
  const [selectedIds, setSelectedIds] = useState({});
  const [anyOneCanView, setAnyoneCanView] = useState(anyOneCanFromOrgCanView);
  const [removedUsersNTeams, setRemovedUsersNTeams] = useState({
    groups: [],
    users: [],
  });

  useEffect(() => {
    if (sharedWith) {
      setSelectedUsersNTeams(sharedWith);
      setAnyoneCanView(anyOneCanFromOrgCanView);
      let data = {};
      sharedWith.groups.map((each) => (data[each.id] = each.id));
      sharedWith.users.map((each) => (data[each.id] = each.id));
      setSelectedIds(data);
    }
  }, [sharedWith]);

  const userScrollRef = useRef(null);
  const teamScrollRef = useRef(null);

  useEffect(() => {
    if (userScrollRef.current) {
      userScrollRef.current.scrollTop = userScrollRef.current.scrollHeight;
    }
    if (teamScrollRef.current) {
      teamScrollRef.current.scrollTop = teamScrollRef.current.scrollHeight;
    }
  }, [selectedUserNTeams]);

  async function getUserOrTeam(name, endLoading) {
    let params = {};
    if (name) {
      params.name = name;
    }
    try {
      const data = await getUsersandGroupsApi(params);
      if (data?.data) {
        let groups = data?.data.groups;
        let users = data?.data.users;
        groups = groups.filter(
          (eachTeam) => !selectedIds?.[eachTeam?._id] && eachTeam
        );
        users = users.filter(
          (eachUser) =>
            !selectedIds?.[eachUser?._id] &&
            eachUser?._id != creator_id &&
            eachUser
        );
        setUsersNdTeams({ users, groups });
      }
    } catch (err) {
    } finally {
      endLoading();
    }
  }
  let options = [];
  userNdTeams.users.length > 0 &&
    options.push({
      label: <Typography className={typographyStyles.body3}>Users</Typography>,
      options: userNdTeams?.users.map((user) => ({
        value: (user?.first_name || "") + " " + (user?.last_name || ""),
        id: user._id,
        user: true,
        username: (user?.first_name || "") + " " + (user?.last_name || ""),
        type: "view",
      })),
    });
  userNdTeams.groups.length > 0 &&
    options.push({
      label: <Typography className={typographyStyles.body3}>Groups</Typography>,
      options: userNdTeams?.groups.map((team) => ({
        value: team?.name,
        id: team._id,
        user: false,
        type: "view",
      })),
    });

  return (
    <>
      <div
        style={{
          minHeight: "40vh",
          display: "relative",
        }}
      >
        <Col span={24}>
          <Typography
            className={typographyStyles.subtext2}
            style={{ marginBottom: "12px" }}
          >
            Share your {shareType}
          </Typography>
          <CustomSearch
            placeholder={placeholder}
            options={options}
            onSelect={(val, option) => {
              if (option.user) {
                const isUserSelected = selectedUserNTeams.users.some(
                  (eachUser) => eachUser.id === option.id
                );
                if (!isUserSelected) {
                  setSelectedUsersNTeams({
                    ...selectedUserNTeams,
                    users: [...selectedUserNTeams.users, option],
                  });
                  setSelectedIds((prev) => ({
                    ...prev,
                    [option.id]: option.id,
                  }));
                  let users = userNdTeams.users.filter(
                    (eachUser) => eachUser._id !== option.id
                  );
                  setUsersNdTeams((prev) => ({ users, groups: prev.groups }));
                }
              } else {
                const isTeamSelected = selectedUserNTeams.groups.some(
                  (eachTeam) => eachTeam.id === option.id
                );
                if (!isTeamSelected) {
                  setSelectedUsersNTeams({
                    ...selectedUserNTeams,
                    groups: [...selectedUserNTeams.groups, option],
                  });
                  setSelectedIds((prev) => ({
                    ...prev,
                    [option.id]: option.id,
                  }));
                  let groups = userNdTeams.groups.filter(
                    (eachTeam) => eachTeam._id !== option.id
                  );
                  setUsersNdTeams((prev) => ({ users: prev.users, groups }));
                }
              }
            }}
            onSearch={debounce(getUserOrTeam, 300)}
          />
          <Typography
            style={{ fontSize: "10px", color: "#475467", marginTop: "4px" }}
          >
            {" "}
            <InfoCircleOutlined
              style={{ marginRight: "5px", color: "#007aff" }}
            />
            Search with minimum 2 characters
          </Typography>

          <div style={{ marginTop: "16px" }}>
            <Switch
              onChange={(checked) => setAnyoneCanView(checked)}
              checked={anyOneCanView}
              style={{ marginRight: "5px" }}
            />
            {anyOneCanView ? "Any " : "No "} one in the organisation can view.
          </div>
        </Col>

        {selectedUserNTeams.users.length > 0 && (
          <>
            <Col span={24} style={{ marginTop: "1rem" }}>
              <Typography className={typographyStyles.subtext2}>
                People with Access
              </Typography>
            </Col>
            <Col
              span={24}
              style={{
                maxHeight:
                  selectedUserNTeams.groups.length > 0 ? "25vh" : "60vh",
                overflowY: "auto",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                paddingBottom: "24px",
              }}
              ref={userScrollRef}
            >
              {selectedUserNTeams.users.map((eachUser) => {
                return (
                  <>
                    <Row
                      style={{
                        margin: "10px 0",
                        minWidth: "100%",
                        display: "flex",
                      }}
                    >
                      <Col>
                        <RandomAvatar
                          user={{ ...eachUser, first_name: eachUser.username }}
                          name={eachUser.username}
                          indexId={eachUser.user_id}
                          url={eachUser.profile_image}
                          size={24}
                        />
                      </Col>
                      <Col span={14} offset={1}>
                        <Typography className={typographyStyles.body3}>
                          {eachUser?.username ||
                            (eachUser?.first_name || "") +
                              " " +
                              (eachUser?.last_name || "")}
                        </Typography>
                        <Typography.Text
                          className={typographyStyles.body2}
                          type="secondary"
                        >
                          {eachUser.value || eachUser?.email}
                        </Typography.Text>
                      </Col>
                      <Col
                        span={6}
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          alignItems: "center",
                        }}
                      >
                        {(creator_id === loggedInUserId ||
                          loggedInUserRole == "admin") && (
                          <CloseOutlined
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              const newUsers = selectedUserNTeams.users.filter(
                                (each) => each.id !== eachUser.id
                              );
                              setRemovedUsersNTeams((prev) => ({
                                groups: prev.groups,
                                users: [...(prev.users || []), eachUser.id],
                              }));
                              setSelectedUsersNTeams({
                                ...selectedUserNTeams,
                                users: newUsers,
                              });
                              delete selectedIds?.[eachUser.id];
                              setSelectedIds((prev) => ({ ...prev }));
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </>
                );
              })}
            </Col>
          </>
        )}

        {selectedUserNTeams.groups.length > 0 && (
          <>
            <Col span={24} style={{ marginBottom: "1rem" }}>
              <Typography className={typographyStyles.subtext2}>
                Teams with Access
              </Typography>
            </Col>
            <Col
              span={24}
              style={{
                maxHeight:
                  selectedUserNTeams.users.length > 0 ? "25vh" : "60vh",
                overflowY: "auto",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                paddingBottom: "24px",
              }}
              ref={teamScrollRef}
            >
              {selectedUserNTeams.groups.map((eachTeam) => {
                return (
                  <>
                    <Row style={{ margin: "10px 0" }}>
                      <Col>
                        <UserOutlined style={{ fontSize: "20px" }} />
                      </Col>
                      <Col
                        span={14}
                        offset={1}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography className={typographyStyles.body3}>
                          {eachTeam.value || eachTeam.name}
                        </Typography>
                        {eachTeam.username && (
                          <Typography.Text
                            type="secondary"
                            className={typographyStyles.body2}
                          >
                            {eachTeam.username}
                          </Typography.Text>
                        )}
                      </Col>
                      <Col span={6} style={{ textAlign: "right" }}>
                        {(creator_id === loggedInUserId ||
                          loggedInUserRole == "admin") && (
                          <CloseOutlined
                            style={{ marginLeft: "10px" }}
                            onClick={() => {
                              const newTeams = selectedUserNTeams.groups.filter(
                                (each) => each.id !== eachTeam.id
                              );
                              setRemovedUsersNTeams((prev) => ({
                                users: prev.users,
                                groups: [...(prev.groups || []), eachTeam.id],
                              }));
                              setSelectedUsersNTeams({
                                ...selectedUserNTeams,
                                groups: newTeams,
                              });
                              delete selectedIds?.[eachTeam.id];
                              setSelectedIds((prev) => ({ ...prev }));
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </>
                );
              })}
            </Col>
          </>
        )}
      </div>
      <Col
        span={24}
        style={{
          textAlign: "right",
          position: "absolute",
          bottom: "0px",
          right: 0,
          padding: "0px 24px 10px 24px",
        }}
      >
        {editBtn && (
          <Button
            className={btnStyles.mdBtn}
            onClick={() => setPreview?.(false)}
          >
            Back to Edit
          </Button>
        )}
        <Button
          className={btnStyles.mdBtn}
          style={{
            marginLeft: "15px",
          }}
          type="primary"
          onClick={() => {
            const permissions = [
              ...selectedUserNTeams.users.map((eachUser) => ({
                level: "user",
                entity: String(eachUser.id || eachUser._id),
                status: "add",
              })),
              ...selectedUserNTeams.groups.map((eachTeam) => ({
                level: "group",
                entity: String(eachTeam.id || eachTeam._id),
                status: "add",
              })),
              ...removedUsersNTeams.users?.map((eachUser) => ({
                level: "user",
                entity: String(eachUser),
                status: "remove",
              })),
              ...removedUsersNTeams.groups?.map((eachTeam) => ({
                level: "group",
                entity: String(eachTeam),
                status: "remove",
              })),
            ];

            publishHandler(
              permissions?.map((each) => {
                !courseId && delete each?.status;
                return each;
              }),
              anyOneCanView
            );
          }}
        >
          Share & Publish
        </Button>
      </Col>
    </>
  );
}
export default ShareTyut;
