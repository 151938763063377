import { Button, Space, Image, Empty } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import VideoPlayer from "./VideoPlayer";
import { vimeoRegex, youtubeRegex } from "../Sops/CreateSOP/AddElem/LinkElem";
import { SelectOutlined } from "@ant-design/icons";
import { getAssetsPath } from "../../helpers/utility";
import { UserContext } from "../../context/UserProvider";

function PdfViewer({ pdfUrl, width = null, height = null }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [loadError, setLoadError] = useState(false);
  const [contentType, setContentType] = useState(null);

  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  function onDocumentLoadSuccess(pdf) {
    setNumPages(pdf.numPages);
  }
  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  useEffect(() => {
    if (pdfUrl) {
      fetch(pdfUrl)
        .then((response) => {
          if (response.ok) {
            const type = response.headers.get("Content-Type");
            setContentType(type);
          } else {
            console.error("HTTP Error:", response.status);
          }
        })
        .catch((error) => console.error("Fetching error:", error));
    }
  }, []);

  return (
    <div style={{ overflow: "auto", height: height || "500px" }}>
      {contentType?.includes("image") ? (
        <>
          <Image
            src={getAssetsPath(pdfUrl, storageType)}
            alt="Image"
            width={"100%"}
            height={350}
            style={{ objectFit: "cover", width: "100%" }}
          />
        </>
      ) : contentType?.includes("pdf") ? (
        <>
          <Document
            file={pdfUrl}
            onSourceSuccess={(a) => {}}
            onSourceError={(a) => {
              setLoadError(true);
            }}
            onLoadError={(err) => {
              setLoadError(true);
            }}
            onLoadSuccess={onDocumentLoadSuccess}
            error={
              loadError ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Empty description="Cannot load pdf file" />
                </div>
              ) : (
                "Failed to load PDF"
              )
            }
          >
            <Page
              noData={true ? "Unable to read file" : "Failed to load PDF"}
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              size="A4"
              scale={1.5}
            />
          </Document>
          {!loadError && numPages > 1 && (
            <Space
              align="end"
              style={{
                display: "flex",
                justifyContent: "flex-end !important",
              }}
            >
              <Button onClick={goToPrevPage} disabled={pageNumber <= 1}>
                Prev
              </Button>
              <Button onClick={goToNextPage} disabled={numPages === pageNumber}>
                Next
              </Button>
            </Space>
          )}
        </>
      ) : youtubeRegex?.test(pdfUrl) || vimeoRegex?.test(pdfUrl) ? (
        <VideoPlayer url={pdfUrl} width="100%" height="500px" />
      ) : (
        <>
          <a
            href={pdfUrl}
            target="_blank"
            style={{ textAlign: "right", display: "block" }}
          >
            New Tab
            <SelectOutlined
              style={{ transform: " rotate(90deg)", marginLeft: "10px" }}
            />
          </a>
          <iframe
            src={pdfUrl}
            title="SOP URL render"
            width={"100%"}
            height={"90%"}
          ></iframe>
        </>
      )}
    </div>
  );
}

export default PdfViewer;
