import React, { useEffect, useState } from "react";
import { Empty, Result, Table, Typography, Skeleton } from "antd";
import { getFacilitiesApi } from "../../services/location";
import { tableComponents } from "../../helpers/utility";

const FacilityTable = () => {
  const [facilities, setFacilities] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "Facility",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No. of users",
      dataIndex: "membersCount",
      key: "membersCount",
    },
    {
      title: "No. of assets",
      dataIndex: "assetsCount",
      key: "assetsCount",
    },
  ];

  const getFacilities = async () => {
    setLoading(true);
    try {
      const data = await getFacilitiesApi();
      setFacilities(
        data?.data?.map((each) => {
          delete each?.children;
          return each;
        })
      );
    } catch (error) {
      setFacilities([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFacilities();
  }, []);

  return (
    <Table
      bordered
      columns={columns}
      dataSource={facilities}
      pagination={false}
      loading={
        loading
          ? {
              spinning: false,
              indicator: null,
            }
          : false
      }
      scroll={{
        x: 1200,
        y: "70vh",
      }}
      locale={{
        emptyText: loading ? (
          <Skeleton active />
        ) : (
          <Result
            icon={Empty.PRESENTED_IMAGE_SIMPLE}
            title={<Typography>No facilities found</Typography>}
          />
        ),
      }}
      components={tableComponents}
    />
  );
};

export default FacilityTable;
