import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../Layout";
import OrgSettingsHeader from "./OrgSettingsHeader";
import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Modal,
  Row,
  Space,
  Switch,
  Typography,
  message,
  Table,
  Popconfirm,
  Tag,
  Input,
  Drawer,
  List,
} from "antd";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import { UserContext } from "../../context/UserProvider";
import {
  getOrganisationApi,
  getTaskTypeApi,
  createTaskTypeApi,
  updateTaskTypeApi,
  deleteTaskTypeApi,
  updateOrganisationApi,
} from "../../services/organisation.services";

import switchStyles from "../../assets/styles/Custom/Switch.module.css";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { getMetaDataApi } from "../../services/users.services";
import { getCookie } from "../../helpers/localStorage";
import { ORGANISATION } from "../../constants/defaultKeys";

const OrgSettings = () => {
  const [updating, setUpdating] = useState(false);
  const [details, setDetails] = useState({});
  const [editTaskType, setEditTaskType] = useState({
    name: "",
    issueCodes: [],
  });
  const [showDrawer, setShowDrawer] = useState(false);
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [taskTypeDetails, setTaskTypeDetails] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);

  const handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedKeys((prev) => [...prev, record._id]);
    } else {
      setExpandedKeys((prev) => prev.filter((_id) => _id !== record._id));
    }
  };

  const getUserData = async () => {
    try {
      let resp = await getMetaDataApi();
      const orgId = getCookie(ORGANISATION);
      const activeOrg = orgId
        ? resp?.data?.organizations?.filter(
            (each) => each?.organization?._id == orgId
          )
        : {};
      setUserDetails((prev) => ({
        ...prev,
        activeOrg: activeOrg[0],
        organizations: resp?.data?.organizations,
      }));
    } catch (error) {}
  };
  const onClose = () => {
    setShowDrawer(false);
  };

  const onFinishTaskType = async (values) => {
    try {
      const res = await createTaskTypeApi(values);
      message.success(res?.data?.message);
      getTaskType();
    } catch (error) {
      message.error(error?.response?.data?.message);
      setModalVisible(false);
    } finally {
      form1.resetFields();
      setModalVisible(false);
    }
  };
  const handleDeleteTaskType = async (id) => {
    try {
      const res = await deleteTaskTypeApi(id);
      getTaskType();
      message.success(res?.data?.message);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const handleDeleteIssueCode = async (id) => {
    try {
      const payload = {
        name: editTaskType?.name,
        issueCodes: editTaskType?.issueCodes
          ?.filter((item) => item?._id == id)
          .map(({ _id, ...rest }) => ({
            issueCodeId: _id,
            ...rest,
            status: "remove",
          })), // Destructure and exclude _id
      };
      const res = await updateTaskTypeApi(editTaskType?._id, payload);
      getTaskType();
      message.success(res?.data?.message);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const getDetails = async () => {
    try {
      const resp = await getOrganisationApi();
      setDetails(resp?.data?.settings);
      form.setFieldsValue({
        inventory_module: resp?.data?.settings?.inventory_module ? true : false,
        track_spare_parts: resp?.data?.settings?.track_spare_parts
          ? true
          : false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getTaskType = async () => {
    try {
      const resp = await getTaskTypeApi();
      setTaskTypeDetails(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTaskType();
    getDetails();
  }, []);

  const handleOnFinish = async (values) => {
    console.log("valus ", values);
    try {
      const existingIssueCodes = editTaskType?.issueCodes || [];

      const issueCodesPayload = values?.issueCodes.map((issue) => {
        const existingIssue = existingIssueCodes.find(
          (e) => e._id === issue._id
        );

        if (!existingIssue) {
          return { ...issue, status: "add" };
        } else if (
          existingIssue.code !== issue.code ||
          existingIssue.description !== issue.description
        ) {
          return { issueCodeId: issue._id, ...issue, status: "update" };
        }
        return issue;
      });

      const issueCodes = issueCodesPayload
        .filter((item) => item?.status)
        .map(({ _id, ...rest }) => rest);

      const payload = {
        name: editTaskType?.name,
        issueCodes: issueCodes,
      };

      // Send API request
      const res = await updateTaskTypeApi(editTaskType._id, payload);
      message.success(res?.data?.message);
      getTaskType();
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      form.resetFields();
      setShowDrawer(false);
    }
  };

  const onFinish = async (values) => {
    let payload = {
      settings: {
        inventory_module: values?.inventory_module,
        track_spare_parts: values?.track_spare_parts,
      },
    };
    try {
      const resp = await updateOrganisationApi(payload);
      if (resp) {
        message.success(resp?.data?.message);
        await getUserData();
        await getDetails();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setUpdating(false);
    }
  };

  const tableColumns = [
    {
      title: "Task type",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },

    ...(userDetails?.activeOrg?.role === "admin"
      ? [
          {
            title: (
              <Typography
                className={"tableHeader"}
                style={{ fontWeight: "bold" }}
              >
                Actions
              </Typography>
            ),
            dataIndex: "action",
            key: "action",

            render: (_, record) => (
              <Flex gap={12} align="center" justify="start">
                <Popconfirm
                  icon={false}
                  description="Are you sure to delete this task type?"
                  onConfirm={(e) => {
                    handleDeleteTaskType(record?._id);
                    e.stopPropagation();
                  }}
                  onCancel={(e) => e.stopPropagation()}
                >
                  <DeleteOutlined />
                </Popconfirm>
                <EditOutlined
                  onClick={() => {
                    setEditTaskType(record);
                    setShowDrawer(true);
                  }}
                />
              </Flex>
            ),
          },
        ]
      : []),
  ];

  return (
    <CustomLayout
      header={<OrgSettingsHeader />}
      footer={
        updating && (
          <Flex justify="flex-end" gap={12}>
            <Button
              className={btnStyles.mdBtn}
              onClick={() => {
                form.resetFields();
                setUpdating(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className={btnStyles.mdBtn}
              type="primary"
              onClick={form.submit}
            >
              Update
            </Button>
          </Flex>
        )
      }
    >
      <Row gutter={[12, 16]}>
        {/* <Col span={16}>
          <TaskTypes />
        </Col> */}
        <Col span={16}>
          <Card
            title="Inventory settings"
            styles={{
              header: { backgroundColor: "#fff" },
            }}
          >
            <Form
              form={form}
              layout="vertical"
              requiredMark={false}
              disabled={userDetails?.activeOrg?.role != "admin"}
              initialValues={details}
              onValuesChange={() => setUpdating(true)}
              onFinish={onFinish}
              style={{
                pointerEvents:
                  userDetails?.activeOrg?.role == "admin" ? "auto" : "none",
              }}
            >
              <Space style={{ width: "100%" }}>
                <Form.Item
                  name="inventory_module"
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Switch className={switchStyles.basic} />
                </Form.Item>
                <Typography>Enable Inventory Module</Typography>
              </Space>
              <Space style={{ width: "100%", marginTop: 12 }}>
                <Form.Item
                  name="track_spare_parts"
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Switch className={switchStyles.basic} />
                </Form.Item>
                <Typography>Enable Tracking of Spare Parts</Typography>
              </Space>
            </Form>
          </Card>
        </Col>
      </Row>
      {/* <Row gutter={[24, 18]} style={{ marginTop: 24 }}>
        <Col span={userDetails?.activeOrg?.role === "admin" ? 16 : 10}>
          <Table
            rowKey="_id"
            title={() => (
              <Space
                align="center"
                style={{ width: "100%", justifyContent: "flex-end", gap: 16 }}
              >
                {userDetails?.activeOrg?.role === "admin" && (
                  <Button
                    id="create-task-type"
                    type="primary"
                    className={btnStyles.mdBtn}
                    onClick={() => setModalVisible(true)}
                  >
                    + Add Task Type
                  </Button>
                )}
              </Space>
            )}
            pagination={false}
            bordered
            columns={tableColumns}
            dataSource={taskTypeDetails}
            expandable={{
              expandedRowRender: (record) => (
                <List
                  size="default"
                  dataSource={record?.issueCodes || []}
                  renderItem={(item) => (
                    <List.Item style={{ paddingLeft: 50, textAlign: "left" }}>
                      <Space>
                        <Tag color="blue">{item?.code}</Tag>
                        <Typography>{item?.description}</Typography>
                      </Space>
                    </List.Item>
                  )}
                />
              ),
              rowExpandable: (record) => Boolean(record?.issueCodes?.length),
              expandedRowKeys: expandedKeys,
              onExpand: handleExpand,
            }}
          />
        </Col>
      </Row>
      {modalVisible && (
        <Modal
          title={` Add Task Type`}
          open={modalVisible}
          onCancel={() => {
            setModalVisible(false);
            form1.resetFields();
          }}
          footer={null}
        >
          <Form
            form={form1}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinishTaskType}
          >
            <Form.Item
              name={"name"}
              style={{ marginBottom: "0px" }}
              rules={[
                {
                  required: true,
                  message: "Task type is required!",
                },
                {
                  min: 3,
                  message: "Task type must be at least 3 characters long!",
                },
                {
                  max: 30,
                  message: "Task type cannot exceed 30 characters!",
                },
              ]}
            >
              <Input size="medium" placeholder="Enter task type" />
            </Form.Item>
            <Form.Item
              style={{ textAlign: "right", marginBottom: "0px", marginTop: 20 }}
            >
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
      {showDrawer && (
        <Drawer
          title="Edit Task Type"
          placement="right"
          width={500}
          onClose={onClose}
          open={showDrawer}
          footer={
            <div style={{ textAlign: "right" }}>
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="primary" htmlType="submit" form="editTaskForm">
                  Submit
                </Button>
              </Space>
            </div>
          }
          footerStyle={{ padding: "10px 24px" }}
        >
          <Form
            id="editTaskForm"
            name="dynamic_form_nest_item"
            onFinish={handleOnFinish}
            style={{ maxWidth: 600, padding: "0 20px" }}
            autoComplete="off"
            initialValues={{ issueCodes: editTaskType?.issueCodes || [] }}
          >
            <Form.Item
              name={"name"}
              initialValue={editTaskType?.name}
              rules={[
                {
                  required: true,
                  message: "Task type is required!",
                },
                {
                  min: 3,
                  message: "Task type must be at least 3 characters long!",
                },
                {
                  max: 30,
                  message: "Task type cannot exceed 30 characters!",
                },
              ]}
            >
              <Input
                size="large"
                label="Task Type"
                name="name"
                placeholder="Enter task type"
                onChange={(e) =>
                  setEditTaskType({ ...editTaskType, name: e.target.value })
                }
              />
            </Form.Item>
            <Space style={{ marginBottom: 10 }}>
              <Typography style={{ fontWeight: "bold" }}>
                Issue codes
              </Typography>
            </Space>
            <Form.List name="issueCodes">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    const issueCodeId = editTaskType?.issueCodes[key]?._id;

                    return (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "code"]}
                          rules={[
                            {
                              required: true,
                              message: "Please provide issue code!",
                            },
                            {
                              min: 3,
                              message:
                                "Issue code must be at least 3 characters long!",
                            },
                            {
                              max: 20,
                              message:
                                "Issue code cannot exceed 30 characters!",
                            },
                          ]}
                        >
                          <Input placeholder="Issue code" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "description"]}
                          rules={[
                            {
                              max: 50,
                              message:
                                "Description cannot exceed 100 characters!",
                            },
                          ]}
                        >
                          <Input placeholder="Description" />
                        </Form.Item>
                        <DeleteOutlined
                          onClick={() => {
                            if (issueCodeId) {
                              handleDeleteIssueCode(issueCodeId);
                            }
                            remove(key);
                          }}
                        />
                      </Space>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add issues
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </Drawer>
      )} */}
    </CustomLayout>
  );
};

export default OrgSettings;
