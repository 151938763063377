import { Button, Col, Form, Modal, Row, TreeSelect } from "antd";
import React, { useEffect, useRef } from "react";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../assets/styles/Custom/Input.module.css";

const AssetFilters = ({
  showFilterModal,
  setShowFilterModal,
  assetCategories,
  filters,
  setFilters,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef();

  const assetStatusOptions = [
    {
      title: "Online",
      value: "Online",
    },
    {
      title: "Under Maintenance",
      value: "Under Maintenance",
    },
    {
      title: "Not Working",
      value: "Not Working",
    },
    {
      title: "Retired",
      value: "Retired",
    },
  ];

  useEffect(() => {
    if (showFilterModal) {
      form.setFieldsValue(filters);
    }
  }, [showFilterModal, filters]);

  const onFinish = (values) => {
    setShowFilterModal(false);
    setFilters({
      categories: values?.categories,
      statuses: values?.statuses,
    });
  };

  return (
    <>
      <Modal
        title="Filter"
        width={"50%"}
        open={showFilterModal}
        onCancel={() => setShowFilterModal(false)}
        maskClosable={false}
        footer={
          <Button
            className={btnStyles.mdBtn}
            type="primary"
            onClick={() => formRef?.current?.submit()}
          >
            Apply
          </Button>
        }
      >
        <Form layout="vertical" form={form} ref={formRef} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="Asset Category" name={"categories"}>
                <TreeSelect
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Asset Category"
                  allowClear
                  multiple={true}
                  treeCheckable={true}
                  showSearch={false}
                  treeData={assetCategories}
                  popupClassName="filter-tree-dropdown"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Asset Status" name={"statuses"}>
                <TreeSelect
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Asset Status"
                  allowClear
                  multiple={true}
                  treeCheckable={true}
                  showSearch={false}
                  treeData={assetStatusOptions}
                  popupClassName="filter-tree-dropdown"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AssetFilters;
