import { ConfigProvider, Tabs, Typography } from "antd";
import React, { useEffect, useState } from "react";
import CustomLayout from "../Layout";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import Courses from "./Courses";
import Tyuts from "./Tyuts";
import { useNavigate, useSearchParams } from "react-router-dom";

const Trainings = () => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const [view, setView] = useState("tyut");

  useEffect(() => {
    if (searchParams.get("type") == "course") {
      setView("course");
    } else {
      setView("tyut");
    }
  }, [searchParams]);

  const tabItems = [
    {
      key: "tyut",
      label: "Tyuts",
    },
    {
      key: "course",
      label: "Courses",
    },
  ];

  return (
    <CustomLayout
      header={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 35,
          }}
        >
          <Typography className={typoStyles.h6} style={{ marginTop: 6 }}>
            Trainings
          </Typography>
          <div style={{ height: 36 }}>
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    colorBorderSecondary: "#d0d5dd",
                  },
                },
              }}
            >
              <Tabs
                className="schedulerTabs"
                activeKey={view}
                onChange={(e) => navigate(`/trainings?type=${e}`)}
                moreIcon={null}
              >
                {tabItems?.map((item) => (
                  <Tabs.TabPane key={item?.key} tab={item?.label} />
                ))}
              </Tabs>
            </ConfigProvider>
          </div>
        </div>
      }
    >
      <>
        {view == "tyut" ? (
          <Tyuts view={view} setView={setView} />
        ) : (
          <Courses view={view} setView={setView} />
        )}
      </>
    </CustomLayout>
  );
};

export default Trainings;
