import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../Layout";
import OrgSettingsHeader from "./OrgSettingsHeader";
import { Button, Card, Col, Empty, Flex, Form, Input, Modal, Popconfirm, Result, Row, Skeleton, Space, Switch, Table, Tag, TreeSelect, Typography } from "antd";
import { MES_LINK } from "../../constants/defaultKeys";
import { getMesToken } from "../../services/organisation.services";
import switchStyles from "../../assets/styles/Custom/Switch.module.css";
import CopyToClipboard from "react-copy-to-clipboard";
import { CopyTwoTone, DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { UserContext } from "../../context/UserProvider";
import { tableComponents } from "../../helpers/utility";
import btnStyles from "../../assets/styles/Custom/Button.module.css";

const IntegrationPage = () => {
    const { userDetails, setUserDetails } = useContext(UserContext);

  const [mesDetails, setMesDetails] = useState(null);
  const [mesChecked, setMesChecked] = useState(false);
  const [showMesModal, setShowMesModal] = useState(false);
  
  const fetchMesToken = async () => {
    try {
      const resp = await getMesToken();
      if (resp?.data) {
        setMesDetails(resp?.data);
        setMesChecked(resp?.data?.token ? true : false);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchMesToken();
  }, []);

  
  const displayMesDetail = (title, value) => {
    return (
      <Space style={{ gap: 16 }}>
        <Typography style={{ width: 80 }}>{title} :</Typography>
        {value && (
          <Typography>
            <span
              style={{
                border: "1px solid #d3d4d4",
                padding: 4,
                borderRadius: 4,
              }}
            >
              {value}
            </span>
            <CopyToClipboard
              text={value}
              onCopy={() => message.success("Copied")}
            >
              <CopyTwoTone style={{ marginLeft: 8 }} />
            </CopyToClipboard>
          </Typography>
        )}
      </Space>
    );
  };

  return (
    <CustomLayout header={<OrgSettingsHeader />}>
      <Row>
        <Col span={16}>
          <Card
            title={
              <Space
                align="baseline"
                style={{ width: "100%", justifyContent: "space-between" }}
              >
                <Typography style={{ fontSize: 16 }}>
                  Manufacturing Execution System (MES) settings
                </Typography>
                <Typography.Link underline href={MES_LINK} target="_blank">
                  View Developer Documentation
                </Typography.Link>
              </Space>
            }
            styles={{
              header: { backgroundColor: "#fff" },
            }}
          >
            <Space align="center">
              <div
                style={{
                  cursor: mesDetails?.token ? "not-allowed" : "pointer",
                }}
              >
                <Switch
                  className={switchStyles.basic}
                  checked={mesChecked}
                  onChange={(checked) => {
                    setMesChecked(checked);
                    checked && setShowMesModal(true);
                  }}
                  style={{ pointerEvents: mesDetails?.token ? "none" : "auto" }}
                />
              </div>
              <Typography>Integrate with MES</Typography>
            </Space>
            {mesDetails?.token && (
              <>
                <Space
                  direction="vertical"
                  style={{ width: "100%", gap: 12, marginTop: 12 }}
                >
                  {displayMesDetail(
                    "Endpoint",
                    "https://getbolt.cc/api/webhook/oee"
                  )}
                  {displayMesDetail("Token", mesDetails?.token)}
                  {displayMesDetail("Secret Key", mesDetails?.secret_key)}
                </Space>
              </>
            )}
            <Modal
              title="MES Confirmation"
              open={showMesModal}
              cancelText="No"
              okText="Yes"
              onCancel={() => {
                setShowMesModal(false);
                setMesChecked(false);
              }}
              onOk={() => {
                setShowMesModal(false);
                // generateMesToken();
              }}
              maskClosable={false}
            >
              <Space direction="vertical" style={{ width: "100%" }}>
                <Typography>
                  Are you sure you want to integrate with MES ?
                </Typography>
              </Space>
            </Modal>
          </Card>
        </Col>
      </Row>
      
    </CustomLayout>
  );
};

export default IntegrationPage;
