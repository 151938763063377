import React, { useEffect, useState } from "react";
import { Typography, Tabs, ConfigProvider } from "antd";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";

import "./Locations.css";
import CustomLayout from "../Layout";
import TreeLocations from "./TreeLocatios";
import FacilityTable from "./FacilityTable";
import { useNavigate, useSearchParams } from "react-router-dom";

const Locations = () => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const [type, setType] = useState("all");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (searchParams.get("type") == "facility") {
      setType("facility");
    } else {
      setType("all");
    }
  }, [searchParams]);

  const tabItems = [
    {
      key: "all",
      label: "All locations",
    },
    {
      key: "facility",
      label: "Facilities",
    },
  ];

  return (
    <CustomLayout
      header={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 35,
          }}
        >
          <Typography className={typoStyles.h6} style={{ marginTop: 6 }}>
            Locations
          </Typography>
          <div style={{ height: 36 }}>
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    colorBorderSecondary: "#d0d5dd",
                  },
                },
              }}
            >
              <Tabs
                className="schedulerTabs"
                activeKey={type}
                onChange={(e) => navigate(`/locations?type=${e}`)}
                moreIcon={null}
              >
                {tabItems?.map((item) => (
                  <Tabs.TabPane key={item?.key} tab={item?.label} />
                ))}
              </Tabs>
            </ConfigProvider>
          </div>
        </div>
      }
    >
      {type == "all" ? (
        <TreeLocations setRefresh={setRefresh} refresh={refresh} />
      ) : (
        <FacilityTable />
      )}
    </CustomLayout>
  );
};

export default Locations;
