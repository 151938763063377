import { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  List,
  Avatar,
  Button,
  Typography,
  Popconfirm,
  Result,
  Empty,
  Form,
  Card,
  message,
  Space,
  Upload,
  Image,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  CheckOutlined,
  UploadOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { MentionsInput, Mention } from "react-mentions";
import CommentCss from "./CommentCss";
import {
  createCommentApi,
  deleteCommentByIDApi,
  getCommentsApi,
} from "../../../../services/work-orders.services";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteDocs,
  getAssetsPath,
  getAvatarBackgroundColor,
  renameFile,
  timeAgo,
  uploadFunc,
} from "../../../../helpers/utility";
import EditComment from "./EditComment";
import { getUsersApi } from "../../../../services/users.services";
import { UserContext } from "../../../../context/UserProvider";
import { ONPREMISE } from "../../../../constants/defaultKeys";

const Comments = ({ workOrderID, edit, verifyStatus }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [comments, setComments] = useState([]);
  const [users, setUsers] = useState([]);
  const [newComment, setNewComment] = useState({
    content: "",
    markup: "",
    tagged_users: [],
  });
  const [editComment, setEditComment] = useState({
    status: false,
    value: null,
  });
  const [imageFiles, setImageFiles] = useState([]);
  const [showError, setShowError] = useState(false);

  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  const refreshPage = () => {
    setRefresh((prevValue) => !prevValue);
  };

  const deleteComment = async (comment_id) => {
    try {
      const resp = await deleteCommentByIDApi(comment_id, "workorder");
      message.success(resp?.data?.message);
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!!");
    } finally {
      refreshPage();
    }
  };

  const handleChange = (event, _, newPlainTextValue, mentions) => {
    setNewComment({
      content: newPlainTextValue,
      markup: event?.target?.value,
      tagged_users: mentions,
    });
    setShowError(newPlainTextValue?.length > 0 ? false : true);
  };

  useEffect(() => {
    if (!params?.id && workOrderID) {
      newComment?.content?.length > 0
        ? createComment()
        : navigate("/work-orders");
    }
  }, [workOrderID]);

  const createComment = async (values) => {
    if (newComment?.content?.length > 0) {
      setLoading(true);
      let data = {
        ...newComment,
        tagged_users: newComment?.tagged_users?.map((user) => user?.id),
        files:
          imageFiles?.length > 0
            ? imageFiles?.map((imgFile) =>
                storageType == ONPREMISE ? imgFile : renameFile(imgFile)
              )
            : [],
      };
      !newComment?.tagged_users?.length > 0 && delete data?.tagged_users;
      try {
        const resp = await createCommentApi(workOrderID, data, "workorder");
        if (resp) {
          setNewComment({ content: "", markup: "", tagged_users: [] });
          setImageFiles([]);
          !params?.id && navigate("/work-orders");
        }
      } catch (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!!"
        );
      } finally {
        refreshPage();
        setLoading(false);
      }
    } else {
      setShowError(true);
    }
  };

  const getComments = async () => {
    try {
      const resp = await getCommentsApi(workOrderID, "workorder");
      setComments(resp?.data);
    } catch (error) {}
  };

  const getUsers = async () => {
    let params = {};

    const queryString = new URLSearchParams(params);
    try {
      const resp = await getUsersApi(queryString);
      const usersData = resp?.data?.map((eachUser) => {
        return {
          display:
            (eachUser?.first_name || "") + " " + (eachUser?.last_name || ""),
          id: eachUser?._id,
        };
      });
      setUsers(usersData);
    } catch (error) {}
  };

  useEffect(() => {
    workOrderID && getComments();
  }, [refresh, workOrderID]);

  useEffect(() => {
    getUsers();
  }, []);

  const validateFileType = (file) => {
    const isImage = file.type.startsWith("image/");
    if (!isImage) {
      message.error("You can only upload image files!");
    }
    return isImage;
  };

  const handleUpload = (info) => {
    if (info.file.status !== "uploading") {
      uploadImage(info);
    }
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (info.file.status === "error") {
      message.error("Image upload failed.");
    }
  };

  const uploadImage = async (info, key) => {
    try {
      const resp = await uploadFunc(info, storageType);
      if (resp) {
        setImageFiles([...imageFiles, resp?.path || ""]);
      }
    } catch (error) {}
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Card
      title="Remarks"
      styles={{
        header: { backgroundColor: "#fff" },
      }}
    >
      <Form.Item
        name="comments"
        style={{ margin: "0px", height: "0px" }}
      ></Form.Item>
      {(!verifyStatus || userDetails?.activeOrg?.role === "manager") && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            gap: 12,
            flexDirection: "column",
          }}
        >
          <Space direction="vertical" style={{ width: "100%", gap: 0 }}>
            <MentionsInput
              onChange={handleChange}
              value={newComment?.markup}
              placeholder="Add notes here (Use '@' for mention)"
              style={CommentCss}
              className="custom-mentions-input-pl"
            >
              <Mention data={users} style={{ backgroundColor: "#cee4e5" }} />
            </MentionsInput>
            {showError && (
              <div style={{ color: "#F04438" }}>Please enter notes</div>
            )}
          </Space>
          <Upload
            beforeUpload={(file) => {
              if (!validateFileType(file)) {
                return Upload.LIST_IGNORE; 
              }
              // console.log(file.size);
              return new Promise((resolve) => {
                new Compressor(file, {
                  quality: 0.8, // Adjust quality between 0 and 1
                  convertSize: 1000000,
                  success(compressedFile) {
                    resolve(compressedFile); // Resolve with the compressed file
                  },
                  error(err) {
                    console.error("Compression failed:", err.message);
                  },
                });
              });
            }}
            customRequest={dummyRequest}
            name="file"
            showUploadList={false}
            onChange={handleUpload}
            accept="image/*"
            multiple={false}
          >
            <Button>
              Upload Images <UploadOutlined />
            </Button>
          </Upload>
          <Space style={{ width: "100%", marginTop: 10 }}>
            {imageFiles?.map(
              (imgSrc) =>
                imgSrc && (
                  <div style={{ position: "relative" }}>
                    <Image
                      src={getAssetsPath(imgSrc, storageType)}
                      preview={true}
                      style={{ width: 80, height: 80 }}
                    />
                    <CloseCircleOutlined
                      style={{
                        fontSize: 16,
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                      onClick={() => {
                        deleteDocs(imgSrc, storageType);
                        setImageFiles(imageFiles?.filter((i) => i !== imgSrc));
                      }}
                    />
                  </div>
                )
            )}
          </Space>
          {(newComment?.content?.length > 0 || imageFiles?.length > 0) &&
            params?.id && (
              <div style={{ width: "100%" }}>
                <Button
                  type="primary"
                  onClick={createComment}
                  style={{ float: "right" }}
                  loading={loading}
                >
                  Done <CheckOutlined />
                </Button>
              </div>
            )}
        </div>
      )}
      {comments?.length > 0 && (
        <Row className="comments" style={{ marginTop: 10 }}>
          <Col span={24}>
            <List
              size="small"
              dataSource={comments}
              itemLayout="horizontal"
              locale={{
                emptyText: (
                  <Result
                    icon={Empty.PRESENTED_IMAGE_SIMPLE}
                    title={<Typography>No comments</Typography>}
                  />
                ),
              }}
              renderItem={(item) => (
                <List.Item
                  style={{ alignItems: "baseline" }}
                  key={item?._id}
                  actions={
                    userDetails?._id === item?.creator?._id &&
                    (!verifyStatus ||
                      userDetails?.activeOrg?.role === "manager") && [
                      <span
                        key={item?.creator?._id}
                        onClick={() =>
                          setEditComment({ value: item, status: true })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <EditOutlined />
                      </span>,
                      <Popconfirm
                        title="Are you sure ?"
                        key={item?.creator?._id}
                        icon={
                          <QuestionCircleOutlined style={{ color: "red" }} />
                        }
                        onConfirm={() => deleteComment(item?._id)}
                      >
                        <DeleteOutlined />
                      </Popconfirm>,
                    ]
                  }
                >
                  <List.Item.Meta
                    style={{ alignItems: "baseline" }}
                    className="comments-list"
                    avatar={
                      <Avatar
                        key={item?._id}
                        style={{
                          backgroundColor: `${getAvatarBackgroundColor(
                            item?.creator?.first_name
                          )}`,
                        }}
                        src={item?.creator?.profile_image}
                        size={28}
                      >
                        {item?.creator?.first_name[0]?.toUpperCase()}
                      </Avatar>
                    }
                    title={
                      <Space direction="vertical" style={{ gap: 0 }}>
                        <Typography>{item?.creator?.first_name}</Typography>
                      </Space>
                    }
                    description={
                      <Space direction="vertical">
                        <Typography style={{ fontWeight: "400" }}>
                          {item?.content}
                        </Typography>
                        <Space style={{ width: "100%" }}>
                          {item?.files?.map(
                            (imgSrc) =>
                              imgSrc && (
                                <Image
                                  src={getAssetsPath(imgSrc, storageType)}
                                  preview={true}
                                  style={{ width: 80, height: 80 }}
                                />
                              )
                          )}
                        </Space>
                      </Space>
                    }
                  />
                  <Typography style={{ color: "#00000073" }}>
                    {timeAgo(
                      item?.updated_at ? item.updated_at : item?.created_at
                    )}
                  </Typography>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      )}
      <EditComment
        users={users}
        refresh={refreshPage}
        editComment={editComment}
        setEditComment={setEditComment}
      />
    </Card>
  );
};

export default Comments;
