import {
  Button,
  Col,
  Form,
  Image,
  Popover,
  Radio,
  Row,
  Space,
  Typography,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import CustomCard from "./CustomCard";
import checkboxStyles from "../../assets/styles/Custom/Checkbox.module.css";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import { SOP } from "../../constants/defaultKeys";
import { getAssetsPath } from "../../helpers/utility";

function CustomRadioButton({
  data,
  title,
  onChange,
  disabled = false,
  answers = {},
  onFinish,
  isAnsSelected = {},
  selectedValue,
  contentType = SOP,
  viewAnsHint,
  storageType,
}) {
  const [form] = Form.useForm();
  const [isViewAns, setIsViewAns] = useState(false);

  useEffect(() => {
    if (isViewAns) {
      form.setFieldsValue({ selected_ans: data?.answers });
    } else {
      form.setFieldsValue({
        selected_ans: selectedValue?.radio,
      });
    }
  }, [isViewAns, data]);

  const Elem = contentType === SOP ? Fragment : Form;
  const isEnglishText = (text) => /^[A-Za-z0-9\s.,!?;:'"-]+$/.test(text);

  return (
    <CustomCard>
      <Row gutter={[24, 24]} style={{ height: "100%" }}>
        <Col span={24}>
          <Typography
            style={{
              fontSize: isEnglishText(title) ? 16 : 19,
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            {title}
          </Typography>
        </Col>

        <Col span={24}>
          <Elem form={form} onFinish={onFinish} name="Sle">
            <Form.Item
              name="selected_ans"
              rules={
                contentType !== SOP
                  ? [
                      {
                        required: true,
                        message: "select answer",
                      },
                    ]
                  : []
              }
            >
              <Radio.Group
                style={{ width: "100%" }}
                onChange={onChange}
                defaultValue={answers?.[data?.id]}
              >
                <Row gutter={[24, 24]}>
                  {data?.choices?.map((item, i) => (
                    <Col span={12} key={i}>
                      <Radio
                        value={item}
                        key={i}
                        disabled={disabled}
                        className={checkboxStyles.smRadio}
                      >
                        {data?.optionType == "images" ? (
                          <Image
                            src={getAssetsPath(item, storageType)}
                            alt="Uploaded"
                            style={{
                              width: "100px",
                              marginBlock: "12px",
                              height: "100px",
                            }}
                          />
                        ) : (
                          <Typography
                            style={{
                              fontSize: isEnglishText(title) ? 14 : 17,
                              fontWeight: 400,
                              lineHeight: "24px",
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}
                          >
                            {item}
                          </Typography>
                        )}
                      </Radio>
                    </Col>
                  ))}
                </Row>
              </Radio.Group>
            </Form.Item>
            {contentType != SOP && (
              <>
                {Object.keys(isAnsSelected)?.includes(data?.id) &&
                  viewAnsHint?.[data?.id] && (
                    <Space direction="vertical">
                      <Typography>Answer hint</Typography>
                      <Typography>
                        {data?.ansDesc}{" "}
                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: "8px",
                            fontWeight: 600,
                            lineHeight: "18px",
                            textUnderlineOffset: "2px",
                            textDecoration: "underline",
                            color: "blue",
                          }}
                          onClick={() => setIsViewAns((prev) => !prev)}
                        >
                          {!isViewAns
                            ? "see the correct answer"
                            : "unhide answer"}
                        </span>
                      </Typography>
                    </Space>
                  )}
                <Form.Item>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <Popover
                      placement="left"
                      content={
                        isAnsSelected?.[data?.id] === 0 ||
                        !Object.keys(isAnsSelected).includes(data?.id) ||
                        Object.keys(isAnsSelected).length === 0
                          ? "Choose an answer"
                          : ""
                      }
                    >
                      <Button
                        className={btnStyles.mdBtn}
                        type="primary"
                        htmlType="submit"
                        disabled={
                          isAnsSelected?.[data?.id] === 0 ||
                          !Object.keys(isAnsSelected).includes(data?.id) ||
                          Object.keys(isAnsSelected).length === 0
                            ? true
                            : false
                        }
                      >
                        Submit
                      </Button>
                    </Popover>
                  </div>
                </Form.Item>
              </>
            )}
          </Elem>
        </Col>
      </Row>
    </CustomCard>
  );
}

export default CustomRadioButton;
