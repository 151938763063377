import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Image,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import CustomLayout from "../Layout";
import WorkOrdersTable from "./WorkOrdersTable";
import dayjs from "dayjs";
import { displayFilterTag } from "../../helpers/utility";
import {
  getAllAssetsApi,
  getProductionLines,
} from "../../services/assets.services";
import WoFilters from "./WoFilters";
import filterActiveIcon from "../../assets/images/filterActiveIcon.svg";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";

const { RangePicker } = DatePicker;
const WorkOrders = () => {
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "month"),
    end_date: dayjs().add(1, "month"),
  });
  const [totalCOunt, setTotalCOunt] = useState(0);
  const [filters, setFilters] = useState({
    asset: undefined,
    production_line: undefined,
    types: [],
    priorities: [],
    statuses: [],
    verification: undefined,
  });
  const [assets, setAssets] = useState([]);
  const [productionLines, setProductionLines] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const fetchAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: (
            <div
              style={{
                gap: 8,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tag className={tagStyles.smTag} color="blue">
                {asset?.code}
              </Tag>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {asset?.name}
              </span>
            </div>
          ),
          name: asset?.name,
          value: asset?._id,
        }))
      );
    } catch (error) {
      setAssets([]);
    }
  };

  const fetchProductionLines = async () => {
    try {
      const res = await getProductionLines();
      setProductionLines(
        res?.data?.assets?.map((item) => ({
          ...item,
          label: item?.name,
          value: item?._id,
        }))
      );
    } catch (error) {
      setProductionLines([]);
    }
  };

  useEffect(() => {
    fetchAssets();
    fetchProductionLines();
  }, []);

  return (
    <CustomLayout
      header={
        <Typography className={typoStyles.h6}>
          Work Orders ({totalCOunt || 0})
        </Typography>
      }
    >
      <Row gutter={[0, 16]}>
        <Col span={10}>
          <Space
            style={{ width: "100%", justifyContent: "flex-start", gap: 12 }}
          >
            <Button
              style={{ color: "#6941C6" }}
              className={btnStyles.smBtn}
              type="default"
              icon={
                <Image
                  src={filterActiveIcon}
                  width={18}
                  height={18}
                  alt="filter"
                  preview={false}
                />
              }
              onClick={() => setShowFilterModal(true)}
            >
              Filter
            </Button>
          </Space>
          {showFilterModal && (
            <WoFilters
              showFilterModal={showFilterModal}
              setShowFilterModal={setShowFilterModal}
              assets={assets}
              productionLines={productionLines}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </Col>
        <Col offset={4} span={10} style={{ textAlign: "right" }}>
          <RangePicker
            id="wo-filter"
            format="DD-MMM-YYYY"
            value={[dateFilter.start_date, dateFilter.end_date]}
            onChange={(dates, dateString) => {
              setDateFilter({
                start_date: dates?.[0],
                end_date: dates?.[1],
              });
            }}
          />
        </Col>
        {(filters?.asset ||
          filters?.production_line ||
          filters?.types?.length > 0 ||
          filters?.priorities?.length > 0 ||
          filters?.statuses?.length > 0 ||
          filters?.verification) && (
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              gap: 6,
              flexWrap: "wrap",
            }}
          >
            {filters?.asset && displayFilterTag("Asset", "purple")}
            {filters?.production_line &&
              displayFilterTag("Production Line", "purple")}
            {filters?.types?.length > 0 &&
              displayFilterTag("WO Type", "volcano", filters?.types?.length)}
            {filters?.priorities?.length > 0 &&
              displayFilterTag(
                "Priority",
                "magenta",
                filters?.priorities?.length
              )}
            {filters?.statuses?.length > 0 &&
              displayFilterTag("WO Status", "blue", filters?.statuses?.length)}
            {filters?.verification &&
              displayFilterTag("WO Verification", "orange")}
            <Typography
              style={{ color: "#7369F4", cursor: "pointer", fontSize: 12 }}
              onClick={() =>
                setFilters({
                  asset: undefined,
                  production_line: undefined,
                  types: [],
                  priorities: [],
                  statuses: [],
                  verification: undefined,
                })
              }
            >
              Clear All
            </Typography>
          </Col>
        )}
        <WorkOrdersTable
          totalCOunt={totalCOunt}
          setTotalCOunt={setTotalCOunt}
          dateFilter={dateFilter}
          filters={filters}
        />
      </Row>
    </CustomLayout>
  );
};

export default WorkOrders;
