import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Typography,
  Space,
  Input,
  Popconfirm,
  message,
  Table,
  Skeleton,
  Result,
  Empty,
  Flex,
  Modal,
  Form,
} from "antd";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { UserContext } from "../../context/UserProvider";
import {
  createDepartmentApi,
  deleteDepartmentApi,
  getDepartments,
  updateDepartmentApi,
} from "../../services/department.services";
import CustomLayout from "../Layout";
import OrgSettingsHeader from "./OrgSettingsHeader";
import { tableComponents } from "../../helpers/utility";
const Departments = () => {
  const { userDetails } = useContext(UserContext);
  const [departments, setDepartments] = useState([]);
  const [deptLoading, setDeptLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [editDept, setEditDept] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const [form] = Form.useForm();

  const fetchDepartments = async () => {
    setDeptLoading(true);
    try {
      const res = await getDepartments();
      setDepartments(res?.data);
    } catch (error) {
      setDepartments([]);
    } finally {
      setDeptLoading(false);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, [refresh]);

  useEffect(() => {
    if (editDept) {
      form.setFieldsValue({
        name: editDept?.name,
      });
    }
  }, [editDept]);

  const refreshPage = () => {
    setRefresh((prev) => !prev);
  };

  const onFinish = async (values) => {
    let payload = { name: values?.name };
    try {
      const resp = editDept?.id
        ? await updateDepartmentApi(editDept?.id, payload)
        : await createDepartmentApi(payload);
      if (resp) {
        message.success(resp?.data?.message);
        setModalVisible(false);
        refreshPage();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
      setModalVisible(false);
    } finally {
      form.resetFields();
    }
  };

  const deleteDepartment = async (id) => {
    try {
      const resp = await deleteDepartmentApi(id);
      message.success(resp?.data?.message);
      refreshPage();
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const tableColumns = [
    {
      title: "Department name",
      dataIndex: "name",
      key: "name",
      width: 250,
    },

    ...(userDetails?.activeOrg?.role === "admin"
      ? [
          {
            title: <Typography className={"tableHeader"}>Actions</Typography>,
            dataIndex: "action",
            key: "action",
            width: 140,
            render: (_, record) => (
              <Flex gap={12} align="center" justify="start">
                <Popconfirm
                  icon={false}
                  description="Are you sure to delete this department?"
                  onConfirm={(e) => {
                    e.stopPropagation();
                    deleteDepartment(record?._id);
                  }}
                  onCancel={(e) => e.stopPropagation()}
                >
                  <DeleteOutlined />
                </Popconfirm>
                <EditOutlined
                  onClick={() => {
                    setEditDept({
                      id: record?._id,
                      name: record?.name,
                    });
                    setModalVisible(true);
                  }}
                />
              </Flex>
            ),
          },
        ]
      : []),
  ];

  return (
    <CustomLayout
      header={<OrgSettingsHeader count={departments?.length || 0} />}
    >
      <Row gutter={[24, 18]} >
        <Col span={userDetails?.activeOrg?.role === "admin" ? 24 : 10}>
          <Table
            title={() => (
              <Space
                align="center"
                style={{ width: "100%", justifyContent: "flex-end", gap: 16 }}
              >
                {userDetails?.activeOrg?.role == "admin" && (
                  <Button
                    id="create-department"
                    type="primary"
                    className={btnStyles.mdBtn}
                    onClick={() => {
                      setEditDept(null);
                      setModalVisible(true);
                    }}
                  >
                    + Add Department
                  </Button>
                )}
              </Space>
            )}
            scroll={{
              x: userDetails?.activeOrg?.role === "admin" ? 1200 :0,
              y: "60vh",
            }}
            locale={{
              emptyText: deptLoading ? (
                <Skeleton active />
              ) : (
                <Result
                  icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  title={<Typography>No departments found</Typography>}
                />
              ),
            }}
            pagination={false}
            bordered
            columns={tableColumns}
            dataSource={departments || []}
            loading={
              deptLoading
                ? {
                    spinning: false,
                    indicator: null,
                  }
                : false
            }
            components={tableComponents}
          />
        </Col>
      </Row>
      {modalVisible && (
        <Modal
          title={`${editDept?.id ? "Edit" : "Add"} department`}
          open={modalVisible}
          onCancel={() => {
            setModalVisible(false);
            form.resetFields();
            setEditDept(null);
          }}
          footer={null}
        >
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
          >
            <Form.Item
              name={"name"}
              label="Department name"
              style={{ marginBottom: "0px" }}
              rules={[
                {
                  whitespace: true,
                  message:
                    "Department name cannot be empty or contain only spaces",
                },
                { required: true, message: "Please enter department name" },
                {
                  max: 50,
                  message: "Department name cannot excced 50 characters",
                },
                {
                  min: 3,
                  message:
                    "Department name should contain atleast 3 characters",
                },
              ]}
            >
              <Input size="medium" placeholder="Enter department name" />
            </Form.Item>
            <Form.Item
              style={{ textAlign: "right", marginBottom: "0px", marginTop: 20 }}
            >
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </CustomLayout>
  );
};
export default Departments;
