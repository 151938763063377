import { useState } from "react";
import {
  Col,
  Row,
  Button,
  Input,
  Form,
  Divider,
  Radio,
  Typography,
  message,
  Modal,
  Space,
  Card,
} from "antd";

function DeleteAccount() {
  const [isOthers, setIsOthers] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDataSubmitted, setIsDataSubmitted] = useState(false);

  function onFinish(values) {
    if (values?.username === undefined && values?.mobile === undefined) {
      message.info("Email or Mobile number is required");
      return;
    }
    showModal();
  }

  function onValuesChange(value, allFields) {
    if (allFields.reasons === "Other") {
      setIsOthers(true);
    }
    if (allFields.reasons !== "Other") {
      setIsOthers(false);
    }
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsDataSubmitted(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsDataSubmitted(false);
  };

  return (
    <Row type="flex" justify="center" align="middle" className="login">
      <Col
        lg={{ span: 8, offset: 8 }}
        md={{ span: 12, offset: 6 }}
        sm={{ span: 20, offset: 2 }}
        xs={{ span: 20, offset: 2 }}
        style={{ marginLeft: 0, boxShadow: "0px 5px 5px rgb(0 0 0 / 25%)" }}
      >
        <Card bordered={false}>
          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <Typography.Title level={3}>Remove Account</Typography.Title>
          </div>
          <Form
            name="delete"
            onFinish={onFinish}
            layout="vertical"
            onValuesChange={onValuesChange}
          >
            <Form.Item
              label="Email"
              name="username"
              rules={[{ message: "Please input your email!" }]}
            >
              <Input type="email" />
            </Form.Item>
            <Divider orientation="middle">Or</Divider>
            <Form.Item
              label="Mobile"
              name="mobile"
              rules={[{ message: "Please input your mobile!" }]}
            >
              <Input
                addonBefore="+91"
                type="number"
                maxLength={10}
                minLength={10}
              />
            </Form.Item>
            <Form.Item
              label="Reason"
              name="reasons"
              rules={[
                {
                  required: true,
                  message: "Please choose your reasons!",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="No longer needed">No longer needed</Radio>
                <Radio value="Privacy concerns">Privacy concerns</Radio>
                <Radio value="Other">Others</Radio>
              </Radio.Group>
            </Form.Item>

            {isOthers && (
              <Form.Item
                name="other_reasons"
                label="Other Reasons"
                rules={[
                  {
                    required: true,
                    message: "Please input other reasons",
                  },
                ]}
              >
                <Input.TextArea showCount maxLength={100} />
              </Form.Item>
            )}

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Delete
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>

      {isModalOpen && (
        <Modal
          title={!isDataSubmitted && "Delete"}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={false}
        >
          {isDataSubmitted ? (
            <>
              {
                "Our data associate will contact you in 3 - 5 working days. If you haven't received any response from us, you can reach out to"
              }{" "}
              <a href="mailto:compliance@beyondscale.tech">
                compliance@beyondscale.tech
              </a>
            </>
          ) : (
            <Row>
              <Col span={24}>Are you sure to delete your account?</Col>
              <Col span={24} style={{ textAlign: "right", marginTop: "10px" }}>
                {!isDataSubmitted && (
                  <Space>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button type="danger" onClick={handleOk}>
                      Delete
                    </Button>
                  </Space>
                )}
              </Col>
            </Row>
          )}
        </Modal>
      )}
    </Row>
  );
}

export default DeleteAccount;
