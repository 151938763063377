import { Button, Flex, Form, Input, Typography, message, Card } from "antd";
import React, { useState } from "react";
import CustomLayout from "../../Layout";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import { changeUserPasswordApi } from "../../../services/users.services";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";

const ResetPassword = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    delete values?.confirm_new_password;
    try {
      const resp = await changeUserPasswordApi(values);
      if (resp) {
        message.success(resp?.data?.message);
        form.resetFields();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomLayout
      header={<Typography className={typoStyles.h6}>Reset Password</Typography>}
      footer={
        <Flex justify="flex-end" gap={12}>
          <Button
            className={btnStyles.mdBtn}
            onClick={() => {
              form.resetFields();
            }}
          >
            Cancel
          </Button>
          <Button
            className={btnStyles.mdBtn}
            type="primary"
            onClick={form.submit}
            loading={loading}
          >
            Update
          </Button>
        </Flex>
      }
    >
      <Card
        title="Reset Password"
        styles={{
          header: { backgroundColor: "#fff" },
        }}
        style={{ width: "40vw", margin: "auto" }}
      >
        <Form
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
          form={form}
        >
          <Form.Item
            label="Current password"
            name="oldPassword"
            rules={[
              {
                required: true,
                message: "Please input your current password",
              },
            ]}
          >
            <Input.Password
              placeholder="Enter current password"
              className={inputStyles.smInput}
            />
          </Form.Item>
          <Form.Item
            label="New password"
            name="newPassword"
            rules={[
              { required: true, message: "Please input your password!" },
              {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W]{8,}$/,
                message:
                  "Password must contain at least 1 letter, 1 number, and be at least 8 characters long.",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("oldPassword") !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "Your new password must be different than old password"
                  );
                },
              }),
            ]}
            r
          >
            <Input.Password
              placeholder="Enter new password"
              className={inputStyles.smInput}
            />
          </Form.Item>
          <Form.Item
            label="Confirm password"
            name="confirm_new_password"
            rules={[
              { required: true, message: "Please input your password!" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The new passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Re-enter your password"
              className={inputStyles.smInput}
            />
          </Form.Item>
        </Form>
      </Card>
    </CustomLayout>
  );
};

export default ResetPassword;
