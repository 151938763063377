import React, { useContext, useState } from "react";
import { Layout, Flex, Select } from "antd";
import "./Layout.css";
import SideMenu from "./SideMenu";
import UserSettings from "./UserSettings";
import Notifications from "./Notifications";
import { UserContext } from "../../context/UserProvider";
import inputStyles from "../../assets/styles/Custom/Input.module.css";
import { useLocation } from "react-router-dom";
import { setCookie } from "../../helpers/localStorage";
import { FACILITYID } from "../../constants/defaultKeys";
import OnboardingTour from "../Blocks/OnboardingTour";
const { Sider, Content, Header, Footer } = Layout;

const CustomLayout = ({ children, header, footer }) => {
  const { userDetails, setUserDetails } = useContext(UserContext);
  const location = useLocation();
  const enabledLocations = [
    "/dashboard",
    "/scheduler",
    "/mro",
    "/work-orders",
    "/maintenances",
    "/inspections",
    "/inventory",
    "/assets",
    "/production-lines",
    "/org/users",
    "/org/groups",
    "/org/departments",
  ];
  const [collapsed, setCollapsed] = useState(false);

  const isDisabled = !enabledLocations?.includes(location?.pathname);

  return (
    <Flex gap="middle" wrap="wrap">
      <Layout className="body">
        {header && (
          <Header
            className={
              collapsed
                ? "header headerWithSideAndCollapse"
                : "header headerWithSideAndNoCollapse"
            }
          >
            <Flex justify="space-between" align="center" gap={24}>
              <div style={{ width: "calc(85vw - 48px)" }}>{header}</div>
              <Flex justify="flex-end" align="center" gap={24}>
                <Notifications />
                {!isDisabled && userDetails?.facilities?.length > 0 && (
                  <Select
                    options={userDetails?.facilities || []}
                    defaultValue={
                      userDetails?.facilities &&
                      userDetails?.facilities.length > 0
                        ? userDetails.facilities[0].value
                        : null
                    }
                    placeholder="Location"
                    style={{
                      minWidth: "200px",
                      marginLeft: "12px",
                    }}
                    onChange={(e) => {
                      setUserDetails({ ...userDetails, currFacilty: e });
                      setCookie(
                        FACILITYID,
                        e,
                        `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
                        "/"
                      );
                      window.location.reload();
                    }}
                    className={inputStyles.smSelect}
                    value={
                      isDisabled
                        ? userDetails?.facilities &&
                          userDetails?.facilities.length > 0
                          ? userDetails.facilities[0].value
                          : null
                        : userDetails?.currFacilty
                    }
                  />
                )}
                <UserSettings />
              </Flex>
            </Flex>
          </Header>
        )}
        <Sider
          className={
            collapsed ? `sider siderCollapse` : `sider siderNoCollapse`
          }
          collapsed={collapsed}
          onCollapse={(e) => setCollapsed(e)}
          breakpoint="md"
          collapsible
          trigger={null}
        >
          <SideMenu collapsed={collapsed} setCollapsed={setCollapsed} />
        </Sider>
        <Layout>
          <Content
            className={
              footer?.props?.children
                ? collapsed
                  ? "contentWIthFooterCollapse"
                  : "contentWithFooterNoCollapse"
                : collapsed
                ? "contentWIthNoFooterCollapse"
                : "contentWithNoFooterNoCollapse"
            }
          >
            {children}
          </Content>
          <OnboardingTour />
          {footer?.props?.children && (
            <Footer
              className={
                collapsed
                  ? "footer footerStyleWithCollapsedSide"
                  : "footer footerStyleWithNOCollapsedSide"
              }
            >
              {footer}
            </Footer>
          )}
        </Layout>
      </Layout>
    </Flex>
  );
};
export default CustomLayout;
