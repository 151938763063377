import dayjs from "dayjs";
import {
  createAssetApi,
  createAssetDownTimeApi,
  getAssetByIdApi,
  updateAssetByIdApi,
  updateAssetDownTimeApi,
} from "../../../services/assets.services";
import { message } from "antd";
import { ONPREMISE } from "../../../constants/defaultKeys";
import { convertToUTCFormat, renameFile } from "../../../helpers/utility";

export const handleDynamicValidations = (changedValues, values, form) => {
  const validateFields = (fieldValues, requiredFields, prefix, errorPrefix) => {
    const newFields = [];

    const allFields = Object.keys(values[prefix] || {});
    const hasAnyFieldValue = allFields.some((field) => {
      let fieldVal = fieldValues[field] || values[prefix][field];
      if (Array.isArray(fieldVal) && fieldVal?.length === 0) {
        fieldVal = undefined;
      }
      return fieldValues && fieldVal;
    });

    if (hasAnyFieldValue) {
      requiredFields.forEach((field) => {
        const fieldValue = fieldValues[field] || values[prefix][field];
        newFields.push({
          name: [prefix, field],
          errors: !fieldValue
            ? [`${errorPrefix} ${field.replace("_", " ")} is required`]
            : [],
        });
      });
    } else {
      // Clear existing errors if all fields are empty
      requiredFields.forEach((field) => {
        newFields.push({
          name: [prefix, field],
          errors: [],
        });
      });
    }

    return newFields;
  };

  const warrantyFields = ["start_date", "end_date", "provider_company"];
  const maintenanceFields = [
    "provider_name",
    "provider_type",
    "end_date",
    "start_date",
  ];

  if (changedValues["warranty"]) {
    const newWarrantyFields = validateFields(
      changedValues["warranty"],
      warrantyFields,
      "warranty",
      "Warranty"
    );
    form.setFields(newWarrantyFields);
  } else if (changedValues["maintenance"]) {
    const newMaintenanceFields = validateFields(
      changedValues["maintenance"],
      maintenanceFields,
      "maintenance",
      "Maintenance"
    );
    form.setFields(newMaintenanceFields);
  }
};

export const getAsset = async (
  id,
  setAssetImage,
  setDocuments,
  documents,
  form,
  navigate,
  storageType,
  assetDownTimeID,
  setAssetDetails
) => {
  try {
    const resp = await getAssetByIdApi(id);

    let data = {
      ...resp?.data,
      location: resp?.data?.location?._id,
      groups: resp?.data?.groups?.map((i) => i?._id),
      end_date: dayjs(resp?.data?.end_date),
      start_date: dayjs(resp?.data?.start_date),
      year_of_installation: resp?.data?.year_of_installation
        ? dayjs(
            dayjs(`${resp?.data?.year_of_installation}-01-01`).format("YYYY")
          )
        : null,
      warranty:
        resp?.data?.warranty && Object.keys(resp?.data?.warranty).length > 0
          ? {
              ...resp?.data?.warranty,
              end_date: resp?.data?.warranty?.end_date
                ? dayjs(resp?.data?.warranty?.end_date)
                : null,
              start_date: resp?.data?.warranty?.start_date
                ? dayjs(resp?.data?.warranty?.start_date)
                : null,
            }
          : {},
      maintenance:
        resp?.data?.maintenance &&
        Object.keys(resp?.data?.maintenance).length > 0
          ? {
              ...resp?.data?.maintenance,
              end_date: resp?.data?.maintenance?.end_date
                ? dayjs(resp?.data?.maintenance?.end_date)
                : null,
              start_date: resp?.data?.maintenance?.start_date
                ? dayjs(resp?.data?.maintenance?.start_date)
                : null,
            }
          : {},
    };
    if (resp?.data) {
      setAssetDetails(resp?.data);
      setAssetImage(resp?.data?.file);
      setDocuments({
        ...documents,
        warranty: resp?.data?.warranty?.files,
        maintenance: resp?.data?.maintenance?.files,
        other_docs: resp?.data?.asset_docs?.other_docs,
        pid_drawings: resp?.data?.asset_docs?.pid_drawings,
        product_manuals: resp?.data?.asset_docs?.product_manuals,
        asset_3d_model: resp?.data?.asset_docs?.asset_3d_model,
      });
    }

    form.setFieldsValue(data);
  } catch (error) {
    navigate("/assets");
  }
};

const createDownTimeRecord = async (id, downTime, status) => {
  try {
    const resp = await createAssetDownTimeApi(id, {
      start_time: downTime,
      status: status,
    });
  } catch (error) {}
};

export const convertUTCTimestamptoISTTimestamp = (utcDateTime) => {
  // Create a new Date object from the UTC date-time string
  const date = new Date(utcDateTime);

  // Convert the UTC date to IST by adding the IST offset (5 hours and 30 minutes)
  const istOffset = 5 * 60 * 60 * 1000 + 30 * 60 * 1000; // Offset in milliseconds
  const istDate = new Date(date.getTime() + istOffset);

  // Format the IST date-time string
  const formattedISTDate = istDate.toISOString();

  return formattedISTDate;
};

export const createAsset = async (
  values,
  documents,
  id,
  setEdit,
  navigate,
  setAssetImage,
  setDocuments,
  form,
  storageType,
  assetDownTimeID,
  setRefresh,
  assetDetails
) => {
  const formatDate = (date) =>
    date ? dayjs(date).format("YYYY-MM-DD") : undefined;
  const formatYear = (date) => (date ? dayjs(date).format("YYYY") : null);
  const formatFiles = (docs) =>
    storageType == ONPREMISE
      ? docs
      : docs?.map((each) => (each ? renameFile(each) : ""));

  const isEmptyObject = (obj) =>
    Object.values(obj || {}).every(
      (value) =>
        value === undefined ||
        value === null ||
        (!values?.warranty?.provider_company && value === "")
    );

  const data = {
    ...values,
    year_of_installation: formatYear(values?.year_of_installation),
    file: values?.file
      ? storageType == ONPREMISE
        ? values?.file
        : renameFile(values?.file)
      : "",
    parts:
      values?.parts?.length > 0
        ? storageType === ONPREMISE
          ? values.parts
          : values?.parts?.map((each) => ({
              ...each,
              files: isEmptyObject(each?.files) ? [] : formatFiles(each?.files),
            }))
        : [],
    warranty: isEmptyObject(values?.warranty)
      ? {}
      : {
          ...values?.warranty,
          provider_company: values?.warranty?.provider_company || undefined,
          end_date: formatDate(values?.warranty?.end_date),
          start_date: formatDate(values?.warranty?.start_date),
          files: formatFiles(documents?.warranty),
        },
    maintenance: isEmptyObject(values?.maintenance)
      ? {}
      : {
          ...values?.maintenance,
          charge_dept: values?.maintenance?.charge_dept || undefined,
          end_date: formatDate(values?.maintenance?.end_date),
          start_date: formatDate(values?.maintenance?.start_date),
          ...(documents?.maintenance?.length > 0
            ? { files: formatFiles(documents?.maintenance) }
            : {}),
        },
    asset_docs: {
      other_docs: formatFiles(documents?.other_docs),
      pid_drawings: formatFiles(documents?.pid_drawings),
      asset_3d_model: formatFiles(documents?.asset_3d_model),
      product_manuals: formatFiles(documents?.product_manuals),
    },
  };

  !values?.year_of_installation && delete data?.year_of_installation;
  !values?.end_date && delete data?.end_date;
  !values?.start_date && delete data?.start_date;

  if (isEmptyObject(data?.warranty)) delete data?.warranty;
  if (isEmptyObject(data?.maintenance)) delete data?.maintenance;

  const updatedData = {
    ...data,
    parts:
      data?.parts?.length > 0
        ? data?.parts?.map(({ _id, ...part }) => ({
            ...part,
            status: _id ? "update" : "add",
            partId: _id,
          }))
        : [],
  };

  let downTimeData = updatedData?.downTime;
  let upTimeData = updatedData?.uptime;

  if (downTimeData) {
    delete updatedData?.downTime;
  }
  if (upTimeData) {
    delete updatedData?.uptime;
  }

  try {
    const resp = id
      ? await updateAssetByIdApi(id, updatedData)
      : await createAssetApi(updatedData);
    if (resp) {
      !id && navigate("/assets");

      message.success(resp?.data?.message);
      id && setEdit(false);

      if (updatedData?.status == "Online" || assetDetails?.status == "Online") {
        if (downTimeData) {
          createDownTimeRecord(
            id || resp?.data?._id,
            convertToUTCFormat(downTimeData),
            updatedData?.status
          );
        } else if (upTimeData) {
          updateAssetDownTimeApi(assetDownTimeID, {
            end_time: convertToUTCFormat(upTimeData),
            status: updatedData?.status,
          });
        }
      }
    }
  } catch (error) {
    message.error(error?.response?.data?.message || "Something went wrong!!");
  } finally {
    setRefresh((prev) => !prev);
  }
};
