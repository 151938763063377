import { AutoComplete, Input, Spin } from "antd";
import React, { useState } from "react";
import { debounce } from "../../helpers/utility";
import { SearchOutlined } from "@ant-design/icons";

function CustomSearch({
  placeholder,
  width = "100%",
  height = "auto",
  size = "medium",
  options,
  onSearch,
  onSelect,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [searchedVal, setSearchedVal] = useState(null);
  const handleSearch = (value) => {
    onSearch(value, setIsLoading)?.finally(() => {});
  };
  return (
    <AutoComplete
      style={{ width: width, height: height, marginTop: 2 }}
      options={options}
      onSelect={(val, option) => {
        onSelect(val, option);
        setSearchedVal(null);
        setIsLoading(false);
      }}
      onSearch={debounce(handleSearch, 300)}
      onChange={(value) => setSearchedVal(value)}
      value={searchedVal}
    >
      <Input.Search
        size={size}
        placeholder={placeholder}
        prefix={<SearchOutlined style={{ fontSize: 16, color: "#667085" }} />}
        suffix={
          <Spin
            size="small"
            style={{ visibility: isLoading ? "visible" : "hidden" }}
          />
        }
        className="custom-input-search"
      />
    </AutoComplete>
  );
}

export default CustomSearch;
