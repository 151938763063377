import { Button, Form, Input, Upload, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { createOrganisationApi } from "../../services/organisation.services";
import { useNavigate } from "react-router-dom";
import {
  ACCESS_TOKEN,
  ACTIVEDOMAIN,
  ORGANISATION,
} from "../../constants/defaultKeys";
import { getCookie, setCookie } from "../../helpers/localStorage";
import { UserContext } from "../../context/UserProvider";
import { redirectToDashboard } from "../../helpers/domainUtility";
import { UploadOutlined } from "@ant-design/icons";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import { uploadFunc } from "../../helpers/utility";

const CreateOrgForm = ({ current, changeDomain = false }) => {
  const naviagte = useNavigate();
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [fileObj, setFileObj] = useState(null);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const resp = await createOrganisationApi(values);
      if (resp) {
        setCookie(
          ORGANISATION,
          resp?.data?.org?._id,
          `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
          "/"
        );
        process.env.REACT_APP_STORAGE === "CLOUD" &&
          setCookie(
            ACTIVEDOMAIN,
            resp?.data?.org?.domain,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
        if (process.env.REACT_APP_STORAGE === "CLOUD" && changeDomain) {
          window.location.href = `https://${resp?.data?.org?.domain}.${process.env.REACT_APP_PUBLIC_DOMAIN}/dashboard`;
        } else {
          naviagte(`/signup/${parseInt(current) + 1}`);
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!getCookie(ACCESS_TOKEN)) {
      naviagte("/signup/0");
    } else if (userDetails?.organizations?.length > 0) {
      redirectToDashboard(
        userDetails?.organizations[0]?.organization?.domain,
        userDetails?.organizations[0]?.organization?.settings?.storage,
        naviagte
      );
    }
  }, []);

  const customOnChange = async (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      const resp = await uploadFunc(info, "on-premise", { type: "pem" });
      if (resp) {
        setFileObj({ name: info.file.name, key: resp?.path ?? null });
      }
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Form onFinish={onFinish} layout="vertical" requiredMark={false}>
      <Form.Item
        label="Organisation name"
        name="name"
        rules={[
          {
            required: true,
            message: "Enter organisation name",
          },
          {
            whitespace: true,
            message: "Organisation name cannot be empty or contain only spaces",
          },
          {
            min: 3,
            message: "Organisation name should contain atleast 3 characters",
          },
          {
            max: 50,
            message: "Organisation name cannot exceed 50 characters",
          },
        ]}
      >
        <Input placeholder="Enter organisation name" />
      </Form.Item>
      {process.env.REACT_APP_STORAGE === "CLOUD" && (
        <Form.Item
          label="Domain"
          name="domain"
          rules={[
            {
              required: true,
              message: "Please provide your domain",
            },
            {
              max: 50,
              message: "Domain name cannot exceed 50 characters",
            },
            {
              min: 2,
              message: "Domain name should be at least 2 characters long",
            },
            {
              message: "Please enter valid domian",
              pattern: new RegExp("^[a-zA-Z0-9_-]+$"),
            },
            {
              validator: (_, value) => {
                if (
                  value &&
                  process.env.REACT_APP_RESTRICTED_DOMAINS?.split(
                    ","
                  )?.includes(value.toLowerCase())
                ) {
                  return Promise.reject("Please choose a different domain");
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Input placeholder="Enter domain" />
        </Form.Item>
      )}
      {process.env.REACT_APP_STORAGE === "ON-PREMISE" && (
        <>
          <Form.Item
            label="Organisation Key"
            name="license_key"
            rules={[
              {
                required: true,
                message: "Please enter organisation key",
              },
            ]}
          >
            <Input placeholder="Enter key" />
          </Form.Item>
          <Form.Item
            label="Client Id"
            name="client_id"
            rules={[
              {
                required: true,
                message: "Please enter client id",
              },
            ]}
          >
            <Input placeholder="Enter client id" />
          </Form.Item>
          <Form.Item label=" " style={{ width: "100%" }}>
            <Upload
              accept={[".pem"]}
              maxCount={1}
              showUploadList={false}
              className="assetsUpload"
              onChange={(info) => customOnChange(info)}
              customRequest={dummyRequest}
            >
              <Button
                style={{
                  width: "100%",
                  display: "flex",
                }}
                className={btnStyles.mdBtn}
              >
                {"Upload .pem file"}
                <UploadOutlined />
              </Button>
            </Upload>
            {fileObj && (
              <div className="fileListItem">
                <span>
                  {fileObj?.name?.length > 40
                    ? `${fileObj?.name?.slice(0, 40)}...`
                    : fileObj?.name}
                </span>
              </div>
            )}
          </Form.Item>
        </>
      )}
      <Form.Item className="text-center">
        <Button loading={loading} type="primary" htmlType="submit">
          Create Organisation
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateOrgForm;
