import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, List, Row, Space, Tag, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SparePartForm from "./SparePartForm";
import { getInventoryList } from "../../services/inventory.services";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import { UserContext } from "../../context/UserProvider";
import { formatNumber } from "../../helpers/utility";

const SparePartSection = ({
  form,
  edit,
  productionLines,
  plId,
  inventoryRequestData,
  orderId,
  type,
  refreshCall,
  verifyStatus,
  taskStatus,
}) => {
  const [inventoryList, setInventoryList] = useState([]);
  const [selectedPlAssets, setSelectedPlAssets] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [currEntryId, setCurrEntryId] = useState(null);
  const { userDetails } = useContext(UserContext);

  let filteredInventoryRequestData = [];
  if (userDetails?.activeOrg?.organization?.settings?.inventory_module) {
    filteredInventoryRequestData = inventoryRequestData?.requested?.filter(
      (item) => item?.inventory?._id
    );
  } else if (
    userDetails?.activeOrg?.organization?.settings?.track_spare_parts
  ) {
    filteredInventoryRequestData = inventoryRequestData?.requested?.filter(
      (item) => item?.inventory_text
    );
  }

  const fetchInventoryList = async () => {
    try {
      const res = await getInventoryList();
      setInventoryList(res?.data?.inventories);
    } catch (error) {
      setInventoryList([]);
    }
  };

  useEffect(() => {
    fetchInventoryList();
  }, []);

  useEffect(() => {
    if (plId) {
      let plObject = productionLines?.filter((i) => i?.value === plId)?.[0];
      setSelectedPlAssets(
        plObject?.assets?.map((p) => ({
          label: p?.asset?.name,
          value: p?.asset?._id,
        }))
      );
    } else {
      setSelectedPlAssets([]);
    }
  }, [plId, productionLines]);

  const cancelFunc = () => {
    setCurrEntryId(null);
    setShowForm(false);
  };

  const displayRowContent = (item) => {
    return (
      <Row gutter={[16, 0]} style={{ width: "100%" }}>
        {plId && <Col span={7}>{item?.asset?.name}</Col>}
        {userDetails?.activeOrg?.organization?.settings?.inventory_module ? (
          <>
            <Col span={7}>
              <Space style={{ gap: 8 }}>
                <Typography>{item?.inventory?.name}</Typography>
                <Tag
                  className={tagStyles.smRndTag}
                >{`Cost: ${item?.inventory?.cost}`}</Tag>
              </Space>
            </Col>
            <Col span={4}>{item?.requested_quantity}</Col>
            <Col span={3}>
              {`₹ ${formatNumber(
                Number(
                  (
                    Number(item?.requested_quantity || 0) *
                    Number(item?.inventory?.cost || 0)
                  ).toFixed(2)
                )
              )}`}
            </Col>
          </>
        ) : userDetails?.activeOrg?.organization?.settings
            ?.track_spare_parts ? (
          <Col span={14}>{item?.inventory_text}</Col>
        ) : (
          ""
        )}
        {/* <Col span={3}>
          <Space style={{ width: "100%", gap: 12 }}>
            {!verifyStatus && (
              <EditOutlined
                style={{ fontSize: 16, cursor: "pointer" }}
                onClick={() => {
                  setCurrEntryId(item?._id);
                  setShowForm(false);
                }}
              />
            )}
          </Space>
        </Col> */}
      </Row>
    );
  };
  return (
    <Card
      title="Spare Parts Used"
      styles={{ header: { backgroundColor: "#fff" } }}
    >
      <>
        {filteredInventoryRequestData?.length > 0 && (
          <List
            bordered
            loading={false}
            dataSource={filteredInventoryRequestData}
            header={
              <Row gutter={[16, 0]} style={{ width: "100%" }}>
                {plId && <Col span={7}>Asset</Col>}
                {userDetails?.activeOrg?.organization?.settings
                  ?.inventory_module ? (
                  <>
                    <Col span={7}>Spare part</Col>
                    <Col span={4}>Quantity</Col>
                    <Col span={3}>Total cost</Col>
                  </>
                ) : userDetails?.activeOrg?.organization?.settings
                    ?.track_spare_parts ? (
                  <Col span={14}>Spare part name</Col>
                ) : (
                  ""
                )}
              </Row>
            }
            renderItem={(item) => (
              <List.Item>
                {item?._id == currEntryId ? (
                  <SparePartForm
                    form={form}
                    inventoryList={inventoryList}
                    plId={plId}
                    selectedPlAssets={selectedPlAssets}
                    cancelFunc={cancelFunc}
                    editDetails={{
                      ...item,
                      inventory_request_id: inventoryRequestData?._id,
                    }}
                    orderId={orderId}
                    type={type}
                    refreshCall={refreshCall}
                  />
                ) : (
                  displayRowContent(item)
                )}
              </List.Item>
            )}
          />
        )}
        {taskStatus === "closed" &&
          (!filteredInventoryRequestData ||
            filteredInventoryRequestData?.length === 0) && (
            <Typography>No spare parts used</Typography>
          )}
        {taskStatus !== "closed" && (
          <>
            {showForm ? (
              <SparePartForm
                form={form}
                inventoryList={inventoryList}
                plId={plId}
                selectedPlAssets={selectedPlAssets}
                cancelFunc={cancelFunc}
                editDetails={null}
                orderId={orderId}
                type={type}
                refreshCall={refreshCall}
              />
            ) : (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                disabled={verifyStatus}
                style={{ marginTop: 16 }}
                onClick={() => {
                  setCurrEntryId(null);
                  setShowForm(true);
                }}
              >
                Add spare part
              </Button>
            )}
          </>
        )}
      </>
    </Card>
  );
};

export default SparePartSection;
