import {
  Breadcrumb,
  Button,
  Card,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Image,
  Input,
  Row,
  Select,
  Switch,
  TreeSelect,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CloudUploadOutlined,
  DeleteOutlined,
  EyeOutlined,
  PrinterOutlined,
  ScanOutlined,
} from "@ant-design/icons";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import {
  deleteDocs,
  getAssetsPath,
  statusOptions,
  uploadFunc,
} from "../../../helpers/utility";
import { useParams } from "react-router-dom";
import "../Assets.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import {
  getAllAssetsWithLocsApi,
  getAssetCategories,
  getAssetLocationByIDApi,
  getQrCodeforAssetApi,
  updateAssetByIdApi,
  getAllAssetsApi,
} from "../../../services/assets.services";
import { UserContext } from "../../../context/UserProvider";
import dayjs from "dayjs";
import Compressor from "compressorjs";

const { Dragger } = Upload;

const GeneralDetails = ({
  form,
  assetImage,
  setAssetImage,
  edit,
  assetDetails,
  setRefresh,
}) => {
  const [locations, setLocations] = useState([]);
  const params = useParams();
  const { userDetails } = useContext(UserContext);
  const [status, setStatus] = useState("");
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const [loading, setLoading] = useState(false);
  const [assetCategories, setAssetCategories] = useState([]);
  const [assetLocation, setAssetLocation] = useState([]);
  const [subAssets, setSubAssets] = useState([]);

  const props = {
    name: "file",
    mutilple: false,
    beforeUpload(file) {
      return new Promise((resolve) => {
        new Compressor(file, {
          convertSize: 1000000,
          quality: 0.8, // Adjust quality between 0 and 1
          success(compressedFile) {
            resolve(compressedFile); // Resolve with the compressed file
          },
          error(err) {
            console.error("Compression failed:", err.message);
          },
        });
      });
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        uploadImage(info);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    setStatus("");
  }, [edit]);

  const uploadImage = async (info) => {
    const resp = await uploadFunc(info, storageType);
    if (resp) {
      await form.setFieldsValue({ file: resp?.path });
      setAssetImage(resp?.path);
    }
  };

  const handleStatusChange = (value) => {
    setStatus(value);
    if (!params?.id) {
      if (value === "Online") {
        form.setFieldValue("downTime", undefined);
      } else {
        form.setFieldValue("downTime", dayjs());
      }
    }
  };

  const getLocations = async () => {
    try {
      const resp = await getAllAssetsWithLocsApi();
      setLocations(transformData(resp?.data?.locations?.[0]));
    } catch (error) {}
  };

  function transformData(data) {
    function transformNode(node) {
      const transformedChildren = node.children.map(transformNode);
      return {
        value: node._id,
        title: node.name,
        disabled: node?.disabled,
        children: transformedChildren.length ? transformedChildren : undefined,
      };
    }

    return transformNode(data);
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const fetchAssetCategories = async () => {
    try {
      const res = await getAssetCategories();
      setAssetCategories(
        res?.data?.map((item) => ({
          label: item?.name,
          value: item?._id,
        }))
      );
    } catch (error) {}
  };

  const getAssetLocation = async (id) => {
    try {
      const resp = await getAssetLocationByIDApi(id);
      if (resp?.data) {
        setAssetLocation(resp?.data?.map((each) => ({ title: each?.name })));
      }
    } catch (error) {}
  };

  // const fetchSubAssets = async () => {
  //   try {
  //     const res = await getAllAssetsApi();

  //     setSubAssets(
  //       res?.data?.assets
  //         ?.filter((item) => {
  //           return params?.id !== item?._id;
  //         })
  //         .map((item) => ({
  //           label: item?.name,
  //           value: item?._id,
  //         }))
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    getLocations();
    if (params?.id) {
      fetchAssetCategories();
      // fetchSubAssets();
    }
  }, []);

  useEffect(() => {
    params?.id && getAssetLocation(params?.id);
  }, [assetDetails]);

  const updateAsset = async (data) => {
    try {
      const resp = await updateAssetByIdApi(params?.id, data);
      if (resp) {
        deleteDocs(form.getFieldValue("file"), storageType);
      }
      setAssetImage("");
      form.setFieldsValue({ file: "" });
    } catch (error) {}
  };

  const disabledDate = (current) => {
    const now = dayjs();
  
    if (status === "Online") {
      return current && current < now.startOf("day");
    } else {
      const assetCreationDate = assetDetails?.created_at
        ? dayjs(assetDetails?.created_at)
        : null;
  
      if (!assetCreationDate) {
        return current && !current.isSame(now, "minute");
      }
  
      return current && (current > now || current < assetCreationDate);
    }
  };
  

  const generateQrcode = async () => {
    try {
      setLoading(true);
      const resp = await getQrCodeforAssetApi(params?.id);
      setRefresh((prev) => !prev);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const CustomToolbar = ({ src }) => {
    const handlePrint = () => {
      const printWindow = window.open("", "_blank");

      const printDocument = `
        <html>
          <head>
            <title>${assetDetails?.name}</title>
          </head>
          <body style="margin: 0; display: flex; justify-content: center; align-items: center; height: 100vh;">
            <img src="${src}" style="max-width: 100%; max-height: 100%; object-fit: contain;" />
            <script>
              window.onload = function() {
                window.print();
              };
              window.onafterprint = function() {
                window.close();
              };
            </script>
          </body>
        </html>
      `;

      printWindow.document.write(printDocument);
      printWindow.document.close();
    };

    return (
      <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
        <EyeOutlined style={{ fontSize: "24px", color: "#fff" }} />
        <PrinterOutlined
          style={{ fontSize: "24px", color: "#fff" }}
          onClick={handlePrint}
        />
      </div>
    );
  };
  return (
    <Card
      title="Asset Tracking Information"
      styles={{
        header: { backgroundColor: "#fff" },
      }}
    >
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <Form.Item
            label="Asset name"
            name={"name"}
            rules={[
              {
                whitespace: true,
                message: "Asset name cannot be empty or contain only spaces",
              },
              { required: true, message: "Please enter asset name" },
            ]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter title"
            />
          </Form.Item>
          <Form.Item
            name={"location"}
            label="Location"
            rules={[{ required: true, message: "Please enter location" }]}
          >
            {(!params?.id || (params?.id && edit)) && (
              <TreeSelect
                placeholder="Location"
                style={{
                  width: "100%",
                }}
                showSearch
                className={inputStyles.smSelect}
                treeData={Object.keys(locations)?.length > 0 && [locations]}
              />
            )}
            {params?.id && !edit && assetLocation?.length > 0 && (
              <div
                style={{
                  borderRadius: "8px",
                  border: "1px solid #d0d5dd",
                  padding: "4px 12px",
                }}
              >
                <ConfigProvider
                  theme={{
                    components: {
                      Breadcrumb: {
                        itemColor: "#2f3542",
                        lastItemColor: "#2f3542",
                      },
                    },
                  }}
                >
                  <Breadcrumb separator=">" items={assetLocation} />
                </ConfigProvider>
              </div>
            )}
          </Form.Item>
          <div className="flex-start">
            <div style={{ width: "150%" }}>
              <div style={{ display: "flex", width: "100%" }}>
                <Form.Item
                  rules={[
                    {
                      whitespace: true,
                      message:
                        "Asset code cannot be empty or contain only spaces",
                    },
                    { required: true, message: "Please enter asset code" },
                  ]}
                  label="Code"
                  name="code"
                  style={{ width: "60%" }}
                >
                  <Input
                    className={inputStyles.smInput}
                    style={{
                      width: "100%",
                      borderEndEndRadius: "0px",
                      borderStartEndRadius: "0px",
                    }}
                    placeholder="Enter code"
                  />
                </Form.Item>
                <Form.Item label=" " style={{ width: "40%" }}>
                  <Button
                    onClick={() =>
                      form.setFieldsValue({
                        code: Math.floor(
                          1000 + Math.random() * 9000
                        ).toString(),
                      })
                    }
                    type="primary"
                    style={{
                      borderLeft: "0px",
                      borderEndStartRadius: "0px",
                      borderStartStartRadius: "0px",
                      height: "34px",
                      width: "100%",
                    }}
                    className={btnStyles.mdBtn}
                  >
                    Generate
                  </Button>
                </Form.Item>
              </div>

              <Form.Item
                name={"status"}
                label="Asset status"
                rules={[{ required: true, message: "Please enter status" }]}
              >
                <Select
                  placeholder="Asset status"
                  style={{
                    width: "100%",
                  }}
                  className={inputStyles.smSelect}
                  showSearch
                  onChange={handleStatusChange}
                  options={statusOptions}
                />
              </Form.Item>

              {status &&
                params?.id &&
                status == "Online" &&
                status !== assetDetails?.status && (
                  <Form.Item
                    name="uptime"
                    label="Uptime"
                    rules={[
                      { required: true, message: "Please select asset uptime" },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select asset uptime"
                      style={{ width: "100%" }}
                      showTime
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                )}
              {status &&
                params?.id &&
                status != "Online" &&
                status !== assetDetails?.status && (
                  <Form.Item
                    name="downTime"
                    label="Down time"
                    rules={[
                      {
                        required: true,
                        message: "Please slect asset downtime",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select asset downtime"
                      disabledDate={disabledDate}
                      style={{ width: "100%" }}
                      showTime
                    />
                  </Form.Item>
                )}
            </div>

            {params?.id && (
              <div
                className="flex-start"
                style={{
                  width: "100%",
                  alignSelf: "flex-start",
                }}
              >
                {assetDetails.qrcode ? (
                  <Image
                    src={getAssetsPath(assetDetails.qrcode, storageType)}
                    style={{ width: "90%", height: "90%" }}
                    preview={{
                      mask: <CustomToolbar src={assetDetails.qrcode} />,
                    }}
                  />
                ) : (
                  <Form.Item
                    style={{
                      maxWidth: "35%",
                      marginBottom: "0px",
                      marginTop: "18px",
                    }}
                  >
                    <Button
                      onClick={generateQrcode}
                      type="primary"
                      className={btnStyles.smBtn}
                      loading={loading}
                    >
                      <ScanOutlined />
                      {assetDetails.qrcode
                        ? loading
                          ? "Regenerating..."
                          : "Regenerate"
                        : loading
                        ? "Generating..."
                        : "Generate"}{" "}
                      QR
                    </Button>
                  </Form.Item>
                )}
              </div>
            )}
          </div>
          {params?.id && (
            <Form.Item label="Asset Categories" name={"groups"}>
              <Select
                className={inputStyles.smSelect}
                allowClear
                placeholder={"Select asset categories"}
                mode="multiple"
                options={assetCategories}
                style={{ width: "100%" }}
                filterOption={(inputValue, option) =>
                  option.label
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          )}
          {/* {params?.id && (
            <Form.Item label="Sub assets" name={"sub_assets"}>
              <Select
                className={inputStyles.smSelect}
                allowClear
                placeholder={"Select subassets"}
                mode="multiple"
                options={subAssets}
                style={{ width: "100%" }}
                filterOption={(inputValue, option) =>
                  option.label
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          )} */}
        </Col>

        <Col span={12}>
          <Form.Item name={"file"} label="">
            <div style={{ textAlign: "center" }}>
              {assetImage ? (
                <div
                  style={{ position: "relative" }}
                  className="assetImageParent"
                >
                  {edit && (
                    <>
                      <div className="assetImageMask"></div>
                      <DeleteOutlined
                        className="assetDeleteIcon"
                        onClick={(e) => {
                          if (params?.id) {
                            updateAsset({
                              file: "",
                              status: form.getFieldValue("status"),
                            });
                          } else {
                            setAssetImage("");
                          }
                        }}
                      />{" "}
                    </>
                  )}
                  <Image
                    alt="asset image"
                    preview={true}
                    style={{
                      width: "100%",
                      height: "300px",
                    }}
                    src={getAssetsPath(assetImage, storageType)}
                  />
                </div>
              ) : (
                <Dragger
                  customRequest={dummyRequest}
                  {...props}
                  accept="image/*"
                  maxCount={1}
                  style={{
                    backgroundColor: "#fff",
                    border: "1px solid #EAECF0",
                  }}
                >
                  <CloudUploadOutlined style={{ fontSize: 40 }} />
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Asset Images
                    <br />
                    JPG (max. 800x400px)
                  </p>
                </Dragger>
              )}
            </div>
          </Form.Item>

          <div className="flex-start" style={{ marginLeft: 10 }}>
            <ConfigProvider
              theme={{
                token: { colorPrimary: "#87d068" },
              }}
            >
              <Form.Item name={"is_iot_enabled"} style={{ marginBlock: "0px" }}>
                <Switch />
              </Form.Item>
            </ConfigProvider>
            <Typography>Connected to Bolt IoT</Typography>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default GeneralDetails;
