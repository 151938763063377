export const customCentralTheme = {
  token: {
    fontFamily: "Inter",
    colorPrimary: "#7369F4",
    colorText: "#2f3542",
  },
  components: {
    Menu: {
      itemSelectedBg: "#2f3542",
      itemSelectedColor: "#fff",
    },
    Form: {
      colorError: "#F04438",
      labelFontSize: 12,
      fontSize: 14,
      lineHeight: "20px",
    },
    Tag: {
      defaultBg: "#F9FAFB",
      defaultColor: "#344054",
      colorBorder: "#EAECF0",
    },
    Table: {
      cellPaddingBlock: 8,
      headerBg: "#f5f8ff",
      headerSortActiveBg: "#f5f8ff",
      headerSortHoverBg: "#f5f8ff",
    },
    Radio: {
      buttonColor: "red",
      buttonSolidCheckedBg: "red",
    },
  },
};
