import { Col, Button, Row, Typography } from "antd";
import React from "react";
import btnStyles from "../../../../assets/styles/Custom/Button.module.css";
import "../../SOP.css";
import TextElem from "../AddElem/Questionnaire/TextElem";
import { EditOutlined } from "@ant-design/icons";

function ViewText({
  textElem,
  disabled,
  sopContent,
  setSopContent,
  editing,
  setEditing,
  setIsUnfilled,
  viewType = "edit",
}) {
  return (
    <>
      {editing?.data?.id !== textElem.id ? (
        <Row
          className={"viewSOPElemCard"}
          key={textElem.id}
          style={
            viewType === "edit"
              ? {
                  borderRadius: "16px",
                  boxShadow: " 0px 1px 4px 0px rgba(0, 0, 0, 0.16)",
                }
              : {}
          }
        >
          <Col span={24} className={"viewQuestionnaireContent"}>
            <Typography>{textElem?.text}</Typography>
          </Col>
          {viewType === "edit" && (
            <Col span={24} className={"viewQuestionnaireEditBtn"}>
              <Button
                className={btnStyles.mdBtn}
                type="primary"
                disabled={editing.edit}
                onClick={(e) => setEditing({ edit: true, data: textElem })}
              >
                <span>Edit</span>
                <EditOutlined />
              </Button>
            </Col>
          )}
        </Row>
      ) : (
        <TextElem
          sopContent={sopContent}
          setSopContent={setSopContent}
          questionnaire={textElem}
          type={"text"}
          editing={editing}
          setEditing={setEditing}
          setIsUnfilled={setIsUnfilled}
        />
      )}
    </>
  );
}

export default ViewText;
