import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../../Layout";
import OrgSettingsHeader from "../OrgSettingsHeader";
import { Button, Card, Col, Empty, Row, Skeleton, Typography } from "antd";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import { useNavigate } from "react-router-dom";
import { getWorkflowsApi } from "../../../services/workflow.services";
import { UserContext } from "../../../context/UserProvider";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import "../Organisation.css";

const Workflows = () => {
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchWorkflows = async () => {
    setLoading(true);
    try {
      const res = await getWorkflowsApi();
      setWorkflows(res?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkflows();
  }, []);

  const displayWorkflowCard = (item) => {
    return (
      <Col span={6} style={{ display: "flex" }}>
        <Card
          style={{
            width: "100%",
            cursor: "pointer",
            marginBottom: 16,
          }}
          hoverable
          onClick={() => navigate(`/org/workflows/${item?._id}`)}
        >
          <Typography className={typoStyles.subtext2}>{item?.name}</Typography>
          <Typography className={typoStyles.body2} style={{ marginTop: 12 }}>
            {item?.location?.name}
          </Typography>
        </Card>
      </Col>
    );
  };

  return (
    <CustomLayout header={<OrgSettingsHeader count={workflows?.length || 0} />}>
      <Row gutter={[24, 16]}>
        {userDetails?.activeOrg?.role === "admin" && (
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              id="create-workflow"
              type="primary"
              className={btnStyles.mdBtn}
              onClick={() => navigate("/org/workflows/create")}
            >
              + Create Workflow
            </Button>
          </Col>
        )}
        <Col span={24}>
          {loading ? (
            <Skeleton active />
          ) : workflows?.length > 0 ? (
            <Row
              gutter={[16, 0]}
              style={{ maxHeight: "72vh", overflowY: "auto" }}
            >
              {workflows?.map((item) => displayWorkflowCard(item))}
            </Row>
          ) : (
            <div className="flex-center" style={{ minHeight: "50vh" }}>
              <Empty description="No Workflows found" />
            </div>
          )}
        </Col>
      </Row>
    </CustomLayout>
  );
};

export default Workflows;
