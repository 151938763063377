import axiosInstance from "../config/axios/axios.config";

export const createWorkOrderApi = (payload) => {
  return axiosInstance.post("/workorder", payload);
};
export const getWorkordersApi = (query) => {
  return axiosInstance.get(`/workorder/all`, { params: query });
};
export const getWorkorderByIDApi = (id) => {
  return axiosInstance.get(`/workorder/${id}`);
};
export const updateWorkOrderApi = (id, payload) => {
  return axiosInstance.put(`/workorder/${id}`, payload);
};
export const deleteWorkOrderApi = (id) => {
  return axiosInstance.delete(`/workorder/${id}`);
};

export const createCommentApi = (workorderId, payload, key) => {
  return axiosInstance.post(`/comment/${workorderId}?type=${key}`, payload);
};
export const getCommentsApi = (workorderId, key) => {
  return axiosInstance.get(`/comment/${workorderId}?type=${key}`);
};
export const deleteCommentByIDApi = (id, key) => {
  return axiosInstance.delete(`/comment/${id}?type=${key}`);
};
export const updateCommentApi = (commentId, payload) => {
  return axiosInstance.put(`/comment/${commentId}`, payload);
};

export const getMroList = (query) => {
  return axiosInstance.get(`/mro/`, { params: query });
};
export const getMroByIDApi = (id) => {
  return axiosInstance.get(`mro/${id}`);
};
export const createMroApi = (payload) => {
  return axiosInstance.post(`/mro`, payload);
};
export const updateMroApi = (id, payload) => {
  return axiosInstance.patch(`/mro/${id}`, payload);
};
export const deleteMroApi = (id) => {
  return axiosInstance.delete(`/mro/${id}`);
};

export const getMroBreakdownReportApi = (query) => {
  return axiosInstance.get(
    `/mro/download-mros?start_date=${query.start_date}&end_date=${query?.end_date}
 `,
    {
      responseType: "blob",
    }
  );
};
