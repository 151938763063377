import { Button, Col, Row, message } from "antd";
import React, { useState, useRef } from "react";
import { SaveOutlined } from "@ant-design/icons";
import MCQ from "./MCQ";
import SAQ from "./SAQ";
import btnStyles from "../../../../../assets/styles/Custom/Button.module.css";
import "../../../SOP.css";

function Questionnaire(
  {
    contentType,
    sopContent,
    setSopContent,
    editing,
    setEditing,
    // questionnaire = {},
    type = "mcq",
    isUnfilled,
    setIsUnfilled,
  },
  ref
) {
  const [questionnaireType, setQuestionnaireType] = useState(type || "mcq");
  const defaultChoices = editing.data?.choices
    ? editing.data?.choices?.reduce((obj, item) => {
        obj[item] = item;
        return obj;
      }, {})
    : {
        option1: "Yes",
        option2: "No",
      };
  const defaultMCQChoices = editing.data?.choices
    ? editing.data?.choices?.reduce((obj, item) => {
        obj[item] = item;
        return obj;
      }, {})
    : {
        option1: "Item 1",
      };
  const [questionnaire, setQuestionnaire] = useState({
    question: editing.data?.question || "",
    ansDesc: editing.data?.ansDesc || "",
  });
  const [choices, setChoices] = useState(defaultChoices);
  const [mcqChoices, setMcqChoices] = useState(defaultMCQChoices);
  const [isAnsSelected, setAnsSelected] = useState(
    editing.data?.answers?.length > 0
  );
  const formRef = useRef(null);

  return (
    <Row ref={ref}>
      <Col span={24}>
        <Row
          className={"viewSOPElemCard"}
          style={{
            borderRadius: "8px",
          }}
        >
          <Col span={24} className={"viewQuestionnaireContent"}>
            {questionnaireType == "mcq" ? (
              <MCQ
                mcq={editing.data || {}}
                choices={mcqChoices}
                isAnsSelected={isAnsSelected}
                questionnaire={questionnaire}
                setQuestionnaire={setQuestionnaire}
                setAnsSelected={setAnsSelected}
                setChoices={setMcqChoices}
                formRef={formRef}
                sopContent={sopContent}
                setSopContent={setSopContent}
              />
            ) : (
              <SAQ
                saq={editing.data || {}}
                questionnaire={questionnaire}
                setQuestionnaire={setQuestionnaire}
                choices={choices}
                isAnsSelected={isAnsSelected}
                setAnsSelected={setAnsSelected}
                setChoices={setChoices}
                formRef={formRef}
                sopContent={sopContent}
                setSopContent={setSopContent}
              />
            )}
          </Col>
          <Col span={24} className={"viewQuestionnaireEditBtn"}>
            <div
              key={"1"}
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: `${editing.edit ? "0" : "24px"}`,
                gap: "15px",
              }}
            >
              {editing.edit && (
                <Button
                  className={btnStyles.mdBtn}
                  onClick={() => {
                    const updatedTyutElems = sopContent.map((eachTyut) => {
                      if (eachTyut.id === editing.data.id) delete eachTyut.edit;
                      return eachTyut;
                    });
                    setSopContent([...updatedTyutElems]);
                    setEditing({ edit: false, data: null });
                  }}
                >
                  Cancel
                </Button>
              )}

              <Button
                className={btnStyles.mdBtn}
                type="primary"
                disabled={!formRef.current?.getFieldsValue().question}
                onClick={() => {
                  if (formRef.current === null) {
                    message.error(
                      "Please complete the questionnaire to submit"
                    );
                  } else {
                    formRef.current?.submit();
                    const isFormFilled = Object.values(
                      formRef.current?.getFieldsValue() || {}
                    ).every((eachVal) => {
                      if (!eachVal) {
                        setEditing({ edit: false, data: null });
                        return false;
                      }
                      return true;
                    });
                    if (isFormFilled) {
                      setEditing({ edit: false, data: null });
                    }
                  }
                  setIsUnfilled("#406aff");
                }}
              >
                Save
                <SaveOutlined />
              </Button>
            </div>
          </Col>
        </Row>
        <div className={"activeCardTag"} style={{ background: isUnfilled }} />
      </Col>
    </Row>
  );
}

export default React.forwardRef(Questionnaire);
