import {
  Button,
  Col,
  Empty,
  Input,
  Pagination,
  Popconfirm,
  Result,
  Row,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { deleteGroupApi, getGroupsApi } from "../../services/users.services";
import {
  PAGESIZE,
  addGroups,
  deleteUsers,
  editUsers,
} from "../../constants/defaultKeys";
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { UserContext } from "../../context/UserProvider";
import { debounce, tableComponents } from "../../helpers/utility";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";

const GroupsTable = ({ setCurrGroup, setOpen, currGroup, setGroupsCount }) => {
  const [groups, setGroups] = useState(null);
  const { userDetails } = useContext(UserContext);
  const [refresh, setRefresh] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const fetchData = async () => {
    let query = { page: pageNumber };
    if (searchValue) {
      query = { ...query, name: searchValue };
    }

    setLoading(true);
    try {
      const res = await getGroupsApi(query);
      setGroups(res?.data);
      setGroupsCount(res?.data?.totalGroups);
    } catch (error) {
      console.error("Error while fetching data:", error);
      setGroups(null);
      setGroupsCount(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, refresh]);

  useEffect(() => {
    if (searchValue?.trim()?.length === 0 || searchValue?.trim()?.length > 1) {
      refreshPage("search");
    }
  }, [searchValue]);

  useEffect(() => {
    if (!Object.keys(currGroup)?.length > 0) {
      refreshPage();
    }
  }, [currGroup]);

  const handleSearch = (e) => {
    setSearchValue(e?.target?.value);
  };

  const refreshPage = (source = "") => {
    if (source === "search" && pageNumber > 1) {
      setPageNumber(1);
    } else if (
      source === "delete" &&
      groups?.groups?.length === 1 &&
      pageNumber > 1
    ) {
      setPageNumber((prev) => prev - 1);
    } else {
      setRefresh((prev) => !prev);
    }
  };

  const deleteGroup = async (id) => {
    try {
      const resp = await deleteGroupApi(id);
      if (resp) {
        message.success(resp?.data?.message);
        refreshPage("delete");
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const columns = [
    {
      title: "Team name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (val) =>
        val ? (
          <Tag
            className={tagStyles.smBtn}
            color={val === "Production" ? "magenta" : "geekblue"}
          >
            {val}
          </Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "Facility",
      dataIndex: "facility",
      key: "facility",
      render: (value) => (value?.name ? value?.name : "-"),
    },
    {
      title: "No. of users",
      dataIndex: "users_count",
      key: "users_count",
      render: (_, record) => record?.members?.length,
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      width: 180,
      render: (_, record) => {
        return (
          <Space size="middle">
            {deleteUsers.includes(userDetails?.activeOrg?.role) && (
              <Popconfirm
                icon={null}
                description="Are you sure to delete this team?"
                onConfirm={(e) => {
                  e.stopPropagation();
                  deleteGroup(record?._id);
                }}
                onCancel={(e) => e.stopPropagation()}
              >
                <DeleteOutlined />
              </Popconfirm>
            )}
            {editUsers.includes(userDetails?.activeOrg?.role) && (
              <EditOutlined
                onClick={() => {
                  setCurrGroup(record);
                  setOpen(true);
                }}
              />
            )}
          </Space>
        );
      },
      hidden: !(
        deleteUsers.includes(userDetails?.activeOrg?.role) ||
        editUsers.includes(userDetails?.activeOrg?.role)
      ),
    },
  ];
  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Table
          title={() => (
            <Space
              align="center"
              style={{ width: "100%", justifyContent: "flex-end", gap: 16 }}
            >
              <Input.Search
                size="large"
                onChange={debounce(handleSearch, 500)}
                placeholder="Search by team name"
                prefix={
                  <SearchOutlined style={{ fontSize: 16, color: "#667085" }} />
                }
                style={{ width: "300px" }}
                className="custom-input-search"
              />
              {addGroups.includes(userDetails?.activeOrg?.role) && (
                <Button
                  id="create-team"
                  type="primary"
                  className={btnStyles.mdBtn}
                  onClick={() => setOpen(true)}
                >
                  + Create Team
                </Button>
              )}
            </Space>
          )}
          scroll={{
            x: 1200,
            y: "60vh",
          }}
          pagination={false}
          locale={{
            emptyText: loading ? (
              <Skeleton active />
            ) : (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={<Typography>No teams found</Typography>}
              />
            ),
          }}
          loading={
            loading
              ? {
                  spinning: false,
                  indicator: null,
                }
              : false
          }
          bordered
          columns={columns}
          dataSource={groups?.groups}
          components={tableComponents}
        />
      </Col>
      {groups?.totalGroups > PAGESIZE && (
        <Col span={24} className="text-center">
          <Pagination
            current={pageNumber}
            pageSize={PAGESIZE}
            total={groups?.totalGroups}
            onChange={(e) => setPageNumber(e)}
            showSizeChanger={false}
          />
        </Col>
      )}
    </Row>
  );
};

export default GroupsTable;
