import {
  Button,
  Card,
  Col,
  Empty,
  Flex,
  Form,
  Popconfirm,
  Popover,
  Row,
  Typography,
  message,
} from "antd";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import "../../Tyuts/Tyuts.css";
import btnStyles from "../../../../assets/styles/Custom/Button.module.css";
import typoStyles from "../../../../assets/styles/Custom/Typography.module.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomLayout from "../../../Layout";
import SideMenu from "../../../Layout/SideMenu";
import {
  completeTyutApi,
  deleteTyutApi,
  getTyutByIdApi,
  getTyutPermissionAPi,
  updateTyutAPi,
} from "../../../../services/tyuts.services";
import CustomCard from "../../../Blocks/CustomCard";
import CustomLmsTag from "../CreateTyut/CustomTag";
import ShareTyutModal from "../ShareTyut/ShareTyutModal";
import SOPContent from "../../../Sops/CreateSOP/SOPContent";
import SOPElements from "../../../Sops/CreateSOP/SOPElements";
import FloatinButtons from "./FloatinButtons";
import { RenderItems } from "../../Helpers";
import { ONPREMISE, TYUT, editTyut } from "../../../../constants/defaultKeys";
import { deleteCond, editCond, renameFile } from "../../../../helpers/utility";
import { UserContext } from "../../../../context/UserProvider";

function EditTyut({
  compTyutData = null,
  compTyutMetaData = {},
  compTyutContent = [],
  shouldFetchTyut = true,
  setIsTyutCompleted = null,
}) {
  const tyutFormRef = useRef(null);
  const activeQuestionnaireRef = useRef(null);
  const [tyutData, setTyutData] = useState(null);
  const [tyutContent, setTyutContent] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [tyutMetaData, setTyutMetaData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [viewAnsHint, setViewAnsHint] = useState({});
  const [isAnsSelected, setIsAnsSelected] = useState({});
  const [editing, setEditing] = useState({ edit: false, data: null });
  const [selectedValue, isSelectedValue] = useState({
    radio: null,
    check: [],
  });
  const [ids, setIds] = useState([]);
  const [currentTyut, setCurrentTyut] = useState(1);
  const [answeredIds, setAnsweredIds] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isUnfilled, setIsUnfilled] = useState("#406aff");
  const [isTyutUpdated, setTyutUpdated] = useState(false);
  const [isUploadLimitReached, setUploadLimitReached] = useState(false);
  const [tyutTags, setTuytTags] = useState(
    tyutMetaData.tags?.map((eachTag) => eachTag.name) || []
  );
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  let tyutId = compTyutData?._id || params?.id;

  async function getTyutPermission() {
    try {
      const res = await getTyutPermissionAPi(tyutId);
    } catch (error) {}
  }

  async function getTyut() {
    try {
      const res = await getTyutByIdApi(tyutId);
      if (res) {
        getIds(res?.data?.content);
        setTyutData(res?.data);
        setTyutMetaData(res?.data);
        setTyutContent(res?.data?.content);
      }
    } catch (error) {
      message.error(error.response?.data?.message);
      navigate("/trainings");
    }
  }

  const getIds = (content) => {
    const fetchTyutIds = content?.map((item) => item.id);
    isSelectedValue({
      radio: null,
      check: [],
    });
    setIsAnsSelected({});
    const video = content
      ?.filter((item) => item.type === "video")
      .map((item) => item.id);
    const document = content
      ?.filter((item) => item.type === "document")
      .map((item) => item.id);

    setIds(fetchTyutIds);

    const allAreVideosOrDocuments = content?.filter(
      (item) => item.type === "video" || item.type === "document"
    );

    if (allAreVideosOrDocuments) {
      setAnsweredIds(video.concat(document));
    }
  };

  async function completedTyut() {
    try {
      const res = await completeTyutApi(tyutId);
    } catch (error) {}
  }

  async function updateTyut() {
    let data = {
      ...tyutMetaData,
      content: tyutContent?.map((each) =>
        each?.optionType && each?.optionType === "images"
          ? {
              ...each,
              choices: each?.choices?.map((eachChoice) =>
                storageType == ONPREMISE
                  ? eachChoice
                  : renameFile(eachChoice, storageType)
              ),
              answers: Array.isArray(each?.answers)
                ? each?.answers.map((eachAnswer) =>
                    storageType == ONPREMISE
                      ? eachAnswer
                      : renameFile(eachAnswer, storageType)
                  )
                : storageType == ONPREMISE
                ? each?.answers
                : renameFile(each?.answers, storageType),
            }
          : each
      ),
      permissions: undefined,
      tags: tyutTags,
    };
    try {
      const res = await updateTyutAPi(tyutId, data);
      if (res) {
        getTyut();
        setTyutUpdated(false);
        setIsEditOpen(false);
      }
      message.success(res?.data?.message);
    } catch (error) {
      message.error(error.response?.data?.message);
    }
  }

  useEffect(() => {
    if (tyutFormRef.current && isTyutUpdated && tyutMetaData && isEditOpen) {
      tyutFormRef.current = null;
      updateTyut();
    }
  }, [isEditOpen, isTyutUpdated, tyutMetaData]);

  useEffect(() => {
    form.setFieldValue("name", tyutMetaData.name);
    form.setFieldValue("description", tyutMetaData.description);
    setTuytTags(tyutMetaData.tags);
  }, [tyutMetaData]);

  useEffect(() => {
    if (ids?.length > 0 && ids?.length === answeredIds?.length) {
      setIsCompleted(true);
      completedTyut();
    }
  }, [ids, answeredIds]);

  useEffect(() => {
    if (shouldFetchTyut) {
      getTyutPermission();
    }
  }, []);

  useEffect(() => {
    if (tyutId && shouldFetchTyut) getTyut();
    else getIds(compTyutContent);
  }, [currentTyut, location]);

  useEffect(() => {
    if (!shouldFetchTyut && isCompleted) {
      setIsTyutCompleted?.((prev) => ({
        ...prev,
        [compTyutData?._id]: true,
      }));
    }
  }, [isCompleted]);

  useEffect(() => {
    if (!shouldFetchTyut) {
      setTyutData(compTyutData);
      setTyutMetaData(compTyutMetaData);
      setTyutContent(compTyutContent);
      isSelectedValue({
        radio: null,
        check: [],
      });
      setIsAnsSelected({});
      tyutId = compTyutData?._id;
    }
  }, [compTyutData, compTyutMetaData, compTyutContent, shouldFetchTyut]);

  const deletTyutFunc = async () => {
    try {
      const resp = await deleteTyutApi(params?.id);
      resp && navigate(`/trainings?type=tyut`);
      message.success(resp?.data?.message);
    } catch (error) {
      message.error(error?.repsonse?.data?.message || "Something went wrong!!");
    }
  };

  const layoutProps = !location?.pathname.includes("course")
    ? {
        sideContent: shouldFetchTyut && <SideMenu />,
        header: (
          <Flex justify="space-between" align="center">
            <Typography className={typoStyles.h6}>
              <LeftOutlined
                style={{ marginRight: "6px" }}
                onClick={() => navigate("/trainings?type=tyut")}
              />
              {tyutData?.name?.length > 80
                ? tyutData?.name?.slice(0, 80) + "..."
                : tyutData?.name}
            </Typography>
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              {deleteCond(
                editTyut,
                tyutData?.creator?._id,
                userDetails?.activeOrg?.role,
                userDetails?._id
              ) && (
                <Popconfirm
                  icon={null}
                  description="Are you sure to delete this tyut?"
                  onConfirm={(e) => {
                    deletTyutFunc();
                  }}
                >
                  <Button className={btnStyles.mdBtn}>
                    <span className="hiddenBelowTab">Delete</span>
                    <DeleteOutlined style={{ margin: "0px" }} />
                  </Button>
                </Popconfirm>
              )}
              {editCond(
                editTyut,
                tyutData?.creator?._id,
                userDetails?.activeOrg?.role,
                userDetails?._id
              ) &&
                !isEditOpen && (
                  <Button
                    className={btnStyles.mdBtn}
                    onClick={() => setIsEditOpen((prev) => !prev)}
                    type="primary"
                  >
                    <span className="hiddenBelowTab">Edit</span>
                    <EditOutlined style={{ margin: "0px" }} />
                  </Button>
                )}
            </div>
          </Flex>
        ),
      }
    : {};

  const Layout = !location?.pathname.includes("course")
    ? CustomLayout
    : Fragment;

  return (
    <Fragment>
      <Layout {...layoutProps}>
        <Row
          gutter={[24, 24]}
          style={{
            position: "relative",
            width: "100%",
          }}
        >
          {!isEditOpen && tyutData && (
            <Col
              xs={
                shouldFetchTyut
                  ? { span: 18, offset: 6 }
                  : { span: 20, offset: 2 }
              }
              md={
                shouldFetchTyut
                  ? { span: 18, offset: 6 }
                  : { span: 20, offset: 2 }
              }
              lg={
                shouldFetchTyut
                  ? { span: 18, offset: 6 }
                  : { span: 20, offset: 2 }
              }
              xl={
                shouldFetchTyut
                  ? { span: 16, offset: 4 }
                  : { span: 20, offset: 2 }
              }
            >
              <CustomCard>
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 500,
                        lineHeight: "24px",
                      }}
                    >
                      {tyutData?.name}
                    </Typography>
                  </Col>
                  <Col span={24}>
                    <Typography.Paragraph
                      ellipsis={{
                        rows: 2,
                        expandable: true,
                        symbol: "read more",
                      }}
                      style={{
                        fontSize: 18,
                        fontWeight: 400,
                        lineHeight: "20px",
                      }}
                    >
                      {tyutData?.description}
                    </Typography.Paragraph>
                  </Col>
                  <Col span={24}>
                    <CustomLmsTag data={tyutData?.tags} />
                  </Col>
                </Row>
              </CustomCard>
            </Col>
          )}
          {isEditOpen ? (
            <>
              {" "}
              <Col
                xs={{ span: 18, offset: 6 }}
                md={{ span: 18, offset: 6 }}
                lg={{ span: 18, offset: 6 }}
                xl={{ span: 16, offset: 4 }}
                style={{ paddingBottom: "0px" }}
              >
                <Card style={{ width: "100%" }}>
                  <SOPContent
                    tyutTags={tyutTags}
                    setTuytTags={setTuytTags}
                    contentType={TYUT}
                    isUploadLimitReached={isUploadLimitReached}
                    isUnfilled={isUnfilled}
                    setIsUnfilled={setIsUnfilled}
                    ref={activeQuestionnaireRef}
                    setsopMetaData={setTyutMetaData}
                    sopFormRef={tyutFormRef}
                    sopContent={tyutContent}
                    setSopContent={setTyutContent}
                    editing={editing}
                    setEditing={setEditing}
                    sopMetaData={tyutMetaData}
                  />
                </Card>{" "}
                <SOPElements
                  contentType={TYUT}
                  isUploadLimitReached={isUploadLimitReached}
                  setIsUnfilled={setIsUnfilled}
                  ref={activeQuestionnaireRef}
                  editing={editing}
                  setEditing={setEditing}
                  sopContent={tyutContent}
                  setSopContent={setTyutContent}
                />
              </Col>
            </>
          ) : tyutData && tyutData?.content?.length !== 0 ? (
            tyutData?.content?.map((item, index) => (
              <Col
                xs={
                  shouldFetchTyut
                    ? { span: 18, offset: 6 }
                    : { span: 20, offset: 2 }
                }
                md={
                  shouldFetchTyut
                    ? { span: 18, offset: 6 }
                    : { span: 20, offset: 2 }
                }
                lg={
                  shouldFetchTyut
                    ? { span: 18, offset: 6 }
                    : { span: 20, offset: 2 }
                }
                xl={
                  shouldFetchTyut
                    ? { span: 16, offset: 4 }
                    : { span: 20, offset: 2 }
                }
                key={index}
              >
                {RenderItems(
                  storageType,
                  item,
                  ids,
                  setAnsweredIds,
                  answeredIds,
                  tyutId,
                  setViewAnsHint,
                  isAnsSelected,
                  viewAnsHint,
                  selectedValue,
                  setIsAnsSelected,
                  isSelectedValue
                )}
              </Col>
            ))
          ) : (
            <Col span={24}>
              <Empty />
            </Col>
          )}
          {shouldFetchTyut && !isEditOpen && (
            <FloatinButtons
              currentTyut={currentTyut}
              tyutData={tyutData}
              setTyutData={setTyutData}
              setCurrentTyut={setCurrentTyut}
              shouldFetchTyut={shouldFetchTyut}
              setIsModalOpen={setIsModalOpen}
              setIsEditOpen={setIsEditOpen}
            />
          )}
          {isEditOpen && (
            <Col
              span={4}
              align="end"
              style={{ position: "fixed", zIndex: 1, right: 10, top: "84px" }}
            >
              <Popover
                placement="left"
                content={
                  <>
                    {tyutContent?.filter((each) => each.type == "video")
                      ?.length == 0 ? (
                      <Typography>Add atleast one video</Typography>
                    ) : tyutContent?.length == 0 ? (
                      <Typography>Add tyuts to proceed</Typography>
                    ) : (
                      <Typography>Proceed to save</Typography>
                    )}
                  </>
                }
                trigger="hover"
              >
                <Button
                  className={btnStyles.mdBtn}
                  type="primary"
                  disabled={
                    editing.edit == false &&
                    tyutContent?.length > 0 &&
                    tyutContent.filter((each) => each?.type == "video")
                      ?.length > 0
                      ? false
                      : true
                  }
                  onClick={() => {
                    tyutFormRef.current?.submit();
                    if (
                      tyutFormRef.current
                        ?.getFieldsValue()
                        ?.name?.replace(/\s/g, "")
                    ) {
                      setTyutUpdated(true);
                    }
                  }}
                >
                  Save changes
                  <SaveOutlined />
                </Button>
              </Popover>
            </Col>
          )}
        </Row>
      </Layout>
      {isModalOpen && (
        <ShareTyutModal
          creator_id={tyutData?.creator?._id}
          tyut_id={tyutId}
          isModalOpen={isModalOpen}
          handleCancel={() => setIsModalOpen(false)}
          handleOk={() => setIsModalOpen(false)}
        />
      )}
    </Fragment>
  );
}

export default EditTyut;
