import { Col, Row, message } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import ShareTyut from "./ShareTyut";
import PreviewTyut from "./PreviewTyut";
import { createTyutApi } from "../../../../services/tyuts.services";
import { renameFile } from "../../../../helpers/utility";
import { UserContext } from "../../../../context/UserProvider";
import { ONPREMISE } from "../../../../constants/defaultKeys";

function PublishTyut({ tyutMetaData, tyutContent, setPreview }) {
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  async function createTyut(permissions, anyOneCanView, payload) {
    let createTyutData = {
      ...payload,
      content: payload?.content?.map((each) =>
        each?.optionType && each?.optionType === "images"
          ? {
              ...each,
              choices: each?.choices?.map((eachChoice) =>
                storageType == ONPREMISE
                  ? eachChoice
                  : renameFile(eachChoice, storageType)
              ),
              answers: Array.isArray(each?.answers)
                ? each?.answers.map((eachAnswer) =>
                    storageType == ONPREMISE
                      ? eachAnswer
                      : renameFile(eachAnswer, storageType)
                  )
                : storageType == ONPREMISE
                ? each?.answers
                : renameFile(each?.answers, storageType),
            }
          : each
      ),
      tags: payload.tags,
      permissions,
    };

    if (anyOneCanView)
      createTyutData.permissions.push({
        level: "public",
      });
    else {
      createTyutData.permissions = permissions;
    }
    try {
      let res = await createTyutApi(createTyutData);
      message.success(res?.data?.message);
      navigate("/trainings?type=tyut");
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong");
    }
  }
  return (
    <Row>
      <Col xs={24} sm={24} md={10} lg={10} xl={10} offset={1}>
        <PreviewTyut tyutContent={tyutContent} tyutMetaData={tyutMetaData} />
      </Col>
      <Col xs={24} sm={24} md={11} lg={11} xl={11} offset={1}>
        <ShareTyut
          placeholder={"Add people or Groups"}
          setPreview={setPreview}
          editBtn={true}
          tyutMetaData={tyutMetaData}
          tyutContent={tyutContent}
          publishHandler={(permissions, anyOneCanView) =>
            createTyut(permissions, anyOneCanView, {
              ...tyutMetaData,
              content: tyutContent,
            })
          }
        />
      </Col>
    </Row>
  );
}

export default PublishTyut;
