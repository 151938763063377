import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../../Layout";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Image,
  Input,
  Popconfirm,
  Row,
  Select,
  Space,
  Tag,
  TreeSelect,
  Typography,
  message,
} from "antd";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import buttonStyles from "../../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import {
  DeleteOutlined,
  EditOutlined,
  HolderOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import {
  deleteCond,
  editCond,
  generateRandomString,
  getAssetsPath,
} from "../../../helpers/utility";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  createProductionLine,
  deleteProductionLineApi,
  getAllAssetsWithLocsApi,
  getProductionLineDetails,
  updateProductionLine,
} from "../../../services/assets.services";
import defaultImage from "../../../assets/images/defaultImage.png";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import { deletePL } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserProvider";

const CreateProductionLine = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const [details, setDetails] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [locations, setLocations] = useState({});
  const [locAssetMap, setLocAssetMap] = useState({});
  const [selectedLoc, setSelectedLoc] = useState(null);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [plDetails, setPlDetails] = useState(null);

  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;

  function transformData(data) {
    let locAssetMapObj = {};
    function transformNode(node) {
      const transformedChildren = node.children.map(transformNode);
      locAssetMapObj = {
        ...locAssetMapObj,
        [`${node._id}`]: node?.assets?.map((i) => {
          return {
            label: i?.name,
            value: i?._id,
          };
        }),
      };
      return {
        value: node._id,
        title: node.name,
        disabled: node?.disabled,
        children: transformedChildren.length ? transformedChildren : undefined,
      };
    }
    let treeData = transformNode(data);
    return { treeData, locAssetMapObj };
  }

  const fetchLocations = async () => {
    try {
      const resp = await getAllAssetsWithLocsApi();
      let data = transformData(resp?.data?.locations?.[0]);

      setLocations(data?.treeData);
      setLocAssetMap(data?.locAssetMapObj);
    } catch (error) {
      setLocations({});
    }
  };

  const fetchProductionLineDetails = async () => {
    try {
      const res = await getProductionLineDetails(params?.id);
      let data = res?.data;
      if (data) {
        setPlDetails(data);
        setSelectedLoc(data?.location?._id);
        setSelectedAssets(
          data?.assets?.map((item) => {
            return {
              id: generateRandomString(12),
              asset_id: item?.asset?._id,
              name: item?.asset?.name,
              file: item?.asset?.file,
              code: item?.asset?.code,
            };
          })
        );
        form.setFieldsValue({
          name: data?.name,
          location: data?.location?._id,
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchLocations();
    if (params?.id) {
      fetchProductionLineDetails();
    } else {
      setEditMode(true);
    }
  }, [refresh]);

  const onFinish = async (values) => {
    let callStatus = true;
    let assetsList = [];

    if (selectedAssets?.length > 0) {
      selectedAssets?.map((item, index) => {
        if (!item?.asset_id) {
          callStatus = false;
        }
        assetsList.push({
          asset: item?.asset_id,
          order: index + 1,
        });
      });
    } else {
      callStatus = false;
    }
    if (!callStatus) {
      if (assetsList?.length === 0) {
        message.error("Please select atleast 1 asset");
      } else {
        message.error("Please fill all asset fields");
      }
    } else {
      let payload = {
        name: values?.name,
        location: values?.location,
        assets: assetsList,
      };

      setBtnLoading(true);
      try {
        const resp = params?.id
          ? await updateProductionLine(params?.id, payload)
          : await createProductionLine(payload);
        if (resp) {
          !params?.id && navigate(`/production-lines`);
          message.success(
            `Production line ${params?.id ? "updated" : "added"} successfully`
          );
          params?.id && setEditMode(false);
        }
      } catch (error) {
        message.error(
          error?.response?.data?.message || "Something went wrong!!"
        );
      } finally {
        setBtnLoading(false);
        setRefresh((prev) => !prev);
      }
    }
  };

  const deleteProductionLine = async () => {
    try {
      const resp = await deleteProductionLineApi(params?.id);
      if (resp) {
        navigate(`/production-lines`);
        message.success(resp?.data?.message);
      }
    } catch (error) {
      message.error(error?.repsonse?.data?.message);
    }
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setSelectedAssets((prev) => {
        const activeIndex = prev.findIndex((i) => i.id === active.id);
        const overIndex = prev.findIndex((i) => i.id === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const DraggableCardItem = ({ item }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: item.id,
    });
    const style = {
      transform: transform
        ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
        : "none",
      transition,
      cursor: editMode ? "move" : "not-allowed",
      backgroundColor: "#fff",
      border: "1px solid #e8e8e8",
      borderRadius: "0px",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
    };

    return (
      <div ref={setNodeRef} style={style}>
        <div
          style={{
            gap: 16,
            width: "90%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <HolderOutlined
            style={{
              cursor: editMode ? "grab" : "not-allowed",
              color: "black",
              pointerEvents: editMode ? "auto" : "none",
            }}
            {...attributes}
            {...listeners}
          />
          <Select
            disabled={!editMode}
            placeholder="Select asset"
            notFoundContent="No assets found"
            style={{
              width: "80%",
            }}
            className={inputStyles.smSelect}
            showSearch={true}
            optionFilterProp="label"
            options={locAssetMap?.[selectedLoc] || []}
            value={item?.asset_id}
            onChange={(value) => {
              let updated_assets = [...selectedAssets];
              updated_assets?.map((j) => {
                if (j?.id === item?.id) {
                  j["asset_id"] = value;
                }
                return j;
              });
              setSelectedAssets(updated_assets);
            }}
          />
        </div>
        <DeleteOutlined
          style={{
            cursor: editMode ? "pointer" : "not-allowed",
            pointerEvents: editMode ? "auto" : "none",
          }}
          onClick={() => {
            let updated_assets = [];
            selectedAssets?.map((j) => {
              if (j?.id !== item?.id) {
                updated_assets.push(j);
              }
            });
            setSelectedAssets(updated_assets);
          }}
        />
      </div>
    );
  };

  return (
    <CustomLayout
      header={
        <>
          <Flex justify="space-between" align="center">
            <div className="flex-start" style={{ gap: 6 }}>
              <LeftOutlined
                className={typoStyles.h6}
                onClick={() => navigate("/production-lines")}
              />
              <Typography className={typoStyles.h6}>
                {params?.id
                  ? details?.id || "Update Production Line"
                  : "Create Production Line"}
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
              {params?.id &&
                deleteCond(
                  deletePL,
                  plDetails?.creator,
                  userDetails?.activeOrg?.role,
                  userDetails?._id
                ) && (
                  <Popconfirm
                    icon={null}
                    description="Are you sure to delete this production line?"
                    onConfirm={(e) => {
                      deleteProductionLine();
                    }}
                  >
                    <Button className={buttonStyles.mdBtn}>
                      <span className="hiddenBelowTab">Delete</span>
                      <DeleteOutlined style={{ margin: "0px" }} />
                    </Button>
                  </Popconfirm>
                )}
              {!editMode &&
                params?.id &&
                editCond(
                  deletePL,
                  plDetails?.creator,
                  userDetails?.activeOrg?.role,
                  userDetails?._id
                ) && (
                  <Button
                    className={buttonStyles.mdBtn}
                    onClick={() => setEditMode(true)}
                    type="primary"
                  >
                    <span className="hiddenBelowTab">Edit</span>

                    <EditOutlined style={{ margin: "0px" }} />
                  </Button>
                )}
            </div>
          </Flex>
        </>
      }
      footer={
        <>
          {(!params?.id || (params?.id && editMode)) && (
            <Flex justify="flex-end" gap={12}>
              <Button
                className={buttonStyles.mdBtn}
                onClick={() => {
                  if (params?.id) {
                    setEditMode(false);
                    fetchProductionLineDetails();
                  } else {
                    setSelectedAssets([]);
                    form.resetFields();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={buttonStyles.mdBtn}
                type="primary"
                onClick={form.submit}
                loading={btnLoading}
              >
                Save
              </Button>
            </Flex>
          )}
        </>
      }
    >
      <Form
        name="production-line-form"
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onFinish}
      >
        <Row>
          <Col span={12}>
            <Card
              title="Production Line Details"
              styles={{
                header: { backgroundColor: "#fff" },
              }}
            >
              <Row gutter={[20, 16]}>
                <Col span={24}>
                  <Form.Item
                    label="Title"
                    name={"name"}
                    rules={[
                      {
                        whitespace: true,
                        message: "Title cannot be empty or contain only spaces",
                      },
                      {
                        required: true,
                        message: "Please enter title",
                      },
                    ]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Enter title here"
                      disabled={!editMode}
                      className={inputStyles.smInput}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Location"
                    name={"location"}
                    rules={[
                      { required: true, message: "Please enter location" },
                    ]}
                  >
                    <TreeSelect
                      placeholder="Location"
                      style={{
                        width: "100%",
                      }}
                      showSearch
                      className={inputStyles.smSelect}
                      onChange={(value) => {
                        setSelectedLoc(value);
                        setSelectedAssets([]);
                      }}
                      treeData={
                        Object.keys(locations)?.length > 0 && [locations]
                      }
                      disabled={!editMode}
                    />
                  </Form.Item>
                </Col>
                {params?.id && !editMode ? (
                  <>
                    {selectedAssets?.map((item) => (
                      <Col span={24}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width: "80%",
                            gap: 40,
                            padding: 4,
                            border: "1px solid #d0d5dd",
                            borderRadius: 8,
                          }}
                        >
                          <Image
                            src={
                              item?.file
                                ? getAssetsPath(item?.file, storageType)
                                : defaultImage
                            }
                            preview={false}
                            style={{ height: "80px", width: "80px" }}
                          />
                          <Space direction="vertical" style={{ gap: 4 }}>
                            <Typography>{item?.name}</Typography>
                            {item?.code && (
                              <Tag color="blue" className={tagStyles.mdTag}>
                                {item?.code}
                              </Tag>
                            )}
                          </Space>
                        </div>
                      </Col>
                    ))}
                  </>
                ) : (
                  <>
                    {selectedAssets && selectedAssets?.length > 0 && (
                      <Col span={24}>
                        <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                          <SortableContext
                            items={selectedAssets?.map((i) => i.id)}
                            strategy={verticalListSortingStrategy}
                          >
                            {selectedAssets?.map((item) => (
                              <DraggableCardItem item={item} />
                            ))}
                          </SortableContext>
                        </DndContext>
                      </Col>
                    )}
                    <Col span={24}>
                      <Button
                        className={buttonStyles.mdBtn}
                        type="primary"
                        ghost
                        disabled={!editMode}
                        onClick={() => {
                          const assetId = generateRandomString(12);
                          setSelectedAssets([
                            ...selectedAssets,
                            {
                              id: assetId,
                              asset_id: undefined,
                            },
                          ]);
                        }}
                      >
                        + Add Asset
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </CustomLayout>
  );
};

export default CreateProductionLine;
