import { Card, Col, Row, Space, Tag, Typography } from "antd";
import React from "react";
import { convertUTCToLocalFormat, formatHours } from "../../helpers/utility";
import dayjs from "dayjs";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import { EditTwoTone } from "@ant-design/icons";

const TaskInfo = ({ details, typeValue, showEdit = false, onEditClick }) => {
  const displayDateTime = (dateStr) => {
    return (
      <>
        <span>
          {dayjs(convertUTCToLocalFormat(dateStr)).format("DD MMM YYYY")}
        </span>
        <span style={{ marginLeft: 10 }}>
          {dayjs(convertUTCToLocalFormat(dateStr)).format("hh:mm A")}
        </span>
      </>
    );
  };

  let duration =
    typeValue === "WO" ? details?.wo_duration : details?.occ_duration;
  let durationObj = duration ? formatHours(duration) : null;
  return (
    <>
      <Card styles={{ body: { padding: "16px" } }} style={{ marginTop: 12 }}>
        <Row gutter={[12, 4]}>
          <Col span={12}>Initiated by</Col>
          <Col span={12}>
            {details?.creator?.first_name || details?.creator?.last_name
              ? `${details?.creator?.first_name} ${
                  details?.creator?.last_name || ""
                }`
              : "-"}
          </Col>
          <Col span={12}>Closed by</Col>
          <Col span={12}>
            {details?.closed_by?.first_name || details?.closed_by?.last_name
              ? `${details?.closed_by?.first_name} ${
                  details?.closed_by?.last_name || ""
                }`
              : "-"}
          </Col>
          <Col span={12}>Handled by</Col>
          <Col span={12}>
            {details?.worked_by?.length > 0 ? (
              <Space direction="vertical" style={{ width: "100%", gap: 0 }}>
                {details?.worked_by?.map((u) => (
                  <Typography>{`${u?.first_name} ${
                    u?.last_name || ""
                  }`}</Typography>
                ))}
              </Space>
            ) : (
              "-"
            )}
          </Col>
          <Col span={12}>Verified by</Col>
          <Col span={12}>
            {details?.verified_by?.first_name || details?.verified_by?.last_name
              ? `${details?.verified_by?.first_name} ${
                  details?.verified_by?.last_name || ""
                }`
              : "-"}
          </Col>
        </Row>
      </Card>
      <Card styles={{ body: { padding: "16px" } }} style={{ marginTop: 12 }}>
        <Row gutter={[12, 4]}>
          <Col span={12}>Initiated at</Col>
          <Col span={12}>
            {details?.created_at ? displayDateTime(details?.created_at) : "-"}
          </Col>
          <Col span={12}>Scheduled to start at</Col>
          <Col span={12}>
            {details?.start_date ? displayDateTime(details?.start_date) : "-"}
          </Col>
          <Col
            span={12}
            style={{ display: "flex", alignItems: "center", gap: 10 }}
          >
            <Typography>Started at</Typography>
            {(typeValue === "WO" || typeValue === "PM") && showEdit && (
              <EditTwoTone onClick={() => onEditClick("started")} />
            )}
          </Col>
          <Col span={12}>
            {details?.started_at ? displayDateTime(details?.started_at) : "-"}
          </Col>
          <Col span={12}>Scheduled to close at</Col>
          <Col span={12}>
            {details?.end_date ? displayDateTime(details?.end_date) : "-"}
          </Col>
          <Col
            span={12}
            style={{ display: "flex", alignItems: "center", gap: 10 }}
          >
            <Typography>Closed at</Typography>
            {(typeValue === "WO" || typeValue === "PM") && showEdit && (
              <EditTwoTone onClick={() => onEditClick("closed")} />
            )}
          </Col>
          <Col span={12}>
            {details?.ended_at ? displayDateTime(details?.ended_at) : "-"}
          </Col>
          <Col span={12}>Verified at</Col>
          <Col span={12}>
            {details?.mark_as_verified && details?.updated_at
              ? displayDateTime(details?.updated_at)
              : "-"}
          </Col>
          <Col span={12}>Total time taken to complete</Col>
          <Col span={12}>
            {durationObj ? (
              <Tag className={tagStyles.smRndTag} color="blue">
                {durationObj?.days ? `${durationObj?.days} days ` : ""}
                {`${durationObj?.hours} hrs`}
                {!durationObj?.days ? ` ${durationObj?.minutes} mins` : ""}
              </Tag>
            ) : (
              "-"
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default TaskInfo;
