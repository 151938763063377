import { Button, Col, Form, Modal, Row, TreeSelect } from "antd";
import React, { useEffect, useRef } from "react";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../assets/styles/Custom/Input.module.css";

const InventoryFilters = ({
  showFilterModal,
  setShowFilterModal,
  filters,
  setFilters,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef();

  const inventoryTypes = [
    {
      title: "Mechanical",
      value: "Mechanical",
    },
    {
      title: "Electrical",
      value: "Electrical",
    },
    {
      title: "Others",
      value: "Others",
    },
  ];

  useEffect(() => {
    if (showFilterModal) {
      form.setFieldsValue(filters);
    }
  }, [showFilterModal, filters]);

  const onFinish = (values) => {
    setShowFilterModal(false);
    setFilters({
      types: values?.types,
    });
  };

  return (
    <>
      <Modal
        title="Filter"
        width={"50%"}
        open={showFilterModal}
        onCancel={() => setShowFilterModal(false)}
        maskClosable={false}
        footer={
          <Button
            className={btnStyles.mdBtn}
            type="primary"
            onClick={() => formRef?.current?.submit()}
          >
            Apply
          </Button>
        }
      >
        <Form layout="vertical" form={form} ref={formRef} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="Inventory Type" name={"types"}>
                <TreeSelect
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Inventory Type"
                  allowClear
                  multiple={true}
                  treeCheckable={true}
                  showSearch={false}
                  treeData={inventoryTypes}
                  popupClassName="filter-tree-dropdown"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default InventoryFilters;
