import { Button, Col, Form, Modal, Row, Select, TreeSelect } from "antd";
import React, { useEffect, useRef } from "react";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../assets/styles/Custom/Input.module.css";

const PmFilters = ({
  type,
  viewType,
  showFilterModal,
  setShowFilterModal,
  assets,
  productionLines,
  filters,
  setFilters,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef();

  const pmTypes = [
    {
      title: "Mechanical",
      value: "Mechanical",
    },
    {
      title: "Electrical",
      value: "Electrical",
    },
    {
      title: "Others",
      value: "Others",
    },
  ];

  const priorityOptions = [
    {
      title: "Low",
      value: "low",
    },
    {
      title: "Moderate",
      value: "moderate",
    },
    {
      title: "High",
      value: "high",
    },
  ];

  const verifyStatuses = [
    {
      label: "Verified",
      value: "verified",
    },
    {
      label: "Not Verified",
      value: "not-verified",
    },
  ];

  useEffect(() => {
    if (showFilterModal) {
      form.setFieldsValue(filters);
    }
  }, [showFilterModal, filters]);

  const onFinish = (values) => {
    setShowFilterModal(false);
    let formValues = {
      asset: values?.asset,
      production_line: values?.production_line,
      types: values?.types,
      priorities: values?.priorities,
    };
    if (viewType === "job") {
      formValues = {
        ...formValues,
        verification: values?.verification,
      };
    }
    setFilters(formValues);
  };

  return (
    <>
      <Modal
        title="Filter"
        width={"50%"}
        open={showFilterModal}
        onCancel={() => setShowFilterModal(false)}
        maskClosable={false}
        footer={
          <Button
            className={btnStyles.mdBtn}
            type="primary"
            onClick={() => formRef?.current?.submit()}
          >
            Apply
          </Button>
        }
      >
        <Form layout="vertical" form={form} ref={formRef} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="Asset" name={"asset"}>
                <Select
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Type asset name"
                  allowClear
                  showSearch={true}
                  filterOption={(inputValue, option) =>
                    option.name
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  options={assets}
                  onChange={(e) =>
                    form.setFieldValue("production_line", undefined)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Production Line" name={"production_line"}>
                <Select
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Type production line name"
                  allowClear
                  showSearch={true}
                  optionFilterProp="label"
                  options={productionLines}
                  onChange={(e) => form.setFieldValue("asset", undefined)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={`${type} Type`} name={"types"}>
                <TreeSelect
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder={`${type} Type`}
                  allowClear
                  multiple={true}
                  treeCheckable={true}
                  showSearch={false}
                  treeData={pmTypes}
                  popupClassName="filter-tree-dropdown"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Priority" name={"priorities"}>
                <TreeSelect
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Priority"
                  allowClear
                  multiple={true}
                  treeCheckable={true}
                  showSearch={false}
                  treeData={priorityOptions}
                  popupClassName="filter-tree-dropdown"
                />
              </Form.Item>
            </Col>
            {viewType === "job" && (
              <>
                <Col span={12}>
                  <Form.Item
                    label={`${type} Verification`}
                    name={"verification"}
                  >
                    <Select
                      className={inputStyles.smSelect}
                      style={{ width: "100%" }}
                      placeholder={`${type} Verification`}
                      allowClear
                      options={verifyStatuses}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default PmFilters;
