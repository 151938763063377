import { Card, Col, Divider, Row, Tag, Typography } from "antd";
import React from "react";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import dayjs from "dayjs";
import { convertUTCToLocalFormat, formatHours } from "../../../helpers/utility";

const VerificationDetails = ({ mroDetails }) => {
  const displayBreakdownTime = (value) => {
    if (mroDetails?.severity) {
      if (mroDetails?.severity === "critical") {
        if (mroDetails?.workorders?.[0]?.status === "closed") {
          let durationObj = formatHours(value ? value : 0);
          return (
            <Tag className={tagStyles.smRndTag} color="error">
              {durationObj?.days ? `${durationObj?.days} days ` : ""}
              {`${durationObj?.hours} hrs`}
              {!durationObj?.days ? ` ${durationObj?.minutes} mins` : ""}
            </Tag>
          );
        } else {
          return <Typography>MRO is not yet resolved</Typography>;
        }
      } else {
        return <Typography>Not a breakdown</Typography>;
      }
    } else {
      return "-";
    }
  };

  return (
    <Card title="MRO Summary" styles={{ header: { backgroundColor: "#fff" } }}>
      <Row gutter={[12, 6]}>
        <Col span={12}>MRO status</Col>
        <Col span={12}>
          <Tag
            className={tagStyles.smTag}
            color={mroDetails?.status === "resolved" ? "#87d068" : "#f50"}
          >
            {mroDetails?.status === "resolved" ? "Resolved" : "Not Resolved"}
          </Tag>
        </Col>
        <Col span={12}>Severity</Col>
        <Col span={12} style={{ display: "flex", alignItems: "center" }}>
          {mroDetails?.severity ? (
            <Tag
              className={tagStyles.mdRndDotTag}
              style={{ borderRadius: 16 }}
              color={mroDetails?.severity === "critical" ? "error" : "warning"}
            >
              {mroDetails?.severity === "critical"
                ? "Critical"
                : "Non Critical"}
            </Tag>
          ) : (
            "-"
          )}
        </Col>
        <Col span={12}>
          Breakdown time <br /> <span style={{ fontSize: 11 }}>
            (MRO started to WO closed)</span>
        </Col>

        <Col span={12}>{displayBreakdownTime(mroDetails?.mro_duration)}</Col>
        <Col span={24}>
          <Divider style={{ marginTop: 12, marginBottom: 12 }} />
        </Col>
        <Col span={12}>Created by</Col>
        <Col span={12}>
          {mroDetails?.creator?.first_name || mroDetails?.creator?.last_name
            ? `${mroDetails?.creator?.first_name} ${
                mroDetails?.creator?.last_name || ""
              }`
            : "-"}
        </Col>
        <Col span={12}>Resolved by</Col>
        <Col span={12}>
          {mroDetails?.status === "resolved" &&
          (mroDetails?.resolved_by?.first_name ||
            mroDetails?.resolved_by?.last_name)
            ? `${mroDetails?.resolved_by?.first_name} ${
                mroDetails?.resolved_by?.last_name || ""
              }`
            : "-"}
        </Col>
        <Col span={12}>Verified by</Col>
        <Col span={12}>
          {mroDetails?.mark_as_verified &&
          (mroDetails?.verification_steps?.[0]?.verifier?.first_name ||
            mroDetails?.verification_steps?.[0]?.verifier?.last_name)
            ? `${mroDetails?.verification_steps?.[0]?.verifier?.first_name} ${
                mroDetails?.verification_steps?.[0]?.verifier?.last_name || ""
              }`
            : "-"}
        </Col>
        <Col span={24}>
          <Divider style={{ marginTop: 12, marginBottom: 12 }} />
        </Col>
        <Col span={12}>Created at</Col>
        <Col span={12}>
          {mroDetails?.created_at
            ? dayjs(convertUTCToLocalFormat(mroDetails?.created_at)).format(
                "DD MMM YYYY hh:mm A"
              )
            : "-"}
        </Col>
        <Col span={12}>Expected to start at</Col>
        <Col span={12}>
          {mroDetails?.start_date
            ? dayjs(convertUTCToLocalFormat(mroDetails?.start_date)).format(
                "DD MMM YYYY hh:mm A"
              )
            : "-"}
        </Col>
        <Col span={12}>Resolved at</Col>
        <Col span={12}>
          {mroDetails?.ended_at
            ? dayjs(convertUTCToLocalFormat(mroDetails?.ended_at)).format(
                "DD MMM YYYY hh:mm A"
              )
            : "-"}
        </Col>
        <Col span={12}>Verified at</Col>
        <Col span={12}>
          {mroDetails?.mark_as_verified &&
          mroDetails?.verification_steps?.[0]?.verified_at
            ? dayjs(
                convertUTCToLocalFormat(
                  mroDetails?.verification_steps?.[0]?.verified_at
                )
              ).format("DD MMM YYYY hh:mm A")
            : "-"}
        </Col>
      </Row>
    </Card>
  );
};

export default VerificationDetails;
