import { Row, Col, Typography, Card, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { RenderThumbnail } from "../../Helpers";
import "../Course.css";
import typographyStyles from "../../../../assets/styles/Custom/Typography.module.css";

function TyutList({
  tyutsList,
  setSelectedTyuts,
  setActiveTyut,
  selectedTyuts,
  activeTyut,
}) {
  const [selectedTyutId, setSelectedTyutId] = useState({
    [tyutsList[0]]: tyutsList[0],
  });

  useEffect(() => {
    setSelectedTyutId(
      selectedTyuts.reduce((obj, item) => {
        obj[item._id] = item._id;
        return obj;
      }, {})
    );
  }, [selectedTyuts]);

  return (
    <Row gutter={[12, 12]}>
      {tyutsList?.map((eachTyut) => (
        <Col span={24} key={eachTyut?._id}>
          <Card
            className={`tyutCardLessDescriptive ${
              activeTyut?._id === eachTyut?._id &&
              "activetyutCardLessDescriptive"
            }`}
            bodyStyle={{ padding: "16px" }}
          >
            <Row
              onClick={() => setActiveTyut(eachTyut)}
              style={{ cursor: "pointer" }}
              gutter={[12, 12]}
            >
              <Col
                span={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  onChange={(e) => {
                    let checked = e.target.checked;
                    let val = e.target.value;
                    if (checked) {
                      setActiveTyut(eachTyut);
                      setSelectedTyuts((prev) => [...prev, eachTyut]);
                      setSelectedTyutId((prev) => ({
                        ...prev,
                        [val]: val,
                      }));
                    } else {
                      const tyuts = selectedTyuts?.filter(
                        (tyuts) => tyuts?._id !== eachTyut?._id
                      );
                      setSelectedTyuts(tyuts);
                      delete selectedTyutId?.[val];
                      setSelectedTyutId({ ...selectedTyutId });
                      setActiveTyut(tyuts?.length == 0 ? null : tyuts?.[0]);
                    }
                  }}
                  checked={selectedTyutId?.[eachTyut?._id] ? true : false}
                  value={eachTyut?._id}
                />
              </Col>
              <Col span={8}>{RenderThumbnail(eachTyut, () => {})}</Col>
              <Col
                span={14}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography.Paragraph
                  className={typographyStyles.subtext2}
                  ellipsis={true}
                >
                  {eachTyut?.name}
                </Typography.Paragraph>
                <Typography.Text
                  className={typographyStyles.body1}
                  ellipsis={true}
                >
                  {eachTyut?.description}
                </Typography.Text>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default TyutList;
