import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Image,
  Input,
  Row,
  Typography,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";

import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import darkLogo from "../../../assets/images/cmms-logos/CMMSLogo.svg";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  getMetaDataApi,
  setPasswordApi,
} from "../../../services/users.services";
import ChooseOrgModal from "../../Organisations/ChooseOrgModal";
import { UserContext } from "../../../context/UserProvider";
import { setCookie } from "../../../helpers/localStorage";
import {
  getSubdomain,
  redirectToDashboard,
} from "../../../helpers/domainUtility";
import { ACTIVEDOMAIN, ORGANISATION } from "../../../constants/defaultKeys";
import { getDomainDetailsApi } from "../../../services/organisation.services";

const SetPassword = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [openChooseModal, setOpenChooseModal] = useState(false);
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [orgDetails, setOrgDetails] = useState({ logo: darkLogo, name: "" });

  const onFinish = async (values) => {
    try {
      const resp = await setPasswordApi(values);
      if (resp) {
        getOrgData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const getOrgData = async () => {
    try {
      const resp = await getMetaDataApi();
      if (orgDetails?.name) {
        const activeOrg =
          resp?.data?.organizations?.filter(
            (eachOrg) => eachOrg?.organization?._id === orgDetails?.id
          )?.[0] || {};
        if (Object?.keys(activeOrg).length > 0) {
          setCookie(
            ORGANISATION,
            activeOrg?.organization?._id,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          setCookie(
            ACTIVEDOMAIN,
            activeOrg?.organization?.domain,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          setUserDetails({ ...resp?.data, activeOrg: activeOrg });
          redirectToDashboard(
            activeOrg?.organization?.domain,
            activeOrg?.organization?.settings?.storage,
            navigate
          );
        } else {
          setUserDetails(resp?.data);
          setOpenChooseModal(true);
        }
      } else {
        if (resp?.data?.organizations?.length === 1) {
          const activeOrg = resp?.data?.organizations?.[0];
          setCookie(
            ORGANISATION,
            activeOrg?.organization?._id,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          setCookie(
            ACTIVEDOMAIN,
            activeOrg?.organization?.domain,
            `.${process.env.REACT_APP_PUBLIC_DOMAIN}`,
            "/"
          );
          setUserDetails({ ...resp?.data, activeOrg: activeOrg });
          redirectToDashboard(
            activeOrg?.organization?.domain,
            activeOrg?.organization?.settings?.storage,
            navigate
          );
        } else if (resp?.data?.organizations?.length === 0) {
          navigate("/signup/1");
          setUserDetails(resp?.data);
        } else {
          setUserDetails(resp?.data);
          setOpenChooseModal(true);
        }
      }
    } catch (error) {}
  };

  const getDomainDetails = async (domain) => {
    try {
      const resp = await getDomainDetailsApi(domain);
      setOrgDetails({
        logo: resp?.data?.org?.profile_pic,
        name: resp?.data?.org?.name,
        id: resp?.data?.org?._id,
      });
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_PUBLIC_DOMAIN_BASE_URL}/login`;
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_STORAGE === "CLOUD") {
      const subdomain = getSubdomain(window.location.hostname);
      subdomain &&
        subdomain != process.env.REACT_APP_PUBLIC_DOMAIN &&
        getDomainDetails(subdomain);
    }
  }, []);

  return (
    <Row
      style={{
        height: "100vh",
        alignItems: "center",
      }}
    >
      <ChooseOrgModal openModal={openChooseModal} closable={false} />
      <Col
        xs={{ span: 16, offset: 4 }}
        md={{ span: 10, offset: 6 }}
        lg={{ span: 8, offset: 8 }}
        xl={{ span: 6, offset: 9 }}
      >
        <div style={{ textAlign: "center" }}>
          <Image
            src={darkLogo}
            style={{ height: "100px", width: "auto" }}
            preview={false}
          />
          <Typography
            className={`${typoStyles.h6} text-center`}
            style={{ marginBlock: "24px" }}
          >
            Create password
          </Typography>
        </div>
        <Form
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
          form={form}
        >
          <Form.Item
            label="Password"
            name="newPassword"
            rules={[
              { required: true, message: "Please input your password!" },
              {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W]{8,}$/,
                message:
                  "Password must contain at least 1 letter, 1 number, and be at least 8 characters long.",
              },
            ]}
          >
            <Input.Password
              placeholder="Enter your password"
              className={inputStyles.smInput}
              style={{ width: "100%", gap: "0px" }}
            />
          </Form.Item>
          <Form.Item
            label="Confirm password"
            name="confirmPassword"
            rules={[
              { required: true, message: "Please input your password!" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The new passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Re-enter your password"
              className={inputStyles.smInput}
              style={{ width: "100%", gap: "0px" }}
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "center" }}>
            <Button
              type="primary"
              className={btnStyles.mdBtn}
              htmlType="submit"
            >
              Update Password
            </Button>
          </Form.Item>
          <div style={{ width: "100%", textAlign: "center" }}>
            <Button
              type="link"
              className={`${btnStyles.mdBtn} ${btnStyles.textBtn}`}
              onClick={() => {
                navigate("/login");
              }}
            >
              <ArrowLeftOutlined />
              Back to log in
            </Button>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default SetPassword;
