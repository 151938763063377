import React from "react";
import ViewPrecQuestion from "./ViewSectionQuestion";
import { Collapse, ConfigProvider, Typography } from "antd";
import typoStyles from "../../../../assets/styles/Custom/Typography.module.css";

const CustomCard = ({ title, children, titleText }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            contentBg: "#fff",
            headerBg: "#fff",
          },
        },
      }}
    >
      <Collapse
        items={[
          { key: titleText?.toLowerCase(), label: title, children: children },
        ]}
      />
    </ConfigProvider>
  );
};

const ViewSection = ({
  data,
  title,
  onChange,
  disabled = false,
  answers = {},
  setAnswers,
}) => {
  return (
    <CustomCard
      titleText={data?.title}
      title={
        <Typography
          className={typoStyles.h6}
          style={{
            fontWeight: 500,
            fontSize: "18px",
            lineHeight: "24px",
          }}
        >
          {data?.title}
        </Typography>
      }
    >
      {data?.procedures?.map((each, index) => (
        <ViewPrecQuestion
          setAnswers={setAnswers}
          index={index}
          questionData={each}
          onChange={onChange}
          answers={answers}
          disabled={disabled}
        />
      ))}
    </CustomCard>
  );
};

export default ViewSection;
