import { Col, Radio, Button, Row, Typography, Image } from "antd";
import React, { useContext } from "react";
import { EditOutlined } from "@ant-design/icons";
import Questionnaire from "../AddElem/Questionnaire/Questionnaire";
import typographyStyles from "../../../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../../../assets/styles/Custom/Button.module.css";
import "../../SOP.css";
import TyutsQuestion from "../AddElem/Questionnaire/TyutsQuestion";
import { TYUT } from "../../../../constants/defaultKeys";
import { getAssetsPath } from "../../../../helpers/utility";
import { UserContext } from "../../../../context/UserProvider";

function ViewSAQ({
  saq,
  disabled,
  sopContent,
  setSopContent,
  editing,
  setEditing,
  setIsUnfilled,
  viewType = "edit",
  contentType,
}) {
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  return (
    <>
      {editing.data?.id !== saq.id ? (
        <Row
          className={"viewSOPElemCard"}
          key={saq.id}
          style={
            viewType === "edit"
              ? {
                  borderRadius: "16px",
                  boxShadow: " 0px 1px 4px 0px rgba(0, 0, 0, 0.16)",
                }
              : {}
          }
        >
          <Col span={24} className={"viewQuestionnaireContent"}>
            <Typography>{saq.question}</Typography>
            <Radio.Group value={saq.answers}>
              <Row>
                {saq.choices.map((eachChoice) => {
                  return (
                    <>
                      <Col span={saq?.optionType == "images" ? 12 : 24}>
                        <Radio value={eachChoice}>
                          {saq?.optionType == "images" ? (
                            <Image
                              src={getAssetsPath(eachChoice, storageType)}
                              alt="Uploaded"
                              style={{
                                width: "100px",
                                marginBlock: "12px",
                                height: "100px",
                              }}
                            />
                          ) : (
                            <Typography
                              className={typographyStyles.body2}
                              style={{ marginBottom: "5px" }}
                            >
                              {eachChoice}
                            </Typography>
                          )}
                        </Radio>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </Radio.Group>
            {saq.ansDesc && (
              <div className={"viewQuestionnaireAnswerHint"}>
                <Typography>Answer hint</Typography>
                <Typography className={typographyStyles.body2}>
                  {saq.ansDesc}
                </Typography>
              </div>
            )}
          </Col>
          {viewType === "edit" && (
            <Col span={24} className={"viewQuestionnaireEditBtn"}>
              <Button
                className={btnStyles.smBtn}
                type="primary"
                disabled={editing.edit}
                onClick={(e) => setEditing({ edit: true, data: saq })}
              >
                <span>Edit</span>
                <EditOutlined />
              </Button>
            </Col>
          )}
        </Row>
      ) : (
        <>
          {contentType == TYUT ? (
            <TyutsQuestion
              contentType={contentType}
              sopContent={sopContent}
              setSopContent={setSopContent}
              questionnaire={saq}
              type={"saq"}
              editing={editing}
              setEditing={setEditing}
              setIsUnfilled={setIsUnfilled}
              optionType={saq.optionType}
            />
          ) : (
            <Questionnaire
              contentType={contentType}
              sopContent={sopContent}
              setSopContent={setSopContent}
              questionnaire={saq}
              type={"saq"}
              editing={editing}
              setEditing={setEditing}
              setIsUnfilled={setIsUnfilled}
            />
          )}
        </>
      )}
    </>
  );
}

export default ViewSAQ;
