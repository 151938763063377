import React, { useContext } from "react";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Row } from "antd";
import { DeleteOutlined, HolderOutlined } from "@ant-design/icons";
import { UserContext } from "../../context/UserProvider";
import { ONPREMISE } from "../../constants/defaultKeys";
import { deleteDocsApi } from "../../services/assets.services";
import { delteS3FileApi } from "../../services/upload.services";

const dndIconStyles = {
  background: "#fff",
  padding: "5px",
  borderRadius: "6px",
  fontSize: "1.05rem",
};
function arrayMove(array, fromIndex, toIndex) {
  if (
    fromIndex < 0 ||
    fromIndex >= array.length ||
    toIndex < 0 ||
    toIndex >= array.length
  ) {
    // Invalid index provided, return the array unchanged
    return array;
  }

  const result = [...array];
  const [item] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, item);
  return result;
}

const SortableItem = React.forwardRef(
  (
    {
      item,
      state,
      setState,
      moveDragIconTopBy,
      moveDragIconLeftBy,
      RenderComponent,
      id,
      showDragNdDropDelete,
      ...extraProps
    },
    ref
  ) => {
    const {
      isDragging,
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({ id: item?.[id] });
    const style = {
      transform: transform
        ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
        : undefined,
      transition,
      display: "flex",
      position: "relative",
      width: "100%",
    };
    const { userDetails } = useContext(UserContext);
    const storage = userDetails?.activeOrg?.organization?.settings?.storage;

    return (
      <Row ref={setNodeRef} style={style} key={item?.[id]}>
        <div
          {...listeners}
          {...attributes}
          style={{
            gap: "15px",
            position: "absolute",
            zIndex: 2,
            left: moveDragIconLeftBy,
            top: moveDragIconTopBy,
          }}
        >
          <div style={dndIconStyles}>
            <HolderOutlined style={{ cursor: "grab", color: "black" }} />
          </div>
        </div>
        {showDragNdDropDelete && (
          <div
            style={{
              ...dndIconStyles,
              position: "absolute",
              zIndex: 2,
              left: moveDragIconLeftBy,
              top: "65px",
            }}
          >
            <DeleteOutlined
              style={{ cursor: "pointer", color: "black" }}
              onClick={(e) => {
                if (extraProps.editing.data?.id == item.id) {
                  extraProps.setEditing({
                    edit: state?.length > 0 ? false : null,
                    data: null,
                  });
                }
                if (item.type === "checkbox" || item.type === "radio") {
                  extraProps?.setIsUnfilled?.("#406aff");
                }

                if (
                  (item.type == "document" || item.type == "video") &&
                  item.src?.slice(0, 4) !== "http"
                ) {
                  const api =
                    storage === ONPREMISE ? deleteDocsApi : delteS3FileApi;
                  api(item.src)
                    .then((res) => {
                      console.log("ITEM DELETED");
                    })
                    .catch((err) => {});
                }
                setState((prev) =>
                  prev.filter((eachTyut) => eachTyut.id !== item.id)
                );
              }}
            />
          </div>
        )}
        <RenderComponent
          ref={ref}
          item={item}
          state={state}
          setState={setState}
          {...extraProps}
          isDragging={isDragging}
        />
      </Row>
    );
  }
);

SortableItem.displayName = "SortableItem";

function DND(
  {
    state = [],
    setState,
    RenderComponent,
    id = "id",
    moveDragIconTopBy = "20px",
    moveDragIconLeftBy = "-40px",
    showDragNdDropDelete = true,
    ...extraProps
  },
  ref
) {
  function handleDragEnd(event) {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      const oldIndex = state.findIndex(
        (eachElem) => eachElem?.[id] === active?.id
      );
      const newIndex = state.findIndex(
        (eachElem) => eachElem?.[id] === over?.id
      );
      setState((prevItems) => arrayMove(prevItems, oldIndex, newIndex));
    }
  }
  return (
    <DndContext
      onDragEnd={handleDragEnd}
      sensors={useSensors(useSensor(PointerSensor))}
      collisionDetection={closestCenter}
    >
      <SortableContext
        items={state?.map((eachElem) => eachElem?.[id])}
        strategy={verticalListSortingStrategy}
      >
        {state?.map((eachElem) => (
          <SortableItem
            ref={ref}
            key={eachElem?.[id]}
            item={eachElem}
            state={state}
            setState={setState}
            id={id}
            showDragNdDropDelete={showDragNdDropDelete}
            RenderComponent={RenderComponent}
            moveDragIconTopBy={moveDragIconTopBy}
            moveDragIconLeftBy={moveDragIconLeftBy}
            {...extraProps}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}

export default React.forwardRef(DND);
