import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Popover,
  Row,
  Space,
  Typography,
} from "antd";
import CustomCard from "./CustomCard";
import checkboxStyles from "../../assets/styles/Custom/Checkbox.module.css";
import buttonStyles from "../../assets/styles/Custom/Button.module.css";
import { SOP } from "../../constants/defaultKeys";
import { getAssetsPath } from "../../helpers/utility";

function CustomCheckBox({
  data,
  title,
  onChange,
  disabled = false,
  answers = {},
  isAnsSelected = {},
  viewAnsHint,
  selectedValue,
  onFinish,
  contentType = SOP,
  storageType,
}) {
  const [form] = Form.useForm();
  const [isViewAns, setIsViewAns] = useState(false);

  useEffect(() => {
    if (isViewAns) {
      form.setFieldsValue({ selected_ans: data?.answers });
    } else {
      selectedValue &&
        form.setFieldsValue({
          selected_ans: selectedValue?.check,
        });
    }
  }, [isViewAns, data]);

  const Elem = contentType === SOP ? Fragment : Form;

  const isEnglishText = (text) => /^[A-Za-z0-9\s.,!?;:'"-]+$/.test(text);

  return (
    <CustomCard>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Typography
            style={{
              fontSize: isEnglishText(title) ? 16 : 19,
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            {title}
          </Typography>
        </Col>
        <Col span={24}>
          <Elem
            form={form}
            onFinish={onFinish}
            initialValues={{ selected_ans: answers?.[data?.id] }}
          >
            {contentType === SOP ? (
              <Checkbox.Group
                onChange={onChange}
                defaultValue={answers?.[data?.id]}
              >
                <Space direction="vertical">
                  {data?.choices?.map((item, index) => (
                    <Checkbox
                      value={item}
                      disabled={disabled}
                      key={index}
                      className={checkboxStyles.mdCheck}
                    >
                      <Typography
                        style={{
                          fontSize: isEnglishText(title) ? 14 : 17,
                          fontWeight: 400,
                          lineHeight: "24px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                        }}
                      >
                        {item}
                      </Typography>
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            ) : (
              <Form.Item
                name="selected_ans"
                rules={
                  contentType !== SOP
                    ? [
                        {
                          required: true,
                          message: "select answer",
                        },
                      ]
                    : []
                }
              >
                <Checkbox.Group
                  onChange={onChange}
                  defaultValue={answers?.[data?.id]}
                >
                  <Space direction="vertical">
                    {data?.choices?.map((item, index) => (
                      <Checkbox
                        value={item}
                        disabled={disabled}
                        key={index}
                        className={checkboxStyles.mdCheck}
                      >
                        {data?.optionType == "images" ? (
                          <Image
                            src={getAssetsPath(item, storageType)}
                            alt="Uploaded"
                            style={{
                              width: "100px",
                              marginBlock: "12px",
                              height: "100px",
                            }}
                          />
                        ) : (
                          <Typography
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: "20px",
                            }}
                          >
                            {item}
                          </Typography>
                        )}
                      </Checkbox>
                    ))}
                  </Space>
                </Checkbox.Group>
              </Form.Item>
            )}
            {contentType !== SOP && (
              <>
                {Object.keys(isAnsSelected).includes(data?.id) &&
                  viewAnsHint?.[data?.id] && (
                    <Space direction="vertical">
                      <Typography>Answer hint</Typography>
                      <Typography>
                        {data?.ansDesc}{" "}
                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: "8px",
                            fontWeight: 600,
                            lineHeight: "18px",
                            textUnderlineOffset: "2px",
                            textDecoration: "underline",
                            color: "blue",
                          }}
                          onClick={() => setIsViewAns((prev) => !prev)}
                        >
                          {!isViewAns
                            ? "see the correct answer"
                            : "unhide answer"}
                        </span>
                      </Typography>
                    </Space>
                  )}
                <Form.Item>
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <Popover
                      placement="left"
                      content={
                        isAnsSelected?.[data?.id] === 0 ||
                        !Object.keys(isAnsSelected).includes(data?.id) ||
                        Object.keys(isAnsSelected).length === 0
                          ? "Choose An answer"
                          : ""
                      }
                    >
                      <Button
                        className={buttonStyles.mdBtn}
                        type="primary"
                        htmlType="submit"
                        disabled={
                          isAnsSelected?.[data?.id] === 0 ||
                          !Object.keys(isAnsSelected).includes(data?.id) ||
                          Object.keys(isAnsSelected).length === 0
                            ? true
                            : false
                        }
                      >
                        Submit
                      </Button>
                    </Popover>
                  </div>
                </Form.Item>
              </>
            )}
          </Elem>
        </Col>
      </Row>
    </CustomCard>
  );
}

export default CustomCheckBox;
