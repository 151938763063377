import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../../../Layout";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  TreeSelect,
  Typography,
  message,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import typoStyles from "../../../../assets/styles/Custom/Typography.module.css";
import btnStyles from "../../../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../../../assets/styles/Custom/Input.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { getLocationsApi } from "../../../../services/location";
import {
  getGroupsApi,
  getUsersandGroupsApi,
} from "../../../../services/users.services";
import {
  createWorkflowApi,
  deleteWorkflowApi,
  getWorkflowByIDApi,
  updateWorkflowApi,
} from "../../../../services/workflow.services";
import { UserContext } from "../../../../context/UserProvider";

const CreateWorkflow = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const [locations, setLocations] = useState({});
  const [teamsList, setTeamsList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { userDetails } = useContext(UserContext);

  const workflowActionsMap = {
    "MRO Notification": "mro_notification",
    "Create Workorder": true,
    "Assign To": "wo_group",
    "Assign WO Verifiers": "wo_verifiers",
    "MRO Verifiers": "mro_verifiers",
  };

  function transformData(data) {
    function transformNode(node) {
      const transformedChildren = node.children.map(transformNode);
      return {
        value: node._id,
        title: node.name,
        children: transformedChildren.length ? transformedChildren : undefined,
      };
    }
    let treeData = transformNode(data);
    return treeData;
  }

  const fetchLocations = async () => {
    try {
      const resp = await getLocationsApi();
      let treeData = transformData(resp?.data);

      setLocations(treeData);
    } catch (error) {
      setLocations({});
    }
  };

  const fetchTeams = async () => {
    try {
      const resp = await getGroupsApi();
      setTeamsList(
        resp?.data?.groups?.map((g) => ({
          label: g?.name,
          value: g?._id,
        }))
      );
    } catch (error) {
      setTeamsList([]);
    }
  };

  const fetchUsers = async () => {
    try {
      const resp = await getUsersandGroupsApi({
        role: ["manager", "supervisor"],
      });
      setUsersList(
        resp?.data?.users?.map((u) => ({
          label: `${u?.first_name} ${u?.last_name ?? ""}`,
          value: u?._id,
        }))
      );
    } catch (error) {
      setTeamsList([]);
    }
  };

  const fetchWorkflowDetails = async () => {
    try {
      const res = await getWorkflowByIDApi(params?.id);
      let data = res?.data;
      if (data) {
        let wo_flag = false;
        let initialValues = {
          name: data?.name,
          location: data?.location?._id,
        };

        data?.steps?.map((item) => {
          if (workflowActionsMap[item?.action]) {
            if (item?.action === "Create Workorder") {
              wo_flag = true;
            } else if (item?.action === "MRO Verifiers") {
              initialValues = {
                ...initialValues,
                mro_verifiers_1: item?.mro_verifiers?.[0]?.verifiers?.map(
                  (i) => i?._id
                ),
              };
            } else {
              initialValues = {
                ...initialValues,
                [`${workflowActionsMap[item?.action]}`]: item?.[
                  workflowActionsMap[item?.action]
                ]?.map((i) => i?._id),
              };
            }
          }
        });
        initialValues = { ...initialValues, auto_create_wo: wo_flag };
        setIsChecked(wo_flag);
        form.setFieldsValue(initialValues);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchLocations();
    fetchTeams();
    fetchUsers();
    if (params?.id) {
      fetchWorkflowDetails();
    } else {
      setEditMode(true);
    }
  }, [refresh]);

  const onFinish = async (values) => {
    let workflowSteps = [{ action: "Create MRO" }];
    if (values?.auto_create_wo) {
      workflowSteps = [
        ...workflowSteps,
        {
          action: "Create Workorder",
        },
        {
          action: "Assign To",
          wo_group: values?.wo_group,
        },
      ];
    } else {
      workflowSteps = [
        ...workflowSteps,
        {
          action: "MRO Notification",
          mro_notification: values?.mro_notification,
        },
      ];
    }
    if (values?.wo_verifiers?.length > 0) {
      workflowSteps = [
        ...workflowSteps,
        {
          action: "Assign WO Verifiers",
          wo_verifiers: values?.wo_verifiers,
        },
      ];
    }
    if (values?.mro_verifiers_1?.length > 0) {
      workflowSteps = [
        ...workflowSteps,
        {
          action: "MRO Verifiers",
          mro_verifiers: [{ level: 1, verifiers: values?.mro_verifiers_1 }],
        },
      ];
    }
    let payload = {
      name: values?.name,
      location: values?.location,
      steps: workflowSteps,
    };

    setBtnLoading(true);
    try {
      const resp = params?.id
        ? await updateWorkflowApi(params?.id, payload)
        : await createWorkflowApi(payload);
      if (resp) {
        !params?.id && navigate("/org/workflows");
        message.success(
          `Workflow ${params?.id ? "updated" : "created"} successfully`
        );
        params?.id && setEditMode(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || "Something went wrong!!");
    } finally {
      setBtnLoading(false);
      setRefresh((prev) => !prev);
    }
  };

  const deleteWorkflow = async () => {
    try {
      const resp = await deleteWorkflowApi(params?.id);
      if (resp) {
        navigate("/org/workflows");
        message.success(resp?.data?.message);
      }
    } catch (error) {
      message.error(error?.repsonse?.data?.message);
    }
  };

  return (
    <CustomLayout
      header={
        <Flex justify="space-between" align="center">
          <div className="flex-start" style={{ gap: 6 }}>
            <LeftOutlined
              className={typoStyles.h6}
              onClick={() => navigate("/org/workflows")}
            />
            <Typography className={typoStyles.h6}>
              {params?.id ? "Update Workflow" : "Create Workflow"}
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
            {params?.id && userDetails?.activeOrg?.role === "admin" && (
              <Popconfirm
                icon={null}
                description="Are you sure to delete this workflow ?"
                onConfirm={(e) => {
                  deleteWorkflow();
                }}
              >
                <Button className={btnStyles.mdBtn}>
                  <span className="hiddenBelowTab">Delete</span>
                  <DeleteOutlined style={{ margin: "0px" }} />
                </Button>
              </Popconfirm>
            )}
            {!editMode &&
              params?.id &&
              userDetails?.activeOrg?.role === "admin" && (
                <Button
                  className={btnStyles.mdBtn}
                  onClick={() => setEditMode(true)}
                  type="primary"
                >
                  <span className="hiddenBelowTab">Edit</span>

                  <EditOutlined style={{ margin: "0px" }} />
                </Button>
              )}
          </div>
        </Flex>
      }
      footer={
        <>
          {(!params?.id || (params?.id && editMode)) && (
            <Flex justify="flex-end" gap={12}>
              <Button
                className={btnStyles.mdBtn}
                onClick={() => {
                  if (params?.id) {
                    setEditMode(false);
                    fetchWorkflowDetails();
                  } else {
                    setIsChecked(false);
                    form.resetFields();
                  }
                }}
              >
                Cancel
              </Button>
              <Button
                className={btnStyles.mdBtn}
                type="primary"
                onClick={form.submit}
                loading={btnLoading}
              >
                Save
              </Button>
            </Flex>
          )}
        </>
      }
    >
      <Form
        name="workflow-form"
        layout="vertical"
        requiredMark={false}
        form={form}
        onFinish={onFinish}
      >
        <Card
          title="Workflow Details"
          styles={{ header: { backgroundColor: "#fff" } }}
          style={{ width: "75%" }}
        >
          <Row gutter={12}>
            <Col span={16}>
              <Flex style={{ width: "100%", gap: 6 }} vertical>
                <Form.Item
                  label="Workflow title"
                  name={"name"}
                  rules={[
                    {
                      whitespace: true,
                      message:
                        "Workflow title cannot be empty or contain only spaces",
                    },
                    { required: true, message: "Please enter workflow title" },
                  ]}
                >
                  <Input
                    style={{ width: "100%" }}
                    placeholder="Enter workflow title"
                    className={inputStyles.smInput}
                    disabled={!editMode}
                  />
                </Form.Item>
                <Form.Item
                  label="Location"
                  name={"location"}
                  rules={[{ required: true, message: "Please enter location" }]}
                >
                  <TreeSelect
                    placeholder="Location"
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    allowClear={true}
                    className={inputStyles.smSelect}
                    treeData={Object.keys(locations)?.length > 0 && [locations]}
                    disabled={!editMode}
                  />
                </Form.Item>
                <Alert
                  style={{ fontSize: 12, padding: 8, alignItems: "flex-start" }}
                  type="info"
                  icon={<InfoCircleOutlined />}
                  message={
                    <>
                      Production teams shall report production issues by
                      creating MROs. <br />
                      <br /> With a workflow, you have the option to
                      automatically create a Work Order (WO) when an MRO
                      (production complaint) is raised. <br /> Otherwise, your
                      team will need to manually link a WO to the MRO.
                    </>
                  }
                  showIcon
                />
                <Form.Item
                  valuePropName="checked"
                  name={"auto_create_wo"}
                  style={{
                    marginBottom: 0,
                    cursor: !editMode ? "not-allowed" : "auto",
                  }}
                >
                  <Checkbox
                    onChange={(e) => {
                      setIsChecked(e?.target?.checked);
                      form.setFieldsValue({
                        wo_group: [],
                        mro_notification: [],
                      });
                    }}
                    style={{ pointerEvents: !editMode ? "none" : "auto" }}
                  >
                    Automatically create work order for MRO
                  </Checkbox>
                </Form.Item>
                {isChecked ? (
                  <Form.Item
                    label={
                      "Created work order should be assigned to following maintenance team"
                    }
                    name={"wo_group"}
                    rules={[
                      {
                        required: true,
                        message: "Please select teams",
                      },
                    ]}
                    style={{ marginLeft: 24 }}
                  >
                    <Select
                      placeholder="Select teams"
                      style={{ width: "100%" }}
                      className={inputStyles.smSelect}
                      showSearch={true}
                      optionFilterProp="label"
                      mode="multiple"
                      options={teamsList}
                      disabled={!editMode}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label={
                      "Which maintenance teams to be notified when MRO is created ?"
                    }
                    name={"mro_notification"}
                    rules={[
                      {
                        required: true,
                        message: "Please select teams",
                      },
                    ]}
                    style={{ marginLeft: 24 }}
                  >
                    <Select
                      placeholder="Select teams"
                      style={{ width: "100%" }}
                      className={inputStyles.smSelect}
                      showSearch={true}
                      optionFilterProp="label"
                      mode="multiple"
                      options={teamsList}
                      disabled={!editMode}
                    />
                  </Form.Item>
                )}
                {isChecked && (
                  <Form.Item
                    label={"Who should verify the WOs ?"}
                    name={"wo_verifiers"}
                  >
                    <Select
                      placeholder="Select manager or supervisor"
                      style={{ width: "100%" }}
                      className={inputStyles.smSelect}
                      mode="multiple"
                      allowClear={true}
                      showSearch={true}
                      optionFilterProp="label"
                      options={usersList}
                      disabled={!editMode}
                    />
                  </Form.Item>
                )}
                <Form.Item
                  label={"Who should verify the MROs ?"}
                  name={"mro_verifiers_1"}
                  style={{ marginTop: 8 }}
                >
                  <Select
                    placeholder="Select manager or supervisor"
                    style={{ width: "100%" }}
                    className={inputStyles.smSelect}
                    mode="multiple"
                    allowClear={true}
                    showSearch={true}
                    optionFilterProp="label"
                    options={usersList}
                    disabled={!editMode}
                  />
                </Form.Item>
              </Flex>
            </Col>
          </Row>
        </Card>
      </Form>
    </CustomLayout>
  );
};

export default CreateWorkflow;
