import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { message } from "antd";
import { useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

import "./index.css";
import {
  getWorkordersApi,
  updateWorkOrderApi,
} from "../../services/work-orders.services";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {
  getAllJobsApi,
  updateOccurenceApi,
} from "../../services/maintenance.services";
import EventCard from "./EventCard";
import { UserContext } from "../../context/UserProvider";
import dayjs from "dayjs";
import {
  convertToUTCFormat,
  convertUTCToLocalFormat,
  editCond,
} from "../../helpers/utility";
import { editPM, editWO } from "../../constants/defaultKeys";

const localizer = momentLocalizer(moment);

const DnDCalendar = withDragAndDrop(Calendar);
const MyCalendar = ({ onShowEventView, currEvent }) => {
  const [wos, setWOs] = useState([]);
  const [pms, setPMs] = useState([]);
  const { userDetails } = useContext(UserContext);
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().startOf("week").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("week").format("YYYY-MM-DD"),
  });

  const initProps = {
    localizer: localizer,
    defaultDate: new Date(),
    defaultView: Views.WEEK,
    timeslots: 1,
  };

  useEffect(() => {
    getWOs();
    getPMS();
  }, [currEvent, userDetails, dateFilter]);

  const getPMS = async () => {
    let params = {};

    if (dateFilter?.start_date) {
      params.start_date = convertToUTCFormat(
        dayjs(dateFilter.start_date).startOf("day")
      );
    }
    if (dateFilter?.end_date) {
      params.end_date = convertToUTCFormat(
        dayjs(dateFilter.end_date).endOf("day")
      );
    }

    try {
      const resp = await getAllJobsApi(params);
      const arr = [];
      resp?.data?.occurrences?.forEach((each) => {
        const startTime = new Date(
          convertUTCToLocalFormat(each?.occurrence_date)
        );
        const endTime = new Date(
          convertUTCToLocalFormat(each?.occurrence_end_date)
        );
        arr.push({
          start: startTime,
          end: endTime,
          data: {
            currEvent: {
              ...each,
              status: each?.status,
              type: "pm",
              occ: each,
            },
          },
          title: each?.code,
          type: "pm",
        });
      });

      setPMs(arr);
    } catch (error) {
      console.error("Error fetching maintenance data:", error);
      setPMs([]);
    }
  };

  const getWOs = async () => {
    let params = {};

    if (dateFilter?.start_date) {
      params.start_date = convertToUTCFormat(
        dayjs(dateFilter.start_date).startOf("day")
      );
    }
    if (dateFilter?.end_date) {
      params.end_date = convertToUTCFormat(
        dayjs(dateFilter.end_date).endOf("day")
      );
    }

    try {
      const resp = await getWorkordersApi(params);
      setWOs(
        resp?.data?.workorders?.map((each) => {
          let currEndDate = new Date(convertUTCToLocalFormat(each?.start_date));
          currEndDate.setHours(currEndDate.getHours() + 1);

          const startTime = new Date(convertUTCToLocalFormat(each?.start_date));
          const endTime = each?.end_date
            ? new Date(convertUTCToLocalFormat(each?.end_date))
            : currEndDate;

          return {
            start: startTime,
            end: endTime,
            data: { currEvent: { ...each, occ: each, type: "wo" } },
            title: each?.code,
            type: "wo",
          };
        })
      );
    } catch (error) {}
  };

  const onEventDrop = async (data) => {
    let eventType = "";
    if (data?.event?.type === "wo") {
      eventType = "WO";
    } else if (data?.event?.type === "pm") {
      if (data?.event?.title?.startsWith("INS")) {
        eventType = "Inspection";
      } else {
        eventType = "PM";
      }
    }

    if (
      data?.event?.type === "wo" &&
      data?.event?.data?.currEvent?.status !== "closed" &&
      editCond(
        editWO,
        data?.event?.data?.currEvent?.creator,
        userDetails?.activeOrg?.role,
        userDetails?._id
      )
    ) {
      setWOs((prevEvents) =>
        prevEvents.map((event) =>
          data?.event?.data?.currEvent?.occ?._id ===
          event?.data?.currEvent?.occ?._id
            ? { ...event, start: data.start, end: data.end }
            : event
        )
      );
      updateWO(data?.event?.data?.currEvent?._id, {
        start_date: data?.start ? convertToUTCFormat(data?.start) : "",
        end_date: data?.end ? convertToUTCFormat(data?.end) : "",
      });
    } else if (
      data?.event?.type === "pm" &&
      data?.event?.data?.currEvent?.status !== "closed" &&
      editCond(
        editPM,
        data?.event?.data?.currEvent?.creator,
        userDetails?.activeOrg?.role,
        userDetails?._id
      )
    ) {
      setPMs((prevEvents) =>
        prevEvents.map((event) =>
          data?.event?.data?.currEvent?.occ?._id ===
          event?.data?.currEvent?.occ?._id
            ? { ...event, start: data.start, end: data.end }
            : event
        )
      );

      updateOccurence(data?.event?.data?.currEvent?.occ?._id, {
        occurrence_end_date: data?.end ? convertToUTCFormat(data?.end) : "",
        occurrence_date: data?.start ? convertToUTCFormat(data?.start) : "",
        duration: (new Date(data?.end) - new Date(data?.start)) / 3600000,
      });
    }else if (data?.event?.data?.currEvent?.status !== "closed") {
      message.error("You cannot drag and drop this item because it was created by an admin or manager");
    }
    if (data?.event?.data?.currEvent?.status === "closed") {
      message.error(`Closed ${eventType} cannot be dragged or dropped`);
    }
  };

  const onResize = (data) => {
    let eventType = "";
    if (data?.event?.type === "wo") {
      eventType = "WO";
    } else if (data?.event?.type === "pm") {
      if (data?.event?.title?.startsWith("INS")) {
        eventType = "Inspection";
      } else {
        eventType = "PM";
      }
    }

    if (
      data?.event?.type == "wo" &&
      data?.event?.data?.currEvent?.status !== "closed" &&
      editCond(
        editWO,
        data?.event?.data?.currEvent?.creator,
        userDetails?.activeOrg?.role,
        userDetails?._id
      )
    ) {
      setWOs((prevEvents) =>
        prevEvents.map((event) =>
          data?.event?.data?.currEvent?.occ?._id ===
          event?.data?.currEvent?.occ?._id
            ? { ...event, start: data.start, end: data.end }
            : event
        )
      );
      updateWO(data?.event?.data?.currEvent?._id, {
        start_date: data?.start ? convertToUTCFormat(data?.start) : "",
        end_date: data?.end ? convertToUTCFormat(data?.end) : "",
      });
    } else if (
      data?.event?.type == "pm" &&
      data?.event?.data?.currEvent?.status !== "closed" &&
      editCond(
        editPM,
        data?.event?.data?.currEvent?.creator,
        userDetails?.activeOrg?.role,
        userDetails?._id
      )
    ) {
      setPMs((prevEvents) =>
        prevEvents.map((event) =>
          data?.event?.data?.currEvent?.occ?._id ===
          event?.data?.currEvent?.occ?._id
            ? { ...event, start: data.start, end: data.end }
            : event
        )
      );
      updateOccurence(data?.event?.data?.currEvent?.occ?._id, {
        occurrence_end_date: data?.end ? convertToUTCFormat(data?.end) : "",
        occurrence_date: data?.start ? convertToUTCFormat(data?.start) : "",
        duration: (new Date(data?.end) - new Date(data?.start)) / 3600000,
      });
    }
    if (data?.event?.data?.currEvent?.status === "closed") {
      message.error(`Closed ${eventType} cannot be dragged or dropped`);
    }
  };

  const updateWO = async (id, data) => {
    try {
      const resp = await updateWorkOrderApi(id, data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      getWOs();
    }
  };

  const updateOccurence = async (id, data) => {
    try {
      const resp = await updateOccurenceApi(id, data);
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      getPMS();
    }
  };

  const components = {
    event: ({ event }) => {
      const data = event?.data;
      if (data?.currEvent)
        return (
          <EventCard currEvent={data?.currEvent} onDoubleClick={() => {}} />
        );

      return null;
    },
  };

  const { messages } = useMemo(
    () => ({
      messages: {
        previous: "Previous",
      },
    }),
    []
  );
  const dayFormat = ({ start, end }, culture, localizer) =>
    localizer.format(start, "D MMM YYYY", culture) +
    " - " +
    localizer.format(end, "D MMM YYYY", culture);

  return (
    <div style={{ height: "80vh", width: "100%" }}>
      <DnDCalendar
        storyName="Overlapping Background Events - 'no-overlap'"
        events={[...wos, ...pms]}
        showMultiDayTimes
        dayLayoutAlgorithm="no-overlap"
        views={["week", "day", "agenda"]}
        defaultView={"week"}
        components={components}
        messages={messages}
        eventPropGetter={(event) => {
          let backgroundColor;
          let color;

          switch (event.type) {
            case "pm":
              backgroundColor =
                event?.data?.currEvent?.maintenance?.type == "Inspection"
                  ? "rgb(169 237 181 / 90%)"
                  : "rgba(169, 172, 237, 0.9)";
              color = "#7369F4";
              break;
            case "wo":
              backgroundColor = "rgba(230,227,99,0.8)";
              color = "red";
              break;
            default:
              backgroundColor = "gray";
              break;
          }

          return {
            style: {
              backgroundColor,
              color,
              fontWeight: 600,
              fontSize: 12,
            },
          };
        }}
        style={{ width: "100%" }}
        onDoubleClickEvent={(event) => {
          const currEvent = event?.data?.currEvent;
          currEvent && onShowEventView(currEvent);
        }}
        selectable
        onEventResize={onResize}
        onEventDrop={onEventDrop}
        step={30}
        formats={{
          agendaHeaderFormat: dayFormat,
        }}
        {...initProps}
        onNavigate={(date, view) => {
          if (view === "week") {
            const firstDay = dayjs(date).startOf("week").format("YYYY-MM-DD");
            const lastDay = dayjs(date).endOf("week").format("YYYY-MM-DD");
            setDateFilter({ start_date: firstDay, end_date: lastDay });
          } else if (view === "day") {
            const day = dayjs(date).format("YYYY-MM-DD");
            setDateFilter({ start_date: day, end_date: day });
          }
        }}
      />
    </div>
  );
};

export default MyCalendar;
